/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import './Junction.scss'
import { useNavigate } from "react-router-dom";
import apiService from "@services/api.service";
import newLeader from "../../assets/images/New-Leader-Icon.png";
import union from "../../assets/images/Union.svg";
import { AppConfig } from "@configs";

function Junction() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const goToLeaderDashboard = () => {
    return navigate("/dashboard")
  };

  const goToLearnerDashboard = () => {
    setLoading(true);

    apiService.learnerLogin().then((res) => {
      setLoading(true);
      if (res?.data) {
        const response = res?.data;
        console.log('response: ', response);
        if (response?.data?.url) {
          return window.open(response.data.url, "_self");
        }
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  const logout = () => {
    // swal({
    //   title: "Are you sure you want to logout?",
    //   icon: "warning",
    //   buttons: [true, true],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
        localStorage.clear();
        window.open(AppConfig.logoutRedirectUrl, "_self");
    //   }
    // });
  };


  return (
    <>
      <div className="junction-page-container" style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
        <div className="action-buttons" >
        <button type='button' onClick={()=>{logout()}} className="back-to-login">&lt;Back To Login</button >
        <div className="action-button-start">
          <p className="leader-lerner">You have a <span style={{color:'#3B6494'}}>Leader</span> and a <span style={{color:'#40916C'}}>Learner</span> account. Please choose which to log into.</p>
          <div className="row gx-5" style={{padding:20}}>
            <div className="col-md-6">
                <div className="row" style={{background:'#DDDFD2',padding:20}}> 
                    <div className="col-md-3">
                        <img src={newLeader} alt='new-leader' height={63} />
                    </div>
                    <div className="col-md-9" style={{alignItems:'center',justifyContent:'center',display: 'flex'}}>
                          <p className="leader-acc">Leader Account</p>
                    </div>
                </div>  
                <div className="row" style={{background:'#DDDFD2',paddingLeft:20,paddingRight:20,paddingTop:0,paddingBottom:20}}>
                    <button className="btn-leader" onClick={() => { goToLeaderDashboard(); localStorage.setItem('junctionNavigation', 'leader') }} type="button">Continue as a Leader</button>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row" style={{background:'#DDDFD2',padding:20}}> 
                    <div className="col-md-3">
                        <img src={union} alt='new-leader' height={63} />
                    </div>
                    <div className="col-md-9" style={{alignItems:'center',justifyContent:'center',display: 'flex'}}>
                          <p className="leader-acc">Learner Account</p>
                    </div>
                </div>  
                <div className="row" style={{background:'#DDDFD2',paddingLeft:20,paddingRight:20,paddingTop:0,paddingBottom:20}}>
                    <button className="btn-learner" onClick={() => { goToLearnerDashboard(); localStorage.setItem('junctionNavigation', 'learner') }} type="button">Continue as a Learner</button>
                </div>
            </div>
            
          </div>
          </div>
        </div>

        <div className="jn-footer">
          <div className="footer-text">
            <span>
              NEED HELP? <a className='no-color' href='tel:+12135108050'> CALL +1 213 510 8050</a>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a className='no-color' href='mailto:hello@alchemyvision.com'>EMAIL SUPPORT</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;<a className='no-color' href='mailto:hello@alchemyvision.com'>FAQs</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; <a className='no-color' target='_blank' rel='noreferrer' href='https://www.alchemyvision.com/terms'>TERMS
              & CONDITIONS{" "}</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Junction;