import React,{ useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Plan from "./Plan";
// import Beliving from "./Beliving";
import { useDispatch, useSelector } from "react-redux";
import { getPlans,getPlanFeature } from "../../store/plans/effect";
import { getFaq } from "../../store/faqs/effect";
import { SinglePlan, GetPlanState, GetPlanFeature } from "../../types/Plan.type";
import {  GetFaqState } from "../../types/Faq.type";
import Grow from "../loader/Grow";
import Toast from "../toasts/Toast";
import { toast } from "react-toastify";
import { REGISTER_DATA_RESET } from "../../store/register/actions";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { Includedata } from "./Includedata";
import Essential  from "../../assets/images/e-esse.png";
import Premium  from "../../assets/images/e-prem.png";
import Professional  from "../../assets/images/e-prof.png";
import Union from "../../assets/images/Union.png";

function Welcome() {
  const dispatch = useDispatch();
  const planState = useSelector((state: GetPlanState) => state.planState.plans);
  const faqState = useSelector((state: GetFaqState) => state.faqState.faqs);
  const featuresState = useSelector((state: GetPlanFeature) => state.planState.planFeatures);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [include,setInclude] = useState<any>();
  // const [exist,setExist] = useState(true);


  const contact = () => {
    navigate('/contact')
  }

  React.useEffect(() => {
    const checkTypewriter = document.querySelector('.header-pending')
    if (!checkTypewriter) {
      // setExist(false)
    }
    dispatch(getPlans(1));
    dispatch(getFaq());
    dispatch({
      type: REGISTER_DATA_RESET
    });
    dispatch(getPlanFeature());
    setInclude(Includedata)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(()=>{
    console.log('featuresState',featuresState)
  },[featuresState])

  React.useEffect(() => {
    if (planState?.error) {
      if(planState?.error?.data?.message){
      toast.error(planState?.error?.data?.message, {
        position: "top-right",
      });
      }
    }
  }, [planState.error]);
  React.useEffect(() => {
    if (faqState?.error) {
      if(faqState?.error?.data?.message){
      toast.error(faqState?.error?.data?.message, {
        position: "top-right",
      });
      }
    }
  }, [faqState.error]);

  const planRedireact = () => {
      window.open('https://app.alchemyvision.com/planswithoutheader', "_new");
  }

  return (
    <div className="welcome">
      <div>
        <Toast />
        <section className="subscription">
          <Container className="small-container">
            <div className="subscription-sub-text-box">
              <span className="subscription-sub-text-title">
                Simple pricing to train your entire team
              </span>
            </div>
            <div className="plans">
              {!planState?.loading && !featuresState?.loading ? (
                <div> 
                  {planState?.data && planState?.data?.length > 0 ? (
                    <Row className="gx-3 plan-section">
                      {planState?.data?.map(
                        (plan: SinglePlan, index: number) => {
                          return (
                              <Plan key={index} plan={plan} />
                          );
                        }
                      )}
                      <Col md={12}>
                        <button className='team-btn' onClick={()=>{planRedireact()}}>Get your team started</button>
                      </Col>
                    </Row>
                  ) : (
                    <div className="no-data-found">
                      <h4>No Data Available</h4>
                    </div>
                  )}
                </div>
              ) : (
                <Grow />
              )}
           
            </div>
          </Container>
        </section>
        <section className="whats-include">
           <Container className="small-container">
           {!featuresState?.loading &&
           <Table responsive striped hover>
            <thead>
              <tr>
                <th className='header-title'>What’s included:</th>
                <th className='header-title' style={{textAlign:'center',color:'#4E2857'}}>Essential</th>
                <th className='header-title' style={{textAlign:'center',color:'#012A4A'}}>Professional</th>
                <th className='header-title' style={{textAlign:'center',color:'#1B4332'}}>Premium</th>
              </tr>
            </thead>
             <tbody>
             <tr>
                      <td className='header-include'>Comprehensive training modules</td>
                      <td style={{textAlign:'center'}}><b>40+</b></td>
                      <td style={{textAlign:'center'}}><b>100+</b></td>
                      <td style={{textAlign:'center'}}><b>130+</b></td>
             </tr>
               { featuresState?.data && featuresState?.data?.map((inc:any)=>{
                  return (
                    <>
                      <tr>
                        <td className='header-include'>{inc?.featureText}</td>
                        {
                          inc?.plans?.map((plan:any)=>{
                            return(
                              <>
                              {
                                plan?.included ? 
                                    <>{ plan?.name==='Essential' && <td style={{textAlign:'center'}}><img height='27px' width='27px' src={Essential} alt='Essential' /></td>} { plan?.name==='Premium' &&  <td style={{textAlign:'center'}}><img height='27px' width='27px' src={Premium} alt='Premium' /></td>} { plan?.name==='Professional' &&<td style={{textAlign:'center'}}> <img src={Professional} alt='Professional' height='27px' width='27px' /></td>} </>
                                    :
                                    <td></td>
                              }
                              </>
                            )
                          })
                        }
                        </tr>
                    </>
                  )
                })
               }
               </tbody>
            </Table>
            }
            <Row>
                <Col md={12}>
                  <Card className="enter-price-card">
                    <Card.Body>
                      <Row>
                        <Col md={3}>
                          <div className="plan-icon-div">
                            {/* <img src={Enterprise} alt="enterprise" /> */}
                          </div>
                          <Card.Title className="text-center plan-name-enterprise mt-2 ">
                            ENTERPRISE
                          </Card.Title>
                        </Col>
                        <Col md={5} className="contact-text">
                          <Card.Text className="text-left detail-text-2">
                            <b>We support practices of all sizes.</b>
                            <br />
                            If you have over 26 learners, contact us 
                            for enterprise pricing, support, and
                            other customizable options.
                          </Card.Text>
                        </Col>
                        <Col md={4} className="contact-button">
                          <Button
                            variant="primary"
                            className="contact-sale-btn"
                            onClick={()=>contact()}
                          >
                            Contact Sales
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                    <Card className="faq-card">
                      <Card.Body>
                            <p className='box-faq-title'>Frequently asked questions</p>
                            <p>Find all you need to know about<br/> our ophthalmic technician training</p>
                            <a className='box-btn' href='https://www.alchemyvision.com/faq' target="_blank" rel="noreferrer"><img src={Union} alt='union' />&nbsp;&nbsp;&nbsp;Read through our FAQs</a>
                      </Card.Body>  
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="faq-card">
                      <Card.Body>
                            <p className='box-faq-title'>Watch a demo video</p>
                            <p>Get to know our eye care staff<br/> training plan in a visual way</p>
                            <a className='box-btn' href='https://www.youtube.com/watch?v=8cBRpXHNNdc' target="_blank" rel="noreferrer"><i className="fa fa-play"></i>&nbsp;&nbsp;&nbsp;Watch our demo video</a>
                      </Card.Body>
                    </Card>
                </Col>
             </Row>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default Welcome;
