import * as actionTypes from "./actions";
import { FaqState } from "../../types/Faq.type";
const initialState: FaqState = {
  faqs: {
    loading: false,
    data: [],
    error: null,
  },
};

const FaqReducer = (state: FaqState = initialState, action: any): FaqState => {
  switch (action.type) {
    case actionTypes.GET_FAQ:
      return {
        ...state,
        faqs: {
          loading: true,
        },
      };

    case actionTypes.GET_FAQ_SUCCESS:
      return {
        ...state,
        faqs: {
          data: action?.data,
          loading: false,
        },
      };

    case actionTypes.GET_FAQ_FAILED:
      return {
        ...state,
        faqs: {
          error: action.error,
          loading: false,
        },
      };
  }
  return state;
};

export default FaqReducer;
