import Overlay from "react-bootstrap/Overlay";
import parse from "html-react-parser";

function Overlaypeople(dataprops: any) {
  return (
    <>
      <Overlay
        target={dataprops?.target?.current}
        show={dataprops.show}
        placement="bottom"
      >
        {({
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "#F4F5EE",
              padding: "5px 20px",
              color: "#000",
              border: "2px solid #787A6D !important",
              fontSize: "23px",
              fontWeight: "400",
              fontFamily: "Space Grotesk",
              left: "170px !important",
              lineHeight: "39px",
              borderRadius: 5,
              ...props.style,
            }}
            className={dataprops.classname}
          >
            {dataprops.content !== true
              ? dataprops?.peoplesArray?.map((people: any,index:number) => {
                  if(index===0){
                    return <span></span>;
                  }else{
                    if(people?.profile){
                      return <p>{people?.profile?.name}</p>;
                    }else{
                      return <p>{people?.name}</p>;
                    }
                  }
                })
              : parse(dataprops.contentData)}
          </div>
        )}
      </Overlay>
    </>
  );
}

export default Overlaypeople;
