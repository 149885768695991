import './register.scss';
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from 'formik';
import * as CryptoJS from 'crypto-js';
import { AppConfig } from "../../configs/app.config";
import apiService from "@services/api.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/esm/Spinner";
import Loginbtn from "../../assets/images/LoginButton.png";
import PhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import PlusIcon from "../../../src/assets/images/plus-icon.svg";


const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name is too short!')
    .max(50, 'name is too long!')
    .required('name is required!'),
  surname: Yup.string()
    .min(2, 'surname is too short!')
    .max(50, 'surname is too long!')
    .required('surname is required!'),
  password: Yup.string()
    .required('password is required!')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required('confirm password is required!')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
  email: Yup.string()
    .email('email is invalid')
    .required('email is required'),
});

function Register() {
  const [encodedData, setEncodedData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(null);
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>('https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg');

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      contactNumber: '',
      jobTitle: '',
      certifications: '',
      medicalPracticeId: '',
      roleId: '',
      profilePicture: ''
    },
    validationSchema: SignupSchema,
    onSubmit: values => {
      if (values?.password?.toLowerCase().includes(values?.name?.toLowerCase()) || 
      values?.password?.toLowerCase()?.includes(values?.surname?.toLowerCase())
      ) {
        toast.error('Password does not contain name or surname', {
          position: "top-right",
        });
      } else {
        if (encodedData?.licenseAllocationId) {
          const formData = new FormData();
          formData.append('email', values.email);
          formData.append('password', values.password);
          formData.append('name', values.name);
          formData.append('surname', values.surname);
          formData.append('medicalPracticeId', encodedData.medicalPracticeId);
          formData.append('licenseAllocationId', encodedData.licenseAllocationId);
          formData.append('roleId', encodedData.roleId);
          formData.append('groupMembershipId', encodedData.groupMembershipId);
          
          if (values?.certifications) {
            formData.append('certifications', values.certifications);
          }

          if (values?.jobTitle) {
            formData.append('jobTitle', values.jobTitle);
          }

          if (values?.contactNumber) {
            formData.append('contactNumber', values.contactNumber);
          }

          if (profilePicture) {
            formData.append('profilePicture', profilePicture);
          }
  
          setIsLoading(true);

          apiService.registerProfile(formData).then((response) => {
            setIsLoading(false);
  
            if (response) {
              toast.success('registered successfully', {
                position: "top-right",
              });
  
              navigate('/login');
            }
  
          }).catch((error) => {
            console.log('error: ', error);
  
            toast.error(error?.response?.data?.message, {
              position: "top-right",
            });
  
            setIsLoading(false);
          });
        } else {
          console.log('License allocation id not found');
        }
      }
    }
  });

  function isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cipherText = urlParams.get('invite');

    if (cipherText) {
      const decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
      const bytes = CryptoJS.AES.decrypt(decData, AppConfig.encodeKey).toString(CryptoJS.enc.Utf8);

      if (bytes && isJsonString(bytes)) {
        const results = JSON.parse(bytes);
        // let days = moment().diff(moment(results.expDate), 'days');
        if (results) {
          formik.setValues(results);
          setEncodedData(results);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  return (
    <div className="register-container">
      <div className="register-block">

        <form onSubmit={formik.handleSubmit}>

          <div className="avatar-container">
            <div className="avatar">
              <img onClick={() => { document.getElementById('profilePicture')?.click() }} className="image" alt="" src={previewImage} />
              <img onClick={() => { document.getElementById('profilePicture')?.click() }} className="plus-icon" alt="plus" src={PlusIcon} />
              <input
                id="profilePicture"
                name="profilePicture"
                type="file"
                className="form-control mt-1 no-radious"
                accept="image/png, image/gif, image/jpeg"
                value={formik.values.profilePicture}
                onChange={(event) => {
                  if (event?.target?.files) {
                    setProfilePicture(event.target.files[0]);
                    setPreviewImage(URL.createObjectURL(event.target.files[0]));
                  }
                }}
              />
            </div>
          </div>

          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label">Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
              </div>

              { (formik.errors.name && formik.touched.name) && <p className='error'> { formik.errors.name } </p> }  
            </Col>
          </Row>

          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label">Surname</label>
                <input
                  id="surname"
                  name="surname"
                  type="text"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.surname}
                />
              </div>

              { (formik.errors.surname && formik.touched.surname) && <p className='error'> { formik.errors.surname } </p> }  
            </Col>
          </Row>

          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label">Email</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>

              { (formik.errors.email && formik.touched.email) && <p className='error'> { formik.errors.email } </p> }  
            </Col>
          </Row>


          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </div>

              { (formik.errors.password && formik.touched.password) && <p className='error'> { formik.errors.password } </p> }  
            </Col>
          </Row>

          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label confirm-password">Confirm Password</label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
              </div>

              { (formik.errors.confirmPassword && formik.touched.confirmPassword) && <p className='error'> { formik.errors.confirmPassword } </p> }  
            </Col>
          </Row>

          {encodedData?.roleId === 1 && <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label job-title">Job Title</label>
                <input
                  id="jobTitle"
                  name="jobTitle"
                  type="text"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.jobTitle}
                />
              </div>
            </Col>
          </Row>}

          {encodedData?.roleId === 1 && <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <div className="input-item">
                <label className="item-label">Certifications</label>
                <input
                  id="certifications"
                  name="certifications"
                  type="text"
                  className="form-control item-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.certifications}
                />
              </div>
            </Col>
          </Row>}

          <Row className="mt-4 gx-5" style={{ marginBottom: "16px" }}>
            <Col style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} md={12}>
              <div className="input-item">
                <label className="item-label contact-number">Contact Number</label>
                <PhoneInput
                  inputStyle={{
                    background: 'transparent',
                    boxShadow: 'none'
                  }}
                  country={"us"}
                  disableDropdown={true}
                  inputProps={{
                    id: "contactNumber",
                    name: "contactNumber",
                    className: "form-control mt-1 no-radious",
                  }}
                  placeholder=""
                  onChange={(contactNumber: any) => {
                    formik.setFieldValue('contactNumber', contactNumber);
                  }}
                  countryCodeEditable={false}
                />
              </div>

              <Button className="submit-button" type="submit" disabled={isLoading || !formik.isValid}>
                {isLoading ?
                  <Spinner className="loader" animation="border" /> : 
                  <img className="submit-icon" src={Loginbtn} alt="login-btn" />
                }
              </Button>
            </Col>
          </Row>

          <a href='/sign-in' className="already-account-text">Already have an account?</a>
        </form>
      </div>
    </div>
  );
}

export default Register;
