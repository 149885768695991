// learner actions
export const GET_LEARNER = "GET_LEARNER";
export const GET_LEARNER_SUCCESS = "GET_LEARNER_SUCCESS";
export const GET_LEARNER_FAILED = "GET_LEARNER_FAILED";

// learner state actions
export const GET_EXAMSTATES = "GET_EXAMSTATES";
export const GET_EXAMSTATES_SUCCESS = "GET_EXAMSTATES_SUCCESS";
export const GET_EXAMSTATES_FAILED = "GET_EXAMSTATES_FAILED";

// learner login actions
export const GET_LOGINSTATES = "GET_LOGINSTATES";
export const GET_LOGINSTATES_SUCCESS = "GET_LOGINSTATES_SUCCESS";
export const GET_LOGINSTATES_FAILED = "GET_LOGINSTATES_FAILED";

// send progress report
export const SEND_LOGINSTATES = "SEND_LOGINSTATES";
export const SEND_LOGINSTATES_SUCCESS = "SEND_LOGINSTATES_SUCCESS";
export const SEND_LOGINSTATES_FAILED = "SEND_LOGINSTATES_FAILED";





