import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import parse from "html-react-parser";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function Plan({ plan }: { plan: any }) {
  
  const lightColor = {
    color:plan?.colorConfig?.homePage?.titleColor
  }
  const youtube = {
    color:plan?.colorConfig?.homePage?.cardHeader,
    border:`2px solid `+plan?.colorConfig?.homePage?.cardHeader,
    borderRadius:`30px`,
    padding:'3px 13px',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0em',

  }
  
  const textColor = {
    color:plan?.colorConfig?.homePage?.titleColor
  }
  const textColor2 = {
    color:plan?.colorConfig?.homePage?.buttonColor
  }
  const cardBody = {
    background:plan?.colorConfig?.homePage?.cardBody
  }

  const checkPrice = (plan:any) => {
    let lastprice= plan?.planConfig[0]?.price;
    lastprice = plan?.planConfig?.map((config:any)=>{
        if(config?.price < lastprice){
          lastprice=config?.price;
        }
        return lastprice;
    });
    return lastprice;
  }
 
  
  return (
    <Col md={4} sm={12} className='mobile-margin-bottom'>
      <Card className="plan-card">
        {/* <div className="img-part" style={style}>
            <div className='row title-padding'>
                <div className='col-md-8 col-8 col-sm-8 mb-2 mt-2'> 
                  <Card.Title className="text-left plan-name">
                    {plan?.name}
                  </Card.Title>
                  <p className='subtitle-text'>{plan?.subtitle}</p>
                </div>
                <div className='col-md-4 col-3 col-sm-4 margin-auto'>
                    {plan?.primaryIcon ? (
                      <img alt="plan" height='70px' width='70px' src={plan?.primaryIcon} />
                    ) : (
                      <button className="plan-icon"></button>
                    )}
                </div>
              </div>
        </div> */}
        <div style={cardBody} className='c-body pt-3 pb-4'>
          <div style={{padding:'0px 20px 0px 20px'}}>
            <img alt="plan" height='50px' width='50px' src={plan?.planPageIcon}  />
            <h2 className='plan-name-welcome' style={textColor}>{plan?.name}</h2>
            {typeof plan?.subtext === 'string'  && (
              <span className='charge-sub' style={textColor2}>{parse(plan?.planSubTitle ? plan?.planSubTitle : '')}</span>
            )}  
            {typeof plan?.subtext === 'string'  && (
              <span className='charge-secondorytext' style={lightColor}>{parse(plan?.subtitle ? plan?.subtitle : '')}</span>
            )}  
            </div>
            {/* {
              plan?.planConfig?.length > 0 && (
              <>
              <div className='as-low-as-div'>
               <span className='as-low-as-text' style={textColor}>As low as</span><br/>
              </div> 
              </>
              )
            } */}
          <div className="text-left mt-4  charge-padding">
            {           
              plan?.planConfig?.length === 0 ? (

              plan?.monthlyPrice !== 0
                ? <><span className='plan-price' style={textColor2}>${plan?.monthlyPrice}</span><span className='price-simple-text' style={lightColor}> per learner /mo</span></>
                : `Free`

              ) 
              :
              <><span className='plan-price' style={textColor}>${checkPrice(plan)}</span><span className='price-simple-text' style={lightColor}> per learner /mo</span></>
            }   
          </div>
       
          <div className='text-center mt-4'>
          
            <Popup trigger={<button className='video-link no-bg' style={youtube}><i className="fa fa-play" style={lightColor}></i> Watch a 30-second demo</button>} modal>
              <div><iframe width="100%" height="470" src={plan?.videoSource} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
           </Popup>
          </div>
        </div>
      </Card>
    </Col>
  );

  

}

export default Plan;
