export const AppConfig = {
  apiBaseURL: "https://azapp-signup-portal-service-prod-001.azurewebsites.net",
  stripePrimaryKey:
    "pk_live_51H7nakBvVNJH7qzyS2jBtIjhhTygsXOGLngcwrdRZMa76w3UEQU4lCkVexm9U8ew00HdXaTytA3uCRcyEp7jRKel009L4eOxW3",
  accountName: "alchemydev001",
  containerName: "signup-portal",
  encodeKey: 'secret key 123',
  productUrl: 'https://www.alchemyvision.com/solutions',
  pricingUrl: 'https://www.alchemyvision.com/pricing',
  blog: 'https://medium.com/@alchemyvision',
  about: 'https://www.alchemyvision.com/about',
  terms: 'https://www.alchemyvision.com/pages/terms',
  demo: 'https://www.alchemyvision.com/demo',
  linkedIn: 'https://www.linkedin.com/company/alchemyvision/',
  instagram: 'https://www.instagram.com/alchemyvision/',
  logoutRedirectUrl: "https://AlchemyVisionDev.b2clogin.com/AlchemyVisionDev.onmicrosoft.com/B2C_1_LoginFlow/oauth2/v2.0/logout?post_logout_redirect_uri=https://app.alchemyvision.com/login",
  emergeurl:'https://app.alchemyvision.com/essential',
  entryurl:'https://app.alchemyvision.com/professional', 
  loginurl:'https://app.alchemyvision.com/login', 
};
