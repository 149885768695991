import React from "react";
import Container from "react-bootstrap/Container";
import "./Myaccount.scss";
import { Link } from "react-router-dom";
import Myaccountbox from "./Myaccountbox";

function Mypractice() {
  return (
    <div className="my-account">
      <section className="section-practice">
        <Container>
          <div className="back-to-dashboard">
            &#60;
            <Link to="/dashboard">
              <span> Back to My Dashboard</span>
            </Link>
          </div>
          <Myaccountbox />
        </Container>
      </section>
    </div>
  );
}

export default Mypractice;
