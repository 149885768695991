import * as actionTypes from "./actions";
import { myAccountState } from "../../types/Myaccount.type";
const initialState: myAccountState = {
  myaccount: {
    loading: false,
    data: [],
    error: null,
  },
  editAccount: {
    loading: false,
    data: [],
    error: null,
  },
  changePassword: {
    loading: false,
    data: [],
    error: null,
  },
};

const accountReducer = (
  state: myAccountState = initialState,
  action: any
): myAccountState => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT:
      return {
        ...state,
        myaccount: {
          loading: true,
        },
      };

    case actionTypes.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        myaccount: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.GET_ACCOUNT_FAILED:
      return {
        ...state,
        myaccount: {
          error: action.error,
          loading: false,
        },
      };

      case actionTypes.UPDATE_ACCOUNT:
        return {
          ...state,
          editAccount: {
            loading: true,
          },
        };
  
      case actionTypes.UPDATE_ACCOUNT_SUCCESS:
        return {
          ...state,
          editAccount: {
            data: action.data,
            loading: false,
          },
        };
  
      case actionTypes.UPDATE_ACCOUNT_FAILED:
        return {
          ...state,
          editAccount: {
            error: action.error,
            loading: false,
          },
        };

        case actionTypes.RESET_PASSWORD:
          return {
            ...state,
            changePassword: {
              loading: true,
            },
          };
    
        case actionTypes.RESET_PASSWORD_SUCCESS:
          return {
            ...state,
            changePassword: {
              data: action.data,
              loading: false,
            },
          };
    
        case actionTypes.RESET_PASSWORD_FAILED:
          return {
            ...state,
            changePassword: {
              error: action.error,
              loading: false,
            },
          };

          case actionTypes.RESET_PASSWORD_RESET:
          return {
            ...state,
            changePassword: {
              loading: false,
              data: [],
              error: null,
            },
          };

          

      case actionTypes.RESET_ACCOUNTEDIT:
        return{
          ...state,
          editAccount: {
            loading: false,
            data: [],
            error: null,
          },
        }


  }
  return state;
};

export default accountReducer;
