import React, { useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Exclamation from "../../../src/assets/images/exclamation.svg";
import Overlaypeople from "./Overlaypeople";
import { Link } from "react-router-dom";
import { getExamState,getLoginState } from "../../store/learner/effect";
import { useDispatch, useSelector } from "react-redux";
import { GetExamState } from "../../types/Learners.type";
import Grow from "../loader/Grow";
import Peopleround from "../../../src/assets/images/peopleround.png";
import Learningpath from "../../../src/assets/images/Emerge-Logo 2.svg";
import Enrichlogo from "../../../src/assets/images/enrichlogo.png";
import parse from "html-react-parser";

function Learnerstate() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showEmerge, setShowEmerge]: any = useState({});

  const target = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const target4 = useRef(null);
  const dispatch = useDispatch();

  const examStates = useSelector(
    (state: GetExamState) => state.learnerState.examdata
  );
  const loginStates = useSelector(
    (state: any) => state.learnerState.logindata
  );

  const formateUpdateDate =(date:any) =>{
    var myDate = new Date(date)
    var pstDate = myDate.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles"
    })
    var psdPrintDate = new Date(pstDate)
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

    let hours = psdPrintDate.getHours();
    let day = psdPrintDate.getDate();
    let month = psdPrintDate.getMonth();
    let year = psdPrintDate.getFullYear();
    let getminute = psdPrintDate.getMinutes(); 
    return `Updated at `+hours+`:`+(getminute<10?'0':'') + getminute+` PST, `+day+` `+monthNames[month]+`, `+year;
  }

  React.useEffect(() => {
    dispatch(getExamState());
    dispatch(getLoginState());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let emergeData: any = {};
    examStates?.data?.forEach((value: any) => {
      emergeData = { ...emergeData, [value.learningPathId]: false };
    });
    setShowEmerge(emergeData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examStates?.data]);

  return (
    <>
      <Col md={12}>
        <div className="common-box">
          <div className="partition practice-color">
            <Row>
              <Col md={6}>
                <span className="common-text">Learner Stats </span>
                <br />
              </Col>
              <Col md={6} className="text-right" style={{display:'flex',alignItems: 'center',justifyContent:'flex-end'}}>
                  {
                    loginStates?.data?.latestUpdatedDateOfLearnerState && 
                    (
                      <span className="common--sub-text">
                         {formateUpdateDate(loginStates?.data?.latestUpdatedDateOfLearnerState)}
                      </span>
                    )
                  }
                <br />
              </Col>
            </Row>
          </div>
          <div className="partition">
            <Row>
              <Col md={6} className="mt-4">
                <div className="first-box">
                  <div className="row">
                    <div className="first-box-sub-title">
                      Logged In This Week
                    </div>
                    <div className="first-box-main-title">
                      {
                        loginStates?.data?.loggedInIntoLast7days?.length > 0 ? (
                          <>
                        {loginStates?.data?.loggedInIntoLast7days[0]?.profile?.name}
                        <Link
                          to="#"
                          ref={target2}
                          onClick={() => setShow2(!show2)}
                          className="more"
                        >
                          {" "}
                          {loginStates?.data?.loggedInIntoLast7days?.length > 1 &&
                          (
                            <>
                            +{loginStates?.data?.loggedInIntoLast7days?.length-1} more{" "}
                           </>
                          )
                          }
                        </Link>
                        <Overlaypeople
                          show={show2}
                          target={target2}
                          peoplesArray={loginStates?.data?.loggedInIntoLast7days}
                          classname="custome"
                        />
                        </>
                      ):
                      'No Data Yet'
                    }
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="first-box-sub-title">Program Completed</div>
                    <div className="first-box-main-title">
                    {
                        loginStates?.data?.listOfUsersWhoHaveCompletedAllCourses?.length > 0
                        ? (
                          <>
                        {loginStates?.data?.listOfUsersWhoHaveCompletedAllCourses[0]?.name}
                        <Link
                          to="#"
                          ref={target3}
                          onClick={() => setShow3(!show3)}
                          className="more"
                        >
                          {loginStates?.data?.listOfUsersWhoHaveCompletedAllCourses?.length > 1 &&
                          (
                            <>
                            +{loginStates?.data?.listOfUsersWhoHaveCompletedAllCourses?.length-1} more
                           </>
                          )
                          }
                        </Link>
                        <Overlaypeople
                          show={show3}
                          target={target3}
                          peoplesArray={loginStates?.data?.listOfUsersWhoHaveCompletedAllCourses}
                          classname="custome"
                        />
                        </>
                      ):
                      'No Data Yet'
                    }
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-4">
                <div className="second-box">
                  <div className="row">
                    <div className="first-box-sub-title">
                      1+ Week Since Last Login
                    </div>
                    <div className="first-box-main-title">
                    {
                        loginStates?.data?.notLoggedInIntoLast7days?.length > 0
                        ? (
                          <>
                        {loginStates?.data?.notLoggedInIntoLast7days[0]?.profile?.name} 
                        <Link
                          to="#"
                          ref={target4}
                          onClick={() => setShow4(!show4)}
                          className="more"
                        >
                          {loginStates?.data?.notLoggedInIntoLast7days?.length > 1 &&
                          (
                            <>
                            +{loginStates?.data?.notLoggedInIntoLast7days?.length-1} more{" "}
                           </>
                          )
                          }
                        </Link>
                        <Overlaypeople
                          show={show4}
                          target={target4}
                          peoplesArray={loginStates?.data?.notLoggedInIntoLast7days}
                          classname="custome"
                        />
                        </>
                      ):
                      'No Data Yet'
                    }
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="first-box-sub-title">Not Yet Started</div>
                    <div className="first-box-main-title">
                    {
                        loginStates?.data?.listOfUsersWhoHaveNotStartedAnyCourses?.length >0
                        ? (
                          <>
                        {loginStates?.data?.listOfUsersWhoHaveNotStartedAnyCourses[0]?.name} 
                        <Link
                          to="#"
                          ref={target}
                          onClick={() => setShow(!show)}
                          className="more"
                        >
                          {loginStates?.data?.listOfUsersWhoHaveNotStartedAnyCourses?.length > 1 &&
                          (
                            <>
                            +{loginStates?.data?.listOfUsersWhoHaveNotStartedAnyCourses?.length-1} more{" "}
                           </>
                          )
                          }
                        </Link>
                        <Overlaypeople
                          show={show}
                          target={target}
                          peoplesArray={loginStates?.data?.listOfUsersWhoHaveNotStartedAnyCourses}
                          classname="custome"
                        />
                        </>
                      ):
                      'No Data Yet'
                    }
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="gx-5">
            <Row>
          </Row>
              {!examStates?.loading ? (
                  <div > 
                    {examStates?.data && examStates?.data?.length > 0 ? (
                        <>
                        <Row className="gx-5">
                              {examStates?.data?.map((examState: any) => {
                                return (
                                  <>
                                    <Col className="mt-4" md={4}>
                                      <div className="inside-box">
                                        <div className="inside-box-1">
                                          <Row>
                                            <Col md={3} xs={3}>
                                              <img
                                                src={examState?.learningPathImage}
                                                alt="people img"
                                                height={65}
                                                width={65}
                                              />
                                            </Col>
                                            <Col md={8} xs={8}>
                                              <span className="inside-box-1-title">
                                                {examState?.learningPath}{" "}
                                              </span>
                                              <br />
                                              <span className="inside-box-1-desc">
                                                Learning Path
                                              </span>
                                            </Col>
                                            <Col md={1} xs={1}>
                                              <div className="dropdown">
                                                <button
                                                  id="dropdownMenuButton1"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  className="no-border info-img no-bg"
                                                >
                                                  <img
                                                    className="exclamation-png"
                                                    src={Exclamation}
                                                    alt="Exclamation"
                                                  />
                                                </button>
                                                <div
                                                  className="dropdown-menu no-margin no-border"
                                                  aria-labelledby="dropdownMenuButton1"
                                                >
                                                  <div className="info-desc">
                                                    {parse(examState?.description)}
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                          {examState?.isDataAvailable===null ? (
                                            <>
                                              <Row className="mt-4">
                                                <Col md={12} xs={12}>
                                                  <span className="no-data-yet">
                                                    You currently have no learners on the {examState?.learningPath} learning path.
                                                  </span>
                                                </Col>
                                                <Col
                                                  md={12}
                                                  xs={12}
                                                  className="minus-marge"
                                                ></Col>
                                              </Row>
                                              <Row className="mt-4">
                                                <span className="no-data-yet">
                                                  <a className='click-here-link' href='https://www.alchemyvision.com/solutions'>Click here</a> to learn more <br />
                                                  about {examState?.learningPath} for your team.
                                                </span>
                                              </Row>
                                            </>
                                          ) : (
                                            <>
                                              <Row className="mt-4">
                                                <Col md={12} xs={12}>
                                                  <span className="prcentage-count">
                                                    {examState?.avgExamScore ? examState?.avgExamScore+`%` : 'No data yet'}
                                                  </span>
                                                </Col>
                                                <Col md={12} xs={12} className="minus-marge">
                                                  <span className="prcentage-text">
                                                    Average Quiz Score
                                                  </span>
                                                </Col>
                                              </Row>
                                              <Row className="mt-4 mb-2">
                                                <Col md={12} xs={12}>
                                                  <span className="prcentage-count">
                                                    {examState?.avgFinalExamScore ? examState?.avgFinalExamScore+`%` : 'No data yet'}
                                                  </span>
                                                </Col>
                                                <Col md={12} xs={12} className="minus-marge">
                                                  <span className="prcentage-text">
                                                    Average Final Exam Score
                                                  </span>
                                                </Col>
                                              </Row>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </>
                                );
                              })}
                        </Row>
                        </>
                      ) : (
                        <Row className="gx-5">
                      <Col className="mt-4" md={4}>
                        <div className="inside-box">
                          <div className="inside-box-1">
                            <Row>
                              <Col md={3} xs={3}>
                                <img
                                  src={Peopleround}
                                  alt="people img"
                                  height={65}
                                  width={65}
                                />
                              </Col>
                              <Col md={8} xs={8}>
                                <span className="inside-box-1-title">
                                  Emerge{" "}
                                </span>
                                <br />
                                <span className="inside-box-1-desc">
                                  Learning Path
                                </span>
                              </Col>
                              <Col md={1} xs={1}>
                                <button
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="no-border no-bg ml-10"
                                  >
                                    <img
                                    className="exclamation-png"
                                    src={Exclamation}
                                    alt="Exclamation"
                                    />
                                  </button>
                                  <div
                                  className="dropdown-menu no-margin no-border"
                                  aria-labelledby="dropdownMenuButton1"
                                  >
                                    <div className="info-desc">
                                    <b>Emerge</b> is an onboard training program designed for new-hires and employees that are entering the world of eye care for the first time.
                                    </div>
                                  </div>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={12} xs={12}>
                                <span className="no-data-yet">
                                  You currently have no learners on the Emerge
                                  learning path.
                                </span>
                              </Col>
                              <Col
                                md={12}
                                xs={12}
                                className="minus-marge"
                              ></Col>
                            </Row>
                            <Row className="mt-4">
                              <span className="no-data-yet">
                              <a className='click-here-link' href='https://www.alchemyvision.com/solutions'>Click here</a> to learn more<br />
                                about Emerge for your team.
                              </span>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col className="mt-4" md={4}>
                        <div className="inside-box">
                          <div className="inside-box-1">
                            <Row>
                              <Col md={3} xs={3}>
                                <img
                                  src={Learningpath}
                                  alt="people img"
                                  height={65}
                                  width={65}
                                />
                              </Col>
                              <Col md={8} xs={8}>
                                <span className="inside-box-1-title">
                                  Entry{" "}
                                </span>
                                <br />
                                <span className="inside-box-1-desc">
                                  Learning Path
                                </span>
                              </Col>
                              <Col md={1} xs={1}>
                                 <button
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="no-border no-bg ml-10"
                                  >
                                    <img
                                    className="exclamation-png"
                                    src={Exclamation}
                                    alt="Exclamation"
                                    />
                                  </button>
                                  <div
                                  className="dropdown-menu no-margin"
                                  aria-labelledby="dropdownMenuButton1"
                                  >
                                    <div className="info-desc">
                                    <b>Entry</b> is an onboard training program designed for new-hires and employees that are entering the world of eye care for the first time.
                                    </div>
                                  </div>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={12} xs={12}>
                                <span className="no-data-yet">
                                  You currently have no learners on the Entry
                                  learning path.
                                </span>
                              </Col>
                              <Col
                                md={12}
                                xs={12}
                                className="minus-marge"
                              ></Col>
                            </Row>
                            <Row className="mt-4">
                              <span className="no-data-yet">
                              <a className='click-here-link' href='https://www.alchemyvision.com/solutions'>Click here</a> to learn more <br />
                                about Entry for your team.
                              </span>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col className="mt-4" md={4}>
                        <div className="inside-box">
                          <div className="inside-box-1">
                            <Row>
                              <Col md={3} xs={3}>
                                <img
                                  src={Enrichlogo}
                                  alt="people img"
                                  height={65}
                                  width={65}
                                />
                              </Col>
                              <Col md={8} xs={8}>
                                <span className="inside-box-1-title">
                                Enrich{" "}
                                </span>
                                <br />
                                <span className="inside-box-1-desc">
                                  Learning Path
                                </span>
                              </Col>
                              <Col md={1} xs={1}>
                                <button
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="no-border no-bg ml-10"
                                  >
                                    <img
                                    className="exclamation-png"
                                    src={Exclamation}
                                    alt="Exclamation"
                                    />
                                  </button>
                                  <div
                                  className="dropdown-menu no-margin no-border"
                                  aria-labelledby="dropdownMenuButton1"
                                  >
                                    <div className="info-desc">
                                    <b>Enrich</b> is an onboard training program designed for new-hires and employees that are entering the world of eye care for the first time.
                                    </div>
                                  </div>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={12} xs={12}>
                                <span className="no-data-yet">
                                  You currently have no learners on the Enrich
                                  learning path.
                                </span>
                              </Col>
                              <Col
                                md={12}
                                xs={12}
                                className="minus-marge"
                              ></Col>
                            </Row>
                            <Row className="mt-4">
                              <span className="no-data-yet">
                              <a className='click-here-link' href='https://www.alchemyvision.com/solutions'>Click here</a> to learn more <br />
                                about Enrich for your team.
                              </span>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      </Row>
                      )}
                    </div>
                    ) : (
                      <div className='mt-5'><Grow /></div>
                    )}
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
}

export default Learnerstate;
