import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Plan from "./Plan";
// import Beliving from "../welcome/Beliving";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getPlans } from "../../store/plans/effect";
import { useState } from "react";
import { SinglePlan, GetPlanState } from "../../types/Plan.type";
import { toast } from "react-toastify";
import Grow from "../loader/Grow";
import { useNavigate } from "react-router-dom";
import { getFaq } from "../../store/faqs/effect";

function PaidSignUp(props:any) {

  console.log('props',props)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planState = useSelector((state: GetPlanState) => state.planState.plans);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  let [total, setTotal] = useState(0);
  let [qut, setQut] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edit, setEdit] = useState(false);
  const [exist,setExist] = useState(true);

  React.useEffect(() => {
    const checkTypewriter = document.querySelector('.header-pending')
    if (!checkTypewriter) {
      setExist(false)
    }
    dispatch(getFaq());
    if (!searchParams.get("edit")) {
      dispatch(getPlans(2));
    } else {
      var tempTotal = 0;
      var totalQut = 0;
      planState?.data?.map((item: SinglePlan, index: number) => {
        if (item.quantity && item.monthlyPrice) {
          tempTotal = tempTotal + item.quantity * item.monthlyPrice;
          totalQut = totalQut + item.quantity;
        }
        return item;
      });
      setTotal(tempTotal);
      setEdit(true);
      setQut(totalQut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (planState?.error) {
      if(planState?.error?.statusText){
      toast.error(planState?.error?.statusText, {
        position: "top-right",
      });
    }
    }
  }, [planState.error]);

  React.useEffect(() => {
    console.log('paid',planState);
    var tempTotal = 0;
    var totalQut = 0;
    planState?.data?.map((item: SinglePlan, index: number) => {
      if (item.quantity && item.monthlyPrice) {
        tempTotal = tempTotal + item.quantity * item.monthlyPrice;
        totalQut = totalQut + item.quantity;
      }
      return item;
    });
    setTotal(tempTotal);
    setQut(totalQut);
  }, [planState]);


  const signup = () => {
    if(qut > 25){
      navigate("/contact")
    }else{
      if(exist===true){
        navigate('/signup')
        }else{
        navigate('/signupwithoutheader')
        }
    }
  };

  return (
    <div>
      <section className="team-section">
        <Container className="small-container ">
          <h1 className="team-title-text">Choose one license for each staff member</h1>
          <div className="team-sub-text-box">
            {/* <span className="team-sub-text">
              Select however many licenses you need for your team, including leaders to manage them.
            </span> */}
          </div>
          <div className="get-plans">
            <div className="get-plans">
              {!planState?.loading ? (
                <div>
                  {planState?.data && planState?.data?.length > 0 ? (
                    <Row className="gx-4">
                      {planState?.data?.map(
                        (plan: SinglePlan, index: number) => {
                          return <Plan key={index} plan={plan} />;
                        }
                      )}
                    </Row>
                  ) : (
                    <div className="no-data-found">
                      <h4>No Data Available</h4>
                    </div>
                  )}
                </div>
              ) : (
                <Grow />
              )}
            </div>
            <Row className="team-started">
              <Container className="small-container ">
                <h1 className="team-price">${total}/mo</h1>
                <Row>
                  <Col md={3}></Col>
                  <Col md={6} sm={12} className="mt-2 text-center">
                    <span className="team-price-sub-text">
                      We’ll take your practice info next
                    </span>
                  </Col>
                  <Col md={3}></Col>
                </Row>
                <Row className="gx-5" style={{ marginTop: "-2rem" }}>
                  <Col md={12} className="text-center team-start-div">
                    {total > 0 ? (
                      <Button
                        onClick={() => {
                          signup();
                        }}
                        className="team-start-btn"
                      >
                        {qut <= 25
                          ? "Get your team started 🚀"
                          : "Get in touch with Sales 📱"}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          signup();
                        }}
                        className="team-start-btn"
                        disabled
                      >
                        Get your team started 🚀
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className='mt-5'>
                 <span className='etc-text'>If you still have questions before committing to a subscription,<br/> you can contact us at <a style={{color:'#3B3C37'}} href='mailto:sales@alchemyvision.com'>sales@alchemyvision.com</a> or <a style={{color:'#3B3C37'}} href='tel:+1 213 510 8050'>+1 213 510 8050</a></span>
                </Row>
              </Container>
            </Row>
          </div>
        </Container>
      </section>
      {/* <section className="faqs">
        <Container className="small-container ">
          <h3 className="faq-title">FAQs</h3>
          {!faqState?.loading ? (
            <div>
              {faqState?.data && faqState?.data?.length > 0 ? (
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                  {faqState?.data?.map((faq: SingleFaq) => (
                    <Faq faq={faq} key={faq.id} />
                  ))}
                </Accordion>
              ) : (
                <div className="no-data-found">
                  <h4 className="no-data-found" style={{ color: "#F4F5EE" }}>
                    No Data Found
                  </h4>
                </div>
              )}
            </div>
          ) : (
            <Grow />
          )}
        </Container>
      </section> */}
      <section className="demo">
        {/* <Beliving /> */}
      </section>
    </div>
  );
}

export default PaidSignUp;
