import React,{useState} from "react";
import "./Thankyou.scss";
import { useNavigate } from "react-router-dom";
import { REGISTER_DATA_RESET } from "../../store/register/actions";
import { useDispatch } from "react-redux";
import Mailimg from "../../assets/images/mailIcon.svg";
import { GetRegisterDataState } from "../../types/Register.type";
import { useSelector } from "react-redux";
import ApiService from "../../services/api.service";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AppConfig } from "../../configs/app.config";

function Thankyou() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email,setEmail] = useState('');
  const registerDataState: any = useSelector(
    (state: GetRegisterDataState) => state?.registerState.registerData
  );
  const [from,setFrom]= useState('');
 
  React.useEffect(() => {
    if(registerDataState?.data === null && ApiService?.thankyouemail === null){
      navigate("/");
    }
    if(registerDataState?.data === null){
      setEmail(ApiService?.thankyouemail)
      setFrom('apiservice')
    }
    if(ApiService?.thankyouemail === null){
      setEmail(registerDataState?.data?.workEmail)
      setFrom('register')
    }
    dispatch({
      type: REGISTER_DATA_RESET
    });
    ApiService.thankyouemail = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
    <div className="welcome">
      <section className="thankyou">
        <div
          className="wrapperAlert"
          style={{ margin: "0px auto", textAlign: "center" }}
        >
          <div className="content mt-5">
              <img src={Mailimg} alt='mailimg' height='100px' width='75px' />
              <div className='mt-2'>
                {
                  from === 'register' ?
                (
                  <div>
                     <span className='thankyou-title'>Thank you for your subscription!</span><br/><br/>
                     <span className='thankyou-subtext'>
                      We’ve sent an email to <b>{email}</b> so you can set up your Leader Dashboard and start adding learners from your team.<br/><br/>
                      We can’t wait for you to get started!</span>
                  </div>
                  )
                  :
                  (
                    <div>
                     <span className='thankyou-title'>Thank you for adding billing details!</span><br/><br/>
                     <span className='thankyou-subtext'>
                      We will send you an email to <b>{email}</b> so you can login to your Leader Dashboard and start exploring.<br/><br/>
                      We can’t wait for you to get started!</span>
                  </div>
                  )
                 }
              </div> 
              <Col md={12} className="text-center">
                <a
                  type="submit"
                  href={AppConfig?.loginurl}
                  className="team-start-btn"
                >
                  Go to the login page 💻
                </a>
              </Col>
          </div>
        </div>
        <Container className="small-container">
          <Row  className="text-center gx-5">
            <Col md={12}>
              <div className='pro-tip'>
                🖥️ <b>Pro Tip:</b> To ensure you have access to Alchemy Vision’s content and emails, please<br/> share the URL below with your IT administrator(s), so they can make any necessary<br/> adjustments to your network – <a className='no-hover' target='new' href='https://www.alchemyvision.com/itsupport'>https://www.alchemyvision.com/itsupport</a>
              </div>
            </Col>
          </Row>
        </Container><br/><br/><br/><br/>
      </section>
    </div>
    </>
  );
}

export default Thankyou;
