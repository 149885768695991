function Title(props: any) {
  return (
    <>
      {props.subtitle === "active" || props?.capital ? (
        <span
          className="common-description"
          style={{ textTransform: "capitalize" }}
        >
          {props?.subtitle ? props?.subtitle : '-'}
        </span>
      ) : (
        <span className="common-description">{props?.subtitle ? props?.subtitle : '-'}</span>
      )}
    </>
  );
}

export default Title;
