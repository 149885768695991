import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import React, { useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../../configs/app.config";
import { GetPlanState } from "../../types/Plan.type";
import { useSelector } from "react-redux";
import { SinglePlan } from "../../types/Plan.type";
import ApiService from "../../services/api.service";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SimpleReactValidator from "simple-react-validator";
import Subscriptionsummery from "../subscriptionsummery/Subscriptionsummery";
import { GetApplyCouponState } from "../../types/Coupon.type";
import Spinner from "react-bootstrap/Spinner";
import './stripe.scss';

const stripePromise = loadStripe(AppConfig.stripePrimaryKey);

function Stripe() {
  const { state } = useLocation() as any;
  const navigate = useNavigate();
  const planState = useSelector(
    (state: GetPlanState) => state?.planState.plans
  );
  React.useEffect(() => {
    let tempPrice = 0;
    planState?.data?.forEach((plan: any, index: number) => {
      if (plan.quantity > 0) {
        tempPrice += plan.monthlyPrice * plan.quantity;
      }
    });
    if (tempPrice <= 0) {
      navigate("/plans");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planState?.data]);

  const appearance = {
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#eee",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Montserrat",
      // See all possible variables below
    },
    // loader:'always',
    // currency:'usd'
  };

  React.useEffect(() => {
    if (state === null) {
      navigate("/plans");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  var options;
  if (state !== null) {
    options = {
      // passing the client secret obtained from the server
      clientSecret: state.clientSecret,
      appearance: appearance,
    };
  }
  return (
    <>
      <section className="stripe-section">
        <Container>
          <Elements stripe={stripePromise} options={options}>
            <StripeIntegration />
          </Elements>
        </Container>
      </section>
    </>
  );
}

function StripeIntegration() {
  const stripe = useStripe();
  const elements = useElements();
  const planState = useSelector((state: GetPlanState) => state.planState.plans);
  const [exist,setExist] = useState(true);
  const applyCouponState = useSelector(
    (state: GetApplyCouponState) => state?.couponState.applyCoupon
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disablebtn, setDisablebtn] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    address: "",
    suite: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    code: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);

  React.useEffect(()=>{
    const checkTypewriter = document.querySelector('.header-pending')
    if (!checkTypewriter) {
      setExist(false)
    }
  },[])

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
    })
  );

  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      setValidated(true);
      simpleValidator.current.showMessages();
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setDisablebtn(true);
      await stripe
        .confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: "https://app.alchemyvision.com/thankyou",
          },
          redirect: "if_required",
        })
        .then((result) => {
          if (result && !Object.hasOwn(result, "error")) {
            const selectedPlanObj = planState?.data?.map((plan: SinglePlan) => {
              if (plan.quantity) {
                if (plan?.quantity > 0) {
                  return {
                    id: plan.id,
                    quantity: plan.quantity,
                  };
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            });

            if (selectedPlanObj) {
              var selectedPlanObjfiltered = selectedPlanObj.filter(function (
                item
              ) {
                return typeof item === "object";
              });
              const payLoad = {
                plans: selectedPlanObjfiltered,
                medicalPracticeEmail: localStorage.getItem("email"),
                frequency: "Monthly",
                medicalPracticeId: localStorage.getItem("medicalPracticeId"),
                payment_method: result?.paymentIntent?.payment_method,
                couponCode: applyCouponState.lastApplied,
                location: {
                  address: billingInfo.address,
                  suite: billingInfo.state,
                  city: billingInfo.address,
                  state: billingInfo.address,
                  postalCode: billingInfo.address,
                  country: billingInfo.address,
                },
              };
              ApiService.createPayment(payLoad)
                .then((resp) => {
                  if (resp) {
                    if(exist===true){
                    navigate("/thankyou");
                    }else{
                    navigate("/thankyouwithoutheader");
                    }
                  }
                })
                .catch((errorData) => {
                  const { response } = errorData;
                  const error = response;
                  toast.error(error?.statusText, {
                    position: "top-right",
                  });
                  setDisablebtn(false);
                });
            }
          } else {
            toast.error(result?.error?.message, {
              position: "top-right",
            });
            const section = document.querySelector("#stripe-integration");
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
            setDisablebtn(false);
          }
        });
    }
  };
  let [total, setTotal] = useState(0);

  const setBillingInfoValue = async (event: any) => {
    var { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setBillingInfo({ ...billingInfo, [name]: value });
  };
  React.useEffect(() => {
    var tempTotal = 0;
    planState?.data?.map((item: SinglePlan, index: number) => {
      if (item.quantity && item.monthlyPrice) {
        tempTotal = tempTotal + item.quantity * item.monthlyPrice;
      }
      return item;
    });
    setTotal(tempTotal);
  }, [planState]);
  
  return (
    <div>
      <h2 className="text-center ">Let’s get your card info</h2>
      <div className="subscription-sub-text-box mb-5">
        <span className="subscription-sub-text">
          Help us ensure that we have the right information on your practice and
          our best point of contact
        </span>
      </div>

      <Row>
        <Col md={7}>
          <form onSubmit={handleSubmit}>
            <div id="stripe-integration">
              <PaymentElement />
            </div>
            <br />
            <b className="lable-elm-info">Billing Info</b>
            <br />
            <br />
            <Row>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Address*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="address"
                    maxLength={100}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                    value={billingInfo.address}
                  />
                  {simpleValidator.current.message(
                    "address",
                    billingInfo.address,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">
                    Suite, Unit, Etc.
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="suite"
                    maxLength={50}
                    value={billingInfo.suite}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "suite",
                    billingInfo.suite,
                    "max:50"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">City*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="city"
                    maxLength={100}
                    value={billingInfo.city}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "city",
                    billingInfo.city,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">State*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="state"
                    maxLength={100}
                    value={billingInfo.state}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "state",
                    billingInfo.state,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Postal Code*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="postalCode"
                    maxLength={15}
                    value={billingInfo.postalCode}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "postalCode",
                    billingInfo.postalCode,
                    "required|max:50"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Country*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="country"
                    value={billingInfo.country}
                    maxLength={50}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "country",
                    billingInfo.country,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Row className="team-started">
                <Container className="small-container ">
                  <h1 className="team-price">
                    ${total}/mo
                    {applyCouponState?.lastDiscount > 0 &&
                      `(-$` + applyCouponState?.lastDiscount + `)`}
                  </h1>
                  <Row>
                    <Col md={3}></Col>
                    <Col md={6} sm={12} className="mt-2  text-center">
                      <span className="team-price-sub-text">
                        Let’s make sure everything looks good
                      </span>
                    </Col>
                    <Col md={3}></Col>
                  </Row>
                  <Row className="gx-5 mb-5" style={{ marginTop: "-2rem" }}>
                    <Col md={12} className="text-center team-start-div">
                      <Button
                        onClick={(e) => handleSubmit(e)}
                        className="team-start-btn"
                        disabled={disablebtn}
                      >
                        {disablebtn && (
                          <Spinner className="loader" animation="border" />
                        )}
                        Confirm subscription 💯
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Row>
            </Row>
          </form>
        </Col>
        <Col md={1}></Col>
        <Col md={4}>
          <Subscriptionsummery />
        </Col>
      </Row>
    </div>
  );
}

export default Stripe;
