import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getPractice = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_MEDICALPRACTICE,
    });
    apiService
      .getMedicalPractice()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_MEDICALPRACTICE_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_MEDICALPRACTICE_FAILED,
          error,
        });
      });
  };
};

export const editPractice = (editData:any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.EDIT_MEDICALPRACTICE,
    });
    apiService
      .editMedicalPractice(editData)
      .then(({ data, ...rest }) => {
        dispatch({
          type: actionTypes.EDIT_MEDICALPRACTICE_SUCCESS,
          data: data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.EDIT_MEDICALPRACTICE_FAILED,
          error,
        });
      });
  };
};
