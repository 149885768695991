/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
function Managepayments() {
  return (
    <div className="dashboard-app">
      <header className="dashboard-toolbar">
        <a href="#!" className="menu-toggle">
          <i className="fas fa-bars"></i>
        </a>
      </header>
      <div className="dashboard-content">
        <div className="container">
          <div className="row">
            <h3 className="mb-5">Manage Payments</h3>
            <br />
            <br />
            <div className="float-end">
              <h6 className="float-end">Edit</h6>
            </div>
            <br />
            <div className="dashboard-profile-area">
              <a href="#" className="edit-btn"></a>
              <ul>
                <form>
                  <li>
                    <span>First Name</span>
                    <input
                      className="form-control no-border"
                      type="text"
                      id="myText"
                      readOnly
                      name="firstName"
                      placeholder="Ramjan Ali"
                      value="Nikhil12350"
                    />
                  </li>
                  <li>
                    <span>Last Name</span>
                    <input
                      className="form-control no-border"
                      type="text"
                      readOnly
                      name="lastName"
                      placeholder="Anik"
                      value="Narvani"
                    />
                  </li>
                  <li>
                    <span>Email</span>
                    <input
                      className="form-control no-border"
                      type="text"
                      readOnly
                      name="email"
                      placeholder="demo@email.com"
                      value="nikhil@gmail.com"
                    />
                  </li>
                  <li>
                    <span>Contact Number</span>
                    <input
                      className="form-control no-border"
                      type="text"
                      readOnly
                      name="contactNumber"
                      placeholder="+918469942846"
                      value="8469942846"
                    />
                  </li>
                </form>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <h3 className="mb-5">Change Password</h3>
            <br />
            <br />
            <div className="float-end"></div>
            <br />
            <div className="dashboard-profile-area">
              <a className="edit-btn"></a>
              <ul>
                <form>
                  <li>
                    <span>Current Password</span>
                    <input
                      className="form-control no-border"
                      type="password"
                      name="firstName"
                      placeholder="Ramjan Ali"
                      value="Nikhil12350"
                    />
                  </li>
                  <li>
                    <span>New Password</span>
                    <input
                      className="form-control no-border"
                      type="password"
                      name="lastName"
                      placeholder="Anik"
                      value="Narvani"
                    />
                  </li>
                  <li>
                    <span>Confirm Password</span>
                    <input
                      className="form-control no-border"
                      type="password"
                      name="email"
                      placeholder="demo@email.com"
                      value="nikhil@gmail.com"
                    />
                  </li>
                </form>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Managepayments;
