/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Badge from 'react-bootstrap/Badge';
import Col from "react-bootstrap/Col";
import "./dashboard.scss";
import Profile from "../../../src/assets/images/profile.png";
import Book from "../../../src/assets/images/book.svg";
import Myprofile from "../../../src/assets/images/myprofile.svg";
import List from "../../../src/assets/images/list.svg";
import Dollorlist from "../../../src/assets/images/dollor-list.svg";
import Setting from "../../../src/assets/images/settings.svg";
import Team from "../../../src/assets/images/team.svg";
import Stastic from "../../../src/assets/images/stastic.svg";
import Arrow from "../../../src/assets/images/arrow.svg";
import Static from "../../../src/assets/images/static.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountState } from "../../types/Myaccount.type";
import { getAccount } from "../../store/myaccount/effect";
// import swal from "sweetalert";
import Grow from "../loader/Grow";
import Avicon from "../../assets/images/AV-Icon.svg"
import Toolicon from "../../assets/images/Tools-Icon.svg"
import { AppConfig } from "@configs";

function Dashboard() {
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(true)
  const [profile, setProfile] = useState<any>();

  const accountState = useSelector(
    (state: GetAccountState) => state.accountState.myaccount
  );

  React.useEffect(() => {
    setLoader(accountState?.loading)
  }, [accountState?.loading]);

  React.useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  React.useEffect(() => {
    if (localStorage.getItem('profile')) {
      const profile = JSON.parse(localStorage.getItem('profile') as string);
      setProfile(profile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('profile')]);

  const logout = () => {
    // swal({
    //   title: "Are you sure you want to logout?",
    //   icon: "warning",
    //   buttons: [true, true],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
        localStorage.clear();
        window.open(AppConfig.logoutRedirectUrl, "_self");
    //   }
    // });
  };

  return (
    <div className="home-dashboard">
      <section className="section-dashboard">
        <Container className="small-container">
          <Row className="gx-5 row justify-content-center">
            <div className="col-sm-6 ">
              <div className="intro">
              {
                     loader  ? (
                    <div className="intro-inside loader-padding text-center">
                      <Grow />
                    </div>
                ) :
                  <>
                  <div className="intro-inside text-center-mobile ">
                    {
                      accountState?.data?.profile?.profilePicture ? (
                      <img src={accountState?.data?.profile?.profilePicture} alt="profile" className="profile-image br-50" />
                      ) :  (
                      <img src={Profile} alt="profile" className="profile-image br-50" />
                      )
                    }
                    <h4 className="text-center-mobile profile-name">
                      Welcome {accountState?.data?.profile?.name} {profile?.freeTrial && <Badge bg="success">Free Trial</Badge>}
                    </h4>
                  </div>
                  <div className="intro-inside2">
                    <Row style={{ borderTop: "1px solid #000" }}>
                      <Col md={6} className="text-center my-account-padding b-r">
                        <Link to="/my-account" className="common-inside-text ">
                          My Account
                        </Link>
                      </Col>
                      <Col md={6} className="text-center my-account-padding">
                        <button
                          type="button"
                          className="no-border"
                          onClick={() => {
                            logout();
                          }}
                        >
                          <span className="common-inside-text ">Log Out</span>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </>
                }
              </div>
            </div>
            <div className="col-sm-6 mt-mobile-8">
              <div className="common-box common-box-height">
                <div className="partition">
                  <Row>
                    <Col md={3} xs={3}>
                      <img src={Myprofile} alt="common" />
                    </Col>
                    <Col md={9} xs={9} className="vertically-center">
                      <span className="common-text">My Practice </span>
                      <br />
                    </Col>
                  </Row>
                </div>
                <div className='partition-container'>
                  <div className="common-partition" style={{ marginTop: "20px" }}>
                    <Link to="/my-practice#practice-details" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={3} xs={3}>
                          <img src={List} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9}>
                            Practice Details
                        </Col>
                      </Row>
                    </Link>
                    {/* <hr className="borderHr" /> */}
                  </div>
                  <div className="common-partition">
                  <Link to="/my-practice#billing-details" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={3} xs={3}>
                          <img
                            src={Dollorlist}
                            alt="common"
                            className="common-img"
                          />
                        </Col>
                        <Col md={9} xs={9}>
                            Billing Details
                        </Col>
                      </Row>
                    </Link>
                    {/* <hr className="borderHr" /> */}
                  </div>
                  <div className="common-partition no-border-display">
                    <Link  to="/my-practice#manage-subscription" className="common-inside-text">
                    <Row className='common-row'>
                      <Col md={3} xs={3}>
                        <img src={Setting} alt="common" className="common-img" />
                      </Col>
                      <Col md={9} xs={9}>
                          Manage Subscription
                      </Col>
                    </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="mt-8 gx-5  justify-content-center">
            <div className="col-sm-6">
              <div className="common-box common-box-height">
                <div className="partition">
                  <Row>
                    <Col md={3} xs={3}>
                      <img src={Team} alt="common" />
                    </Col>
                    <Col md={9} xs={9} className="vertically-center">
                      <span className="common-text">My Team</span>
                      <br />
                    </Col>
                  </Row>
                </div>
                <div className='partition-container'>
                  <div className="common-partition" style={{ marginTop: "20px" }}>
                    <Link to="/my-team#learner-states" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={3} xs={3}>
                          <img src={Stastic} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9}>
                              Learner Stats
                        </Col>
                      </Row>
                    </Link>
                    {/* <hr className="borderHr" /> */}
                  </div>
                  <div className="common-partition">
                    <Link to="/my-team#team-progress" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={3} xs={3}>
                          <img src={Static} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9}>
                            Team Progress
                        </Col>
                      </Row>
                    </Link>
                    {/* <hr className="borderHr" /> */}
                  </div>
                  <div className="common-partition no-border-display">
                    <Link to="/my-team#manage-team" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={3} xs={3}>
                          <img src={Arrow} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9}>
                            Manage Team
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 mt-mobile-8">
              <div className="common-box common-box-height">
                <div className="partition">
                  <Row>
                    <Col md={3} xs={3}>
                      <img src={Book} alt="common" />
                    </Col>
                    <Col md={9} xs={9} className="vertically-center">
                      <span className="common-text">My Resources</span>
                      <br />
                    </Col>
                  </Row>
                </div>
                <div className='partition-container'>
                  <div className="common-partition" style={{ marginTop: "20px" }}>
                    <Link to="/my-resources#alchemy-vision-101" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={3} xs={3}>
                          <img src={Avicon} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9}>
                            Alchemy Vision 101
                        </Col>
                      </Row>
                    </Link>
                    {/* <hr className="borderHr" /> */}
                  </div>
                  <div className="common-partition ">
                  <Link to="/my-resources#leader-tools" className="common-inside-text">
                    <Row className='common-row'>
                      <Col md={3} xs={3}>
                        <img src={Toolicon} alt="common" className="common-img" />
                      </Col>
                      <Col md={9} xs={9}>
                      Leader Tools
                      </Col>
                    </Row>
                    </Link>
                    {/* <hr className="borderHr" /> */}
                  </div>
                </div>
                  {/* <div style={{height:'250px'}}>
                    <div style={{position:'relative',top:'35%',textAlign:'center'}}>
                        <img src={Comingsoon} alt='coming-soon' />
                     </div>
                  </div> */}
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Dashboard;
