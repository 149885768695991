import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getAccount = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_ACCOUNT,
    });
    apiService
      .getAccount()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_ACCOUNT_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_ACCOUNT_FAILED,
          error,
        });
      });
  };
};


export const editAccount = (payload:any): any => {
  return (dispatch: any) => {
    let formData = new FormData();
    formData.append('certifications', payload?.certifications);
    formData.append('contactNumber', payload?.contactNumber);
    formData.append('email', payload?.email);
    formData.append('jobTitle', payload?.jobTitle);
    formData.append('name', payload?.name);
    formData.append('surname', payload?.surname);
    if(payload.profilePicture){
    formData.append('profilePicture', payload?.profilePicture);
    }
    dispatch({
      type: actionTypes.UPDATE_ACCOUNT,
    });
    apiService
      .updateAccount(formData)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
          data: response,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT_FAILED,
          error,
        });
      });
  };
};

export const resetPassword = (payload:any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_PASSWORD,
    });
    apiService
      .changePassword(payload)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.RESET_PASSWORD_SUCCESS,
          data: response,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.RESET_PASSWORD_FAILED,
          error,
        });
      });
  };
};
