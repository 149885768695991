/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "../../assets/images/tab.svg";
import {  useSelector } from "react-redux";
import {AppConfig} from "../../configs/app.config";
import Exclamation from "../../../src/assets/images/Entry-Info.svg";
import { GetLearnerState } from "../../types/Learners.type";

function Teamprogress() {
  const learnerStateData = useSelector(
    (state: GetLearnerState) => state.learnerState.learnerlist
  );
  const [isLagacy,setIsLagacy] = useState(false)

  React.useEffect(()=>{
    if(learnerStateData?.data){
      learnerStateData?.data?.learners?.map((learner:any)=>{
        if(learner?.plan?.name==="Legacy"){
          setIsLagacy(true)
          return true;
        }else{
          setIsLagacy(false)
          return false;
        }
      })

    }
  },[learnerStateData])

  const sendReportCallEntry = (e:any) =>{
    e.preventDefault()
    window.open(AppConfig.entryurl) 
  }

 
  const sendReportCall = (e:any) =>{
    e.preventDefault()
    window.open(AppConfig.emergeurl) 
  }

  return (
    <>
      <Col md={12} className="mt-5" id="team-progress">
        <div className="common-box">
          <div className="partition practice-color">
            <Row>
              <Col md={3}>
                <span className="common-text">Team Progress</span>
                <br />
              </Col>
            </Row>
          </div>
          <div className="partition">
              <Row className="gx-5 center-pending">
                <Col md={4} xs={12} className="text-right text-center-phone">
                  <img src={Tab} alt="growthimage" />
                </Col>
                <Col md={6} xs={12} >
                  <span className="growth-desc">
                  Click below to generate a progress report of your team’s exam scores. The reports will open in new web browser tabs for you to view. 
                  &nbsp; 
                  {
                    isLagacy ===true &&
                  <>
                  <button
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="no-border no-bg"
                  >
                    <img
                    className="exclamation-png-2"
                    src={Exclamation}
                    alt="Exclamation"
                    height='20px'
                    width='20px'
                    />
                  </button>
                  </>
                  }
                  <div
                      className="dropdown-menu no-margin small-width"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="info-desc-2">
                      Exam scores prior to the platform switch were emailed to your practice, as they won't show in these reports. Your staff can retake them for new scores, and as a knowledge check; though it’s not required.
                      </div>
                  </div>
                  </span>
                
                  <br />
                </Col>
              </Row>
          </div>
          <div className="partition-last">
            <Row className="gx-5 update-btn-border">
              <Col md={6} className="text-center main-div-last border-right">
                <a
                  className='generate-report-button '
                  href='#'
                  onClick={(e) => {
                    sendReportCall(e);
                  }}
                >
                  Create a report for Essential
                </a>
                
              </Col>
              <Col md={6} className="text-center main-div-last">
                <a
                  className='generate-report-button'
                  href='#'
                  onClick={(e) => {
                    sendReportCallEntry(e);
                  }}
                >
                  Create a report for Professional
                </a>
                
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
}

export default Teamprogress;
