import Spinner from "react-bootstrap/Spinner";
function Grow() {
  return (
    <>
      <div className="text-center" style={{ margin: "0 auto" }}>
        <Spinner animation="grow" />
      </div>
    </>
  );
}

export default Grow;
