function Title(props:any) {

    return(
        <>
            <span className="common-title">{props.title}</span>
        </> 
    );


}

export default Title;