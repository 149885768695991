export const Includedata = [
    {
        include: "40+ onboarding modules",
        essential: true,
        professional: true,
        premium: true
    }, 
    {
        include: "60+ professional development modules",
        essential: false,
        professional: true,
        premium: true
    }, 
    {
        include: "30+ premium practice development modules",
        essential: false,
        professional: false,
        premium: true
    }, 
    {
        include: "COA preparation exam",
        essential: false,
        professional: true,
        premium: true
    }, 
    {
        include: "Digital study resources",
        essential: false,
        professional: true,
        premium: true
    }, 
    {
        include: "Library of previously live streamed lessons",
        essential: false,
        professional: true,
        premium: true
    }, 
    {
        include: "Exclusive Patient Centered Solutions model training",
        essential: false,
        professional: false,
        premium: true
    }, 
    {
        include: "Live streamed Premium Deep Dives w/ Q+A",
        essential: false,
        professional: false,
        premium: true
    }, 
    {
        include: "Growing library of industry collaborated modules",
        essential: false,
        professional: false,
        premium: true
    }, 
    {
        include: "Badges + achievement leaderboards",
        essential: true,
        professional: true,
        premium: true
    }, 
    {
        include: "Knowledge checkpoints for training retention",
        essential: true,
        professional: true,
        premium: true
    }, 

    {
        include: "Learning path final exams",
        essential: true,
        professional: true,
        premium: true
    }, 
    {
        include: "Accessible from any device anywhere",
        essential: true,
        professional: true,
        premium: true
    }, 
    {
        include: "Swappable licenses in case of learner turnover",
        essential: true,
        professional: true,
        premium: true
    }, 
    {
        include: "Leader dashboard to track team analytics + progress",
        essential: true,
        professional: true,
        premium: true
    },
    {
        include: "Structured learning for progress + engagement",
        essential: true,
        professional: true,
        premium: true
    },
    {
        include: "Checkpoints, path masteries, and assignments",
        essential: true,
        professional: true,
        premium: true
    },
    {
        include: "Revisit courses and retake any knowledge checks",
        essential: true,
        professional: true,
        premium: true
    }
];