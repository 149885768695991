import * as actionTypes from "./actions";
import { createContactState } from "../../types/Contact.type";

const initialState: createContactState = {
  createContact: {
    loading: false,
    data: [],
    error: null,
  }
};

const contactReducer = (
  state: createContactState = initialState,
  action: any
): createContactState => {
  switch (action.type) {
    case actionTypes.INSERT_CONTACT:
      return {
        ...state,
        createContact: {
          loading: true,
        },
      };

    case actionTypes.INSERT_CONTACT_SUCCESS:
      return {
        ...state,
        createContact: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.INSERT_CONTACT_FAILED:
      return {
        ...state,
        createContact: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.RESET_CONTACT:
        return {
          ...state,
          createContact: {
            loading: false,
            data: [],
            error: null,
          },
        };
  }
  return state;
};

export default contactReducer;
