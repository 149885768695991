import React, { useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionList } from "../../store/subscription/effect";
import { GetListSubscriptionState } from "../../types/Subscription.type";
import Grow from "../loader/Grow";
import Pagination from "../mypractice/Pagination";

function Subscriptioninvoices(params: any) {
  const dispatch = useDispatch();
  const subscriptionListState = useSelector(
    (state: GetListSubscriptionState) =>
      state.subscriptionState.subscriptionlist
  );
  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    dispatch(subscriptionList());
  }, [dispatch]);

  const formatDate = (date: any) => {
    var d = new Date(date),
      day = "" + d.getDate(),
      year = d.getFullYear();
    let month = d.toLocaleString("default", { month: "long" });
    if (day.length < 2) day = "0" + day;

    return day + ` ` + month + ` ` + year;
  };

  let PageSize = 3;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return subscriptionListState?.data?.invoices?.slice(
      firstPageIndex,
      lastPageIndex
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, subscriptionListState?.data?.invoices]);

  return (
    <>
      <Col md={12} className="mt-5 pb-5">
        <div className="common-box">
          <div className="partition partition-bg">
            <Row>
              <Col md={4}>
                <span className="common-text">Subscription Invoices</span>
                <br />
              </Col>
            </Row>
          </div>

          <div className="partition">
            <Row className="gx-5 justify-content-center">
              <Col md={5} xs={4} className="justify-content-center">
                <Title title="Charge Date" />
                <br />
              </Col>
              <Col md={2} xs={4}>
                <Title title="Total" />
                <br />
              </Col>
              <Col md={2} xs={4}>
                <Title title="Invoice No." />
                <br />
              </Col>
            </Row>
            {!subscriptionListState.loading ? (
              currentTableData && currentTableData?.length > 0 ? (
                currentTableData?.map((list: any, index: number) => {
                  return (
                    <Row
                      key={index}
                      className="gx-5 justify-content-center mt-3"
                    >
                      <Col md={5} xs={4} className="justify-content-center">
                        <Subtitle subtitle={formatDate(list.createdAt)} />
                        <br />
                      </Col>
                      <Col md={2} xs={4}>
                        <Subtitle subtitle={"$" + list.amount} />
                        <br />
                      </Col>
                      <Col md={2} xs={4}>
                        <Subtitle subtitle={"AV" + list.id} />
                        <br />
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <>
                  <div className="no-data-found">
                    <h4>No Data Found</h4>
                  </div>
                </>
              )
            ) : (
              <div className="mt-5 mb-5">
                <Grow />
              </div>
            )}
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={subscriptionListState?.data?.invoices?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
          />
          <br />
          <br />
        </div>
      </Col>
    </>
  );
}

export default Subscriptioninvoices;
