import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

function Plan({
  plan,
  incNum,
  decNum,
}: {
  plan: any;
  incNum: any;
  decNum: any;
})

{
  const style = {
    background:plan?.colorConfig?.homePage?.selectionCardBg ? plan?.colorConfig?.homePage?.selectionCardBg : '#C7C9C1', 
  }
  const buttonStyle = {
    background:plan?.colorConfig?.homePage?.buttonColor ? plan?.colorConfig?.homePage?.buttonColor : '#787A6D',
     color: "#fff", border: "0px" 
  }

  const cardBody = {
    background:plan?.colorConfig?.homePage?.cardBody ? plan?.colorConfig?.homePage?.cardBody : '#F4F5EE',
  }
  const headerTextColor = {
    color:plan?.colorConfig?.homePage?.buttonColor ? plan?.colorConfig?.homePage?.buttonColor : '#F4F5EE',
  }
  const textColor = {
    color:plan?.colorConfig?.homePage?.buttonColor,
    fontSize:19,
    width:'80%'
  }

  return (
    <Col md={12}>
      <Card
        className="enter-prise-card"
      >
        <Card.Body style={{ paddingTop: 0,paddingBottom:0,paddingRight:10,paddingLeft:10}}>
          <Row  style={cardBody}>
            <Col md={3} className='enter-prise-colored' style={style}>
            <div>
              <div className="plan-icon-div">
                {plan?.primaryIcon ? (  
                  <img alt="plan" src={plan?.primaryIcon} />
                ) : (
                  <button className="plan-icon"></button>
                )}
              </div>
              <Card.Title className="text-center plan-name-my-team mt-2 " style={headerTextColor}>
                {plan?.name}
              </Card.Title>
              </div>
            </Col>
            <Col md={6} className="contact-text" style={{padding:'35px'}}>
              <Card.Title className="text-center charges-2">
                {plan?.monthlyPrice !== 0
                  ? `$${plan?.monthlyPrice} per staff member/month`
                  : `Have any amount of team managers, for free`}
              </Card.Title>
              <Card.Text className="text-center detail-text-3" style={textColor}>
                {plan?.description}
              </Card.Text>
            </Col>
            <Col md={3} className="contact-button">
              <button
                className="minus-btn"
                onClick={() => {
                  decNum(
                    plan?.id as number,
                    plan?.totalSeats,
                    plan?.monthlyPrice
                  );
                }}
                style={buttonStyle}
                disabled={plan.availableSeats > 0 ? false : true}
              >
                <i className="fa-solid fa-minus"></i>
              </button>
              <input
                type="text"
                className="number"
                name={`${plan?.id}`}
                id={`${plan?.id}`}
                data-price={plan?.id}
                value={plan?.totalSeats}
                readOnly
              />

              <button
                onClick={() => {
                  incNum(
                    plan?.id as number,
                    plan?.totalSeats,
                    plan?.monthlyPrice
                  );
                }}
                className="plus-btn"
                style={buttonStyle}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </Col>
            
            { (plan?.nextConfig - plan?.totalSeats) < 3 && (plan?.nextConfig - plan?.totalSeats) > 0 && (
              <div className="remaining-licence">
                <span>
                  Only {plan?.nextConfig  - plan?.totalSeats} licenses away from
                  ${plan?.nextConfigPrice} /learner
                </span>
              </div>
           )} 
          </Row>
        </Card.Body>
      </Card>
      {
        plan?.nextConfig > 0 && plan?.totalSeats > 0  && (
          <div className='mt-3' style={{textAlign:'right'}}>
            *{
              plan?.rules?.map((rule:any,index:number)=>{
                return (
                  <>
                    <span key={index}>${rule.price} for {rule.rule} learners {plan?.rules?.length !== index+1 && ' – '}</span>
                  </>
                )
              }) 
            }
           </div>
        )
      }
    </Col>
  );
}

export default Plan;
