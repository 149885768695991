// coupan actions
export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const GET_COUPON_FAILED = "GET_COUPON_FAILED";

// apply coupan actions
export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAILED = "APPLY_COUPON_FAILED";
export const RESET_APPLY_COUPON = "RESET_APPLY_COUPON";
