import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Preview from "./Preview";
import { GetPlanState, SinglePlan } from "../../types/Plan.type";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Signup() {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryParameters = new URLSearchParams(window.location.search);
  const signup = queryParameters.get("signup");
  const planId = queryParameters.get("planId");
  const planState = useSelector(
    (state: GetPlanState) => state?.planState.plans
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    var tempTotal = 0;
    planState?.data?.map((item: SinglePlan, index: number) => {
      if (item.quantity && item.monthlyPrice) {
        tempTotal = tempTotal + item.quantity * item.monthlyPrice;
      }
      return item;
    });
    if (tempTotal === 0) {
      navigate("/plans");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planState]);

  return (
    <div>
      <section className="signup-form-sec">
        <Container className="small-container ">
          <Preview signup={signup} planId={planId} />
        </Container>
      </section>
    </div>
  );
}

export default Signup;
