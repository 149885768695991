import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import apiService from "../../services/api.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

function Adminlogin() {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputs = (event: any) => {
    var { name, value } = event.target;

    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === false) {
      return false;
    }
    setLoading(true);
    apiService
      .adminLogin(loginData)
      .then((resp) => {
        if (resp && resp.data) {
          setLoading(false);
          const { data } = resp.data;
          if (data.profile.roleId === 2) {
            navigate("/preview/login");
          } else {
            navigate("/dashboard");
          }
          localStorage.setItem("profile", JSON.stringify(data.profile));
        }
      })
      .catch((errorData) => {
        setLoading(false);
        const { response } = errorData;
        const error = response;

        if (error?.data?.message) {
          toast.error(error?.data?.message, {
            position: "top-right",
          });
        }
      });
  };

  return (
    <Container>
      <Row>
        <Form
          noValidate
          validated={validated}
          style={{ margin: "10% auto", width: "30%" }}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={loginData.email}
              onChange={(e) => {
                handleInputs(e);
              }}
              required
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={loginData.password}
              onChange={(e) => {
                handleInputs(e);
              }}
              required
            />
          </Form.Group>
          {/* <Button variant="primary" type="submit">
            Submit 
          </Button> */}
          <Button type="submit" className="primary" disabled={loading}>
            {loading && <Spinner className="loader" animation="border" />}Submit
          </Button>
        </Form>
      </Row>
    </Container>
  );
}

export default Adminlogin;
