import React from "react";
import "./Thankyou.scss";
import { useNavigate } from "react-router-dom";
import Mailimg from "../../assets/images/mailIcon.svg";

function Thankyoucontact() {
  const navigate = useNavigate();

  const handleSubmit = (e:any) =>{
    navigate('/')
  }

  return (
    <>
    <div className="welcome">
      <section className="thankyou">  
        <div
          className="wrapperAlert"
          style={{ margin: "0px auto", textAlign: "center" }}
        >
          <div className="content mt-5">
              <img src={Mailimg} alt='mailimg' height='100px' width='75px' />
              <div className='mt-2'>
                  <div>
                     <span className='thankyou-title'>Thank you for reaching out!</span><br/><br/>
                     <span className='thankyou-subtext'>
                     Our Sales team will contact you as soon as they can. Thank you so much for your interest in Alchemy Vision!</span>
                  </div>
              </div> 
              <div className="row gx-5 pb-5">
              <div className="col-md-12 text-center team-start-div">  
                <button
                  type="submit" 
                  onClick={(e) => handleSubmit(e)}
                  className="team-start-btn"
                  style={{color:'#3B3C37'}}
                >
                   Go back to the Pricing page
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Thankyoucontact;
