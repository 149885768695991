// licenceallocation actions
export const LICENCEALLOCATION = "LICENCEALLOCATION";
export const LICENCEALLOCATION_SUCCESS = "LICENCEALLOCATION_SUCCESS";
export const LICENCEALLOCATION_FAILED = "LICENCEALLOCATION_FAILED";
export const RESET_LICENCEALLOCATION = "RESET_LICENCEALLOCATION";

// get licence
export const GET_LICENCE = "GET_LICENCE";
export const GET_LICENCE_SUCCESS = "GET_LICENCE_SUCCESS";
export const GET_LICENCE_FAILED = "GET_LICENCE_FAILED";

// get experienceleve
export const GET_EXPERIENCE = "GET_EXPERIENCE";
export const GET_EXPERIENCE_SUCCESS = "GET_EXPERIENCE_SUCCESS";
export const GET_EXPERIENCE_FAILED = "GET_EXPERIENCE_FAILED";

// delete licence
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";
export const DELETE_EXPERIENCE_SUCCESS = "DELETE_EXPERIENCE_SUCCESS";
export const DELETE_EXPERIENCE_FAILED = "DELETE_EXPERIENCE_FAILED";
export const RESET_LICENCE_DELETE = "RESET_LICENCE_DELETE";

// edit licence
export const EDIT_LICENCE = "EDIT_LICENCE";
export const EDIT_LICENCE_SUCCESS = "EDIT_LICENCE_SUCCESS";
export const EDIT_LICENCE_FAILED = "EDIT_LICENCE_FAILED";
export const RESET_EDIT_LICENCE = "RESET_EDIT_LICENCE";

// Change licence
export const CHANGE_LICENCE = "CHANGE_LICENCE";
export const CHANGE_LICENCE_SUCCESS = "CHANGE_LICENCE_SUCCESS";
export const CHANGE_LICENCE_FAILED = "CHANGE_LICENCE_FAILED";
export const RESET_CHANGE_LICENCE = "RESET_CHANGE_LICENCE";