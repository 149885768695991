import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getCoupons = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_COUPON,
    });
    apiService
      .getCoupons()
      .then(({ data, ...rest }) => {
        dispatch({
          type: actionTypes.GET_COUPON_SUCCESS,
          data
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_COUPON_FAILED,
          error,
        });
      });
  };
};

export const applyCouponCode = (
  couponCode: String,
  selectedPlanObj: any
): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.APPLY_COUPON,
    });
    apiService
      .applyCoupons(couponCode, selectedPlanObj)
      .then(({ data, ...rest }) => {
        const response = data;
        const sentResponse = response.data;
        sentResponse.message = data.message;
        sentResponse.status = data.status;
        sentResponse.data = response.data;

        dispatch({
          type: actionTypes.APPLY_COUPON_SUCCESS,
          data: sentResponse,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        let error;
        if (Array.isArray(response?.data?.errors)) {
          error = response?.data?.errors;
        } else {
          error = response;
        }
        dispatch({
          type: actionTypes.APPLY_COUPON_FAILED,
          error,
        });
      });
  };
};
