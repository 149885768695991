// get leader
export const GET_LEADERS = "GET_LEADERS";
export const GET_LEADERS_SUCCESS = "GET_LEADERS_SUCCESS";
export const GET_LEADERS_FAILED = "GET_LEADERS_FAILED";

// add leader
export const ADD_LEADERS = "ADD_LEADERS";
export const ADD_LEADERS_SUCCESS = "ADD_LEADERS_SUCCESS";
export const ADD_LEADERS_FAILED = "ADD_LEADERS_FAILED";
export const RESET_ADD_LEADER = "RESET_ADD_LEADER";

// remove leader

export const REMOVE_LEADER = "REMOVE_LEADER";
export const REMOVE_LEADER_SUCCESS = "REMOVE_LEADER_SUCCESS";
export const REMOVE_LEADER_FAILED = "REMOVE_LEADER_FAILED";
export const RESET_REMOVE_LEADER = "RESET_REMOVE_LEADER";
