import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import Logo from "../../../../src/assets/images/AV-Logo.png";
import Homeicon from "../../../../src/assets/images/home.png";
import { Link } from "react-router-dom";

function Mainheader(props: any) {
  return (
    <>
      <Navbar variant="light" className="admin-nav">
        <Container fluid>
          <div className="header-image">
            <Link to="/dashboard">
              {" "}
              <img src={Logo} alt="AV Logo" />{" "}
            </Link>
          </div>
          <h1 className="header-center-text justify-content-center">
            {props.header}
          </h1>
          <div className="header-image home-icon">
            <Link to="/dashboard">
              {" "}
              <img src={Homeicon} alt="icon" />{" "}
            </Link>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Mainheader;
