import * as actionTypes from "./actions";
import { leadersState } from "../../types/leaders.type";
const initialState: leadersState = {
  leaderlist: {
    loading: false,
    data: null,
    error: null,
  },
  addleader: {
    loading: false,
    data: null,
    error: null,
  },
  removeleader: {
    loading: false,
    data: null,
    error: null,
  },
};


const leaderReducer = (
  state: leadersState = initialState,
  action: any
): leadersState => {
  switch (action.type) {
    case actionTypes.GET_LEADERS:
      return {
        ...state,
        leaderlist: {
          loading: true,
        },
      };

    case actionTypes.GET_LEADERS_SUCCESS:
      return {
        ...state,
        leaderlist: {
          data: action?.data,
          loading: false,
        },
      };

    case actionTypes.GET_LEADERS_FAILED:
      return {
        ...state,
        leaderlist: {
          error: action.error,
          loading: false,
        },
      };
    
      case actionTypes.ADD_LEADERS:
        return {
          ...state,
          addleader: {
            loading: true,
          },
        };
  
      case actionTypes.ADD_LEADERS_SUCCESS:
        return {
          ...state,
          addleader: {
            data: action?.data,
            loading: false,
          },
        };
  
      case actionTypes.ADD_LEADERS_FAILED:
        return {
          ...state,
          addleader: {
            error: action.error,
            loading: false,
          },
      };

      case actionTypes.RESET_ADD_LEADER:
        return {
          ...state,
          addleader: {
            loading: false,
            data: null,
            error: null,
          },
       };
      
       case actionTypes.REMOVE_LEADER:
        return {
          ...state,
          removeleader: {
            loading: true,
          },
        };
  
      case actionTypes.REMOVE_LEADER_SUCCESS:
        return {
          ...state,
          removeleader: {
            data: action?.data,
            loading: false,
          },
        };
  
      case actionTypes.REMOVE_LEADER_FAILED:
        return {
          ...state,
          removeleader: {
            error: action.error,
            loading: false,
          },
      };

      case actionTypes.RESET_REMOVE_LEADER:
        return {
          ...state,
          removeleader: {
            loading: false,
            data: null,
            error: null,
          },
       };
  }
  return state;
};

export default leaderReducer;
