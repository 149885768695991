/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./Myteam.scss";
import Manageteam from "./Manageteam";
import Teamprogress from "./Teamprogress";
import Learnerstate from "./Learnerstate";
import { Link } from "react-router-dom";
import { GetExamState } from "../../types/Learners.type";
import { useSelector } from "react-redux";

function Myteam() {
  const examStates = useSelector(
    (state: GetExamState) => state.learnerState.examdata
  )
  React.useEffect(() => {
    if(examStates?.data){
    const str = window.location.href;
    const after_ = str.slice(str.indexOf("#") + 1);
    var elem = document.getElementById(after_);
    elem && elem.scrollIntoView();
  }
  }, [examStates?.data]);

  return (
    <>
      <div className="my-team">
        <section className="section-practice">
          <Container>
            <div className="back-to-dashboard black">
              &#60;<Link to="/dashboard"> Back to My Dashboard</Link>
            </div>
            <Row className="mt-5">
              <Learnerstate />
              <Teamprogress />
              <Manageteam />
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Myteam;
