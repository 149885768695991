import React,{useState} from "react";
import './Emerge.scss'
import Emergelogo from "../../assets/images/essential-logo-small.svg";
import LogoEmergelogo from "../../assets/images/Logo-for-Report-Emerge.png";
import apiService from "../../services/api.service";
import Grow from "../loader/Grow";

function Emerge() {
    const [data,setData]:any = useState()
    const [error,setError]:any = useState()

    React.useEffect(()=>{
        apiService
        .getReport()
        .then((resp) => {
          if (resp && resp.data) {
            setData(resp.data?.data)
          }
        })
        .catch((errorData) => {
            setError(errorData)
        });
    },[])
     

    return( 
        <div className='emerge' >
            <div className="header-mobile" >
                <div className="part-1">
                    <div style={{width:'70%'}}>
                        <div className="learner-progress-text">LEARNER PROGRESS</div>
                        <div className="learner-progress-sub-text">As of {data?.emerge?.latestUpdatedDateOfLearnerState}</div>
                    </div>
                    <div style={{width:'30%',textAlign:'right',marginTop: '5px'}}>
                        <img src={LogoEmergelogo} alt='entry-logo' width="33px" height="30.68px"  />
                    </div>
                </div>
                <div className="part-2">
                        <img height="35px" width="35px" alt='entry-logo' src={Emergelogo} style={{verticalAlign: "middle",marginTop:'6px'}} /><span className="plan-name" style={{marginTop:'6px'}}>Essential</span>  
                </div>
                <div className="part-3">
                    <span className="title">Report Generated for</span><br/>
                    <span className="subtitle">{data?.emerge?.medicalPracticeName}</span>
                </div>
                <div className="part-4">
                    <span className="title">Learners</span><br/>
                    <span className="subtitle">{data?.emerge?.numberOfLearners}</span>
                </div>
                <div className="part-5">
                    <span className="title">Avg. Checkpoint Score</span><br/>
                    <span className="subtitle">{data?.emerge?.avgScore?.avgExamScore ? data?.emerge?.avgScore?.avgExamScore+` %` : '-'}</span>
                </div>
                 <div className="part-6">
                    <span className="title">Avg. Path Mastery Score</span><br/>
                    <span className="subtitle">{data?.emerge?.avgScore?.avgFinalExamScore ? data?.emerge?.avgScore?.avgFinalExamScore+` %` : '-' }</span>
                </div>
            </div>
            {
                data ? 
            <>
             <div className="header" style={{display:'flex'}}>
                <div className="part-1">
                    <div className="learner-progress-text">LEARNER PROGRESS</div>
                    <div className="learner-progress-sub-text">As of {data?.emerge?.latestUpdatedDateOfLearnerState}</div>
                </div>
                <div className="part-2">
                        <img height="35px" width="35px" alt='emerge-logo' src={Emergelogo} style={{verticalAlign: "middle",marginTop:'6px'}} /><span className="plan-name" style={{marginTop:'6px'}}>Essential</span>  
                </div>
                <div className="part-3">
                    <span className="title">Report Generated for</span><br/>
                    <span className="subtitle">{data?.emerge?.medicalPracticeName}</span>
                </div>
                <div className="part-4">
                    <span className="title">Learners</span><br/>
                    <span className="subtitle">{data?.emerge?.numberOfLearners}</span>
                </div>
                <div className="part-5">
                    <span className="title">Avg. Checkpoint Score</span><br/>
                    <span className="subtitle">{data?.emerge?.avgScore?.avgExamScore ? data?.emerge?.avgScore?.avgExamScore+` %` : '-'}</span>
                </div>
                 <div className="part-6">
                    <span className="title">Avg. Path Mastery Score</span><br/>
                    <span className="subtitle">{data?.emerge?.avgScore?.avgFinalExamScore ? data?.emerge?.avgScore?.avgFinalExamScore+` %` : '-' }</span>
                </div>
                <div className="part-7">
                    <img src={LogoEmergelogo} alt='emerge-logo' width="33px" height="30.68px"  />
                </div>
            </div>
            <div className="data  table-responsive" >
                <table className='table' style={{width:'543px !important'}} cellSpacing="0" cellPadding="5px">
                        <thead>
                        <tr>
                            <th align="left"></th>
                            <th align="left">
                            <span className="th-head" style={{padding:'0px'}}>Checkpoint<br/>01</span> 
                            </th>

                            <th align="left">
                            <span className="th-head" style={{padding:'0px'}}>Checkpoint<br/>02</span>
                            </th>
                            
                            <th align="left">
                            <span className="th-head" style={{padding:'0px'}}>Checkpoint<br/>03</span>
                            </th>
                            
                            <th align="left">
                            <span className="th-head"  style={{padding:'0px',whiteSpace:'nowrap'}}>Learning Path <br/>Mastery</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                             data?.emerge?.details?.map((detail:any,index:number)=>{
                                return(
                                <>
                                <tr className={index%2===0 ? 'strippd' : ''}>
                                    <td className="first-td" width='33%'>{detail?.name}</td>
                                    {
                                        detail?.items?.map((singlemarks:any)=>{
                                            return(
                                                singlemarks?.luExamScore ?
                                                singlemarks?.luExamStatus === 'Passed' ?
                                                <td className='color-bg'>{singlemarks?.luExamScore+` %`}</td>
                                                :
                                                <td className='color-bg-danger'>{singlemarks?.luExamScore+` %`}</td>
                                                :
                                                <td className='no-bg'>-</td>
                                            )
                                        })
                                    }
                                </tr> 
                                </>
                                )
                            })
                            }
                        </tbody>
                </table>
            </div>
            <div className="footer-emerge" style={{paddingTop: '10px',width:'100%',display:'flex'}}>
                    <div className="left" style={{width:'50%',fontFamily: 'Space Grotesk',
                    fontSize: '13px',
                    fontWeight: '500',
                    lineHeight: '13px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#A5A5A5'}}
                    >
                        Internal Practice Information – Be Mindful of Privacy
                    </div>
                    <div className="right" style={{width:'50%',fontFamily:'Space Grotesk',
                    fontSize: '13px',
                    fontWeight: '500',
                    lineHeight: '13px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#A5A5A5'}}>
                        Alchemy Vision – Copyright 2023
                    </div>
                </div>
            </>
            :
            <>  
                { error ? 
                <div style={{verticalAlign:'middle',height:'calc(100vh - 20px)'}}>
                    <div style={{margin: '0px auto',display: 'flex',alignItems: 'center',justifyContent: 'center',height: '100vh'}}>
                        <h3>Something went wrong</h3>
                    </div>
                </div>
                :
                <div style={{verticalAlign:'middle',height:'calc(100vh - 20px)'}}>
                    <div style={{margin: '0px auto',display: 'flex',alignItems: 'center',justifyContent: 'center',height: '100vh'}}>
                        <Grow  />
                    </div>
                </div>
                }
            </>
            }
        </div>
    )

}

export default Emerge;
