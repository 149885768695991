import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const insertContact = (payload:any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.INSERT_CONTACT,
    });
    apiService
      .insertContact(payload)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.INSERT_CONTACT_SUCCESS,
          data: response,
        });
      })
      .catch((errorData):any => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.INSERT_CONTACT_FAILED,
          error,
        });
      });
  };
};
