import * as actionTypes from "./actions";
import { learnersState } from "../../types/Learners.type";

const initialState: learnersState = {
  learnerlist: {
    loading: false,
    data: [],
    error: null,
  },
  examdata:{
    loading: false,
    data: [],
    error: null,  
  },
  logindata:{
    loading: false,
    data: [],
    error: null,
  },
  progressreportdata:{
    loading: false,
    data: [],
    error: null,
  }
};

const learnerReducer = (
  state: learnersState = initialState,
  action: any
): learnersState => {
  switch (action.type) {
    case actionTypes.GET_LEARNER:
      return {
        ...state,
        learnerlist: {
          loading: true,
        },
      };

    case actionTypes.GET_LEARNER_SUCCESS:
      return {
        ...state,
        learnerlist: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.GET_LEARNER_FAILED:
      return {
        ...state,
        learnerlist: {
          error: action.error,
          loading: false,
        },
      };
      case actionTypes.GET_EXAMSTATES:
        return {
          ...state,
          examdata: {
            loading: true,
          },
        };
  
      case actionTypes.GET_EXAMSTATES_SUCCESS:
        return {
          ...state,
          examdata: {
            data: action.data,
            loading: false,
          },
        };
  
      case actionTypes.GET_EXAMSTATES_FAILED:
        return {
          ...state,
          examdata: {
            error: action.error,
            loading: false,
          },
        };
        case actionTypes.GET_LOGINSTATES:
        return {
          ...state,
          logindata: {
            loading: true,
          },
        };
  
      case actionTypes.GET_LOGINSTATES_SUCCESS:
        return {
          ...state,
          logindata: {
            data: action.data,
            loading: false,
          },
        };
  
      case actionTypes.GET_LOGINSTATES_FAILED:
        return {
          ...state,
          logindata: {
            error: action.error,
            loading: false,
          },
        };
        case actionTypes.SEND_LOGINSTATES:
          return {
            ...state,
            progressreportdata: {
              loading: true,
            },
          };
    
        case actionTypes.SEND_LOGINSTATES_SUCCESS:
          return {
            ...state,
            progressreportdata: {
              data: action.data,
              loading: false,
            },
          };
    
        case actionTypes.SEND_LOGINSTATES_FAILED:
          return {
            ...state,
            progressreportdata: {
              error: action.error,
              loading: false,
            },
          };
  }
  return state;
};

export default learnerReducer;
