import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getFaq = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_FAQ,
    });
    apiService
      .getFaq()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_FAQ_SUCCESS,
          data: response.data.faqs,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_FAQ_FAILED,
          error,
        });
      });
  };
};
