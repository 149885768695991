// get medical practice
export const GET_MEDICALPRACTICE = "GET_MEDICALPRACTICE";
export const GET_MEDICALPRACTICE_SUCCESS = "GET_MEDICALPRACTICE_SUCCESS";
export const GET_MEDICALPRACTICE_FAILED = "GET_MEDICALPRACTICE_FAILED";


// edit medical practice
export const EDIT_MEDICALPRACTICE = "EDIT_MEDICALPRACTICE";
export const EDIT_MEDICALPRACTICE_SUCCESS = "EDIT_MEDICALPRACTICE_SUCCESS";
export const EDIT_MEDICALPRACTICE_FAILED = "EDIT_MEDICALPRACTICE_FAILED";

export const RESET_PRACTICEEDIT = "RESET_PRACTICEEDIT";