import * as actionTypes from "./actions";
import { LicenceAllocationState } from "../../types/licenceallocation.type";
const initialState: LicenceAllocationState = {
  licenceAllocation: {
    loading: false,
    data: null,
    error: null,
  },
  getLicence: {
    loading: false,
    data: null,
    error: null,
  },
  experience: {
    loading: false,
    data: null,
    error: null,
  },
  deleteLicence:{
    loading: false,
    data: null,
    error: null,
  },
  editLicence:{
    loading: false,
    data: null,
    error: null,
  },
  changelicence:{
    loading: false,
    data: null,
    error: null,
  }
};

const LicenceAllocationReducer = (
  state: LicenceAllocationState = initialState,
  action: any
): LicenceAllocationState => {
  switch (action.type) {
    case actionTypes.LICENCEALLOCATION:
      return {
        ...state,
        licenceAllocation: {
          loading: true,
        },
      };

    case actionTypes.LICENCEALLOCATION_SUCCESS:
      return {
        ...state,
        licenceAllocation: {
          data: action?.data,
          loading: false,
        },
      };

    case actionTypes.LICENCEALLOCATION_FAILED:
      return {
        ...state,
        licenceAllocation: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.RESET_LICENCEALLOCATION:
      return {
        ...state,
        licenceAllocation: {
          loading: false,
          data: null,
          error: null,
        },
      };
    case actionTypes.GET_LICENCE:
      return {
        ...state,
        getLicence: {
          loading: true,
        },
      };

    case actionTypes.GET_LICENCE_SUCCESS:
      return {
        ...state,
        getLicence: {
          data: action?.data,
          loading: false,
        },
      };

    case actionTypes.GET_LICENCE_FAILED:
      return {
        ...state,
        getLicence: {
          error: action.error,
          loading: false,
        },
      };

      case actionTypes.GET_EXPERIENCE:
        return {
          ...state,
          experience: {
            loading: true,
          },
        };
  
      case actionTypes.GET_EXPERIENCE_SUCCESS:
        return {
          ...state,
          experience: {
            data: action?.data,
            loading: false,
          },
        };
  
      case actionTypes.GET_EXPERIENCE_FAILED:
        return {
          ...state,
          experience: {
            error: action.error,
            loading: false,
          },
        };
      
        case actionTypes.DELETE_EXPERIENCE:
          return {
            ...state,
            deleteLicence: {
              loading: true,
            },
          };
    
        case actionTypes.DELETE_EXPERIENCE_SUCCESS:
          return {
            ...state,
            deleteLicence: {
              data: action?.data,
              loading: false,
            },
          };
    
        case actionTypes.DELETE_EXPERIENCE_FAILED:
          return {
            ...state,
            deleteLicence: {
              error: action.error,
              loading: false,
            },
          };
        case actionTypes.RESET_LICENCE_DELETE:
          return {
            ...state,
            deleteLicence:{
              loading: false,
              data: null,
              error: null,
            }
          };
          case actionTypes.EDIT_LICENCE:
            return {
              ...state,
              editLicence: {
                loading: true,
              },
            };
      
          case actionTypes.EDIT_LICENCE_SUCCESS:
            return {
              ...state,
              editLicence: {
                data: action?.data,
                loading: false,
              },
            };
      
          case actionTypes.EDIT_LICENCE_FAILED:
            return {
              ...state,
              editLicence: {
                error: action.error,
                loading: false,
              },
            };
          case actionTypes.RESET_EDIT_LICENCE:
            return {
              ...state,
              editLicence:{
                loading: false,
                data: null,
                error: null,
              }
            };
            case actionTypes.CHANGE_LICENCE:
              return {
                ...state,
                changelicence: {
                  loading: true,
                },
              };
        
            case actionTypes.CHANGE_LICENCE_SUCCESS:
              return {
                ...state,
                changelicence: {
                  data: action?.data,
                  loading: false,
                },
              };
        
            case actionTypes.CHANGE_LICENCE_FAILED:
              return {
                ...state,
                changelicence: {
                  error: action.error,
                  loading: false,
                },
              };
            case actionTypes.RESET_CHANGE_LICENCE:
              return {
                ...state,
                changelicence:{
                  loading: false,
                  data: null,
                  error: null,
                }
              };

    
  }
  return state;
};

export default LicenceAllocationReducer;
