import React, { useEffect } from "react";
import MainHeader from "../admincommon/header";
import MainFooter from "../admincommon/footer";
import { Outlet } from "react-router-dom";
import "./adminstyle.scss";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSubscriptionType } from "@store/subscription/effect";

function MainLayout() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getSubscriptionType());
  }, [dispatch]);

  // const getSubscriptionType = () => {
  //   if (!localStorage.getItem('freeTrial')) {
  //     apiService.getSubscriptionType().then((response) => {
  //       if (response?.data?.data?.subscription) {
  //         localStorage.setItem('freeTrial', response?.data?.data?.subscription?.freeTrial);
  //       }
  //     }).catch((error) => {
  //       console.log('error: ', error);
  //     });
  //   }
  // }

  let header;
  switch (window.location.pathname) {
    case "/dashboard":
      header = "Leader Dashboard";
      break;

    case "/my-account":
      header = "My Account";
      break;

    case "/my-practice":
      header = "My Practice";
      break;

    case "/my-team":
      header = "My Team";
      break;

    default:
      break;
  }
  
  return localStorage?.getItem("profile") ? 
    <div className="dashboard">
      <MainHeader header={header}></MainHeader>
      <Outlet />
      <MainFooter></MainFooter>
    </div>
    :
    <Navigate to='/login' />
}

export default MainLayout;
