import './Users.scss';
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import apiService from '@services/api.service';
import TimeAgo from 'javascript-time-ago'
// English.
import en from 'javascript-time-ago/locale/en'
import Grow from "../../loader/Grow";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

function Users() {
    const [learnerData, setLearnerData] = useState([]);
    const [activePractices, setActicePractices] = useState([]);
    const [tempLearnerData, setTempLearnerData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [currentMedicalPracticeId, setCurrentMedicalPracticeId] = useState<any>(0);

    const columns = [
        // {
        //     name: 'ID',
        //     selector: (row: any) => row?.profile?.id,
        //     sortable: true,
        // },
        {
            name: 'Practice Name',
            selector: (row: any) => `${row?.subscription?.medicalPractice?.practiceName}`,
            sortable: true,
            width: '10'
        },
        {
            name: 'Learner Name',
            selector: (row: any) => `${row?.profile?.name} ${row?.profile?.surname}`,
            sortable: true,
        },
        {
            name: 'Learner Email',
            selector: (row: any) => `${row?.profile?.email}`,
            sortable: true,
        },
        {
            name: 'License Tier',
            selector: (row: any) => `${row?.plan?.name}`,
            sortable: true,
        },
        {
            name: 'LearnUpon (Group)',
            selector: (row: any) => getLearnUponGroupName(row?.plan?.luGroupId),
            sortable: true
        },
        {
            name: 'Last LoggedIn',
            selector: (row: any) => row.profile.lastLoggedIn ? `${timeAgo.format(new Date(row.profile.lastLoggedIn))}` : 'No Login Yet',
        },
    ];

    function getLearnUponGroupName(luGroupId: string) {
        let groupName = '';

        switch (luGroupId) {
            case '708371':
                groupName = 'License Tier – Legacy'
                break;

            case '709608':
                groupName = 'License Tier – Essential'
                break;

            case '709610':
                groupName = 'License Tier – Premium'
                break;

            case '709609':
                groupName = 'License Tier – Professional'
                break;

            default:
                groupName = ''
                break;
        }

        return groupName ? `${groupName} (${luGroupId})` : 'No Group Assigned';
    }

    function filterData(medicalPracticeId: number) {
        console.log('medicalPracticeId: ', medicalPracticeId);
        let tempLearner = JSON.parse(JSON.stringify(tempLearnerData));

        if (Number(medicalPracticeId) === 0) {
         return setLearnerData(tempLearner);
        }
        setLoading(true);
        const data = tempLearner.filter((learnerData: any) => {
            return Number(learnerData?.subscription?.medicalPractice?.id) === Number(medicalPracticeId)
        });
        setLearnerData(data);
        setLoading(false);
    }

    function getLearners() {
        apiService.getLearners().then((resp) => {
            setLoading(false);
            if (resp) {
                setLearnerData(resp.data.learners);
                setTempLearnerData(resp.data.learners);
            }
        }).catch((error) => {
            setLoading(false);
            console.log('error: ', error);
        });
    }

    function getActiveMedicalPractices() {
        apiService.getActiveMedicalPractices().then((resp) => {
            setLoading(false);
            if (resp) {
                setActicePractices(resp.data.medicalPractices);
            }
        }).catch((error) => {
            setLoading(false);
            console.log('error: ', error);
        });
    }

    useEffect(() => {
        getActiveMedicalPractices();
        getLearners();
    }, []);

    return (
        <div className="user-list-container">

            <div className='filter-header'>
                <select className="custom-select custom-select-sm" onChange={(event) => {
                    // setCurrentMedicalPracticeId(event.target?.value);
                    filterData(event.target?.value as any);
                }}>
                    <option value={0}>All Practices</option>
                    {
                        activePractices.map((practice: any) => {
                           return <option value={practice?.medicalPractice?.id}>{ practice?.medicalPractice?.practiceName }</option>
                        })
                    }
                </select>
                {/* <input className='search-input' placeholder='Search' /> */}
            </div>

            {
                loading ? <div className='loader-container'>
                    <Grow />
                </div> :
                    <DataTable
                        striped
                        responsive
                        fixedHeader
                        highlightOnHover
                        subHeaderWrap
                        fixedHeaderScrollHeight="calc(100vh - 100px)"
                        title="Learner's List"
                        columns={columns}
                        data={learnerData}
                    />
            }

        </div>
    );
}

export default Users;
