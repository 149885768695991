import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/api.service";
import { register, registerDataStore } from "../../store/register/effect";
import { useDispatch, useSelector } from "react-redux";
import { SinglePlan, GetPlanState } from "../../types/Plan.type";
import {
  GetRegisterState,
  registerError,
  GetRegisterDataState,
} from "../../types/Register.type";
import { toast } from "react-toastify";
import { RESET_REGISTER } from "../../store/register/actions";
import Spinner from "react-bootstrap/Spinner";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import { applyCouponCode } from "../../store/coupons/effect";
import { GetApplyCouponState } from "../../types/Coupon.type";
import { RESET_APPLY_COUPON } from "../../store/coupons/actions";
import { getCoupons } from "../../store/coupons/effect";

function Preview({ signup, planId }: { signup: any; planId: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validatedcoupon, setValidatedCoupon] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [toastshow,setToastshow] = useState(true)
  const [exist,setExist] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [couponDetails, setCouponDetails] = useState<any>();
  const [freeTrialLoading, setFreeTrialLoading] = useState(false)

  
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
      validators: {
        urlregex: {
          message: "Please Enter Valid URL",
          rule: (val: any, params: any): any => {
            // eslint-disable-next-line no-useless-escape
            var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
            return regex.test(val);
          },  
        },
      },
    })
  );
  const couponValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
   
    })
  );
  const [applyCouponData, setApplyCouponData] = useState({ couponCode: "" });

  const applyCouponState = useSelector(
    (state: GetApplyCouponState) => state?.couponState.applyCoupon
  );

  const planState = useSelector(
    (state: GetPlanState) => state?.planState.plans
  );

  React.useEffect(() => {
    if (applyCouponState?.error) {
      if (applyCouponState?.error?.data?.message) {
        toast.error(applyCouponState?.error?.data?.message, {
          position: "top-right",
        });
        dispatch({
          type: RESET_APPLY_COUPON,
          data: null,
        });
      }
    }
    if (applyCouponState?.data?.discount) {
        if(toastshow){
        toast.success("Coupon code applied successfully", {
          position: "top-right",
        });
      }
      setDiscount(applyCouponState?.data?.discount);
      setCouponDetails(applyCouponState?.data);
      dispatch({
        type: RESET_APPLY_COUPON,
        data: applyCouponData.couponCode,
        discount: applyCouponState?.data?.discount,
      });
    
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyCouponState]);


  let [total, setTotal] = useState(0);

  React.useEffect(() => {
    var tempTotal = 0;
    planState?.data?.map((item: SinglePlan, index: number) => {
      if (item.quantity && item.monthlyPrice) {
        tempTotal = tempTotal + item.quantity * item.monthlyPrice;
      }
      return item;
    });
    setTotal(tempTotal);
  }, [planState]);

  const handleCoupanInputs = (event: any) => {
    var { name, value } = event.target;

    setApplyCouponData({ ...applyCouponData, [name]: value });
  };

  React.useEffect(() => {
    
    dispatch(getCoupons());
    const checkTypewriter = document.querySelector('.header-pending')
    if (!checkTypewriter) {
      setExist(false)
    }
    var tempTotal = 0;
    planState?.data?.map((item: SinglePlan, index: number) => {
      if (item.quantity && item.monthlyPrice) {
        tempTotal = tempTotal + item.quantity * item.monthlyPrice;
      }
      return item;
    });
    setTotal(tempTotal);

    if (signup === null) {
      if (tempTotal === 0) {
        navigate("/");
      }
    }
    
    if (registerDataState?.data?.practiceName) {
      if (applyCouponState.lastApplied) {
        const selectedPlanObj = planState?.data?.map((plan: SinglePlan) => {
          return {
            id: plan.id,
            quantity: plan.quantity,
          };
        });
        setApplyCouponData({
          ...applyCouponData,
          couponCode: applyCouponState.lastApplied,
        });
        setToastshow(false)
        dispatch(applyCouponCode(applyCouponState.lastApplied, selectedPlanObj));
      }
    }else{
      dispatch({
        type: RESET_APPLY_COUPON,
        data: null,
        lastApplied:null,
        lastDiscount:null,
      });
    }
    const str = window.location.href;
    const after_ = str.slice(str.indexOf("#") + 1);
    var elem = document.getElementById(after_);
    elem && elem.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  React.useEffect(() => {
    couponValidator.current.hideMessages();
  }, [applyCouponState?.data]);

  const applyCouponCodeForm = (event: any) => {
    event.preventDefault();
    setValidatedCoupon(true);
    const formValid = couponValidator.current.allValid();
    if (!formValid) {
    couponValidator.current.showMessages();
    } else {
    const selectedPlanObj = planState?.data?.map((plan: SinglePlan) => {
      return {
        id: plan.id,
        quantity: plan.quantity,
      };
    });
    dispatch(applyCouponCode(applyCouponData.couponCode, selectedPlanObj));
    }
  };

  const [registerData, setRegisterData] = useState({
    practiceName: "",
    practiceUrl: "",
    contactNumber: "",
    address: "",
    suite: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    firstname: "",
    lastname: "",
    professionalTitle: "",
    workEmail: "",
    additionalInfo: "",
  });
  const registertState = useSelector(
    (state: GetRegisterState) => state?.registerState.registerUser
  );
  const registerDataState: any = useSelector(
    (state: GetRegisterDataState) => state?.registerState.registerData
  );

  const [errors, setErrors] = useState<any>(null);

  React.useEffect(()=>{
  },[errors])

  React.useEffect(() => {
    if (registerDataState?.data) {
      setRegisterData({
        practiceName: registerDataState?.data?.practiceName,
        practiceUrl: registerDataState?.data?.practiceUrl,
        contactNumber: registerDataState?.data?.contactNumber,
        address: registerDataState?.data?.address,
        suite: registerDataState?.data?.suite,
        city: registerDataState?.data?.city,
        state: registerDataState?.data?.state,
        country: registerDataState?.data?.country,
        postalCode: registerDataState?.data?.postalCode,
        firstname: registerDataState?.data?.firstname,
        lastname: registerDataState?.data?.lastname,
        professionalTitle: registerDataState?.data?.professionalTitle,
        workEmail: registerDataState?.data?.workEmail,
        additionalInfo: registerDataState?.data?.additionalInfo,
      });
      
    }
  }, [registerDataState]);

  React.useEffect(() => {
    if (registertState?.error) {
      if (registertState?.error?.data?.message) {
        toast.error(registertState?.error?.data?.message, {
          position: "top-right",
        });
      }
      if (registertState?.error?.statusText)  {
        toast.error(registertState?.error?.statusText, {
          position: "top-right",
        });
      } else {
        let obj: any = {};
        if(registertState?.error?.length > 0){
        registertState?.error?.forEach((elem: registerError) => {
          obj[`${elem.param}`] = elem.msg;
        });
        }
        setErrors(obj);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registertState?.error]);

  React.useEffect(() => {
    if (registertState?.data != null) {
      dispatch({ type: RESET_REGISTER });
      localStorage.setItem("medicalPracticeId", registertState.data.toString());
      setErrors(null);
      dispatch(registerDataStore(registerData));
      toast.success("Successfully Registered", {
        position: "top-right",
      });
      const selectedPlanObj = planState?.data?.map((plan: SinglePlan) => {
        return {
          id: plan.id,
          quantity: plan.quantity,
        };
      });
      if (signup === null) {
        setValidated(false);
        setRegisterData({
          practiceName: "",
          practiceUrl: "",
          contactNumber: "",
          address: "",
          suite: "",
          city: "",
          country: "",
          state: "",
          postalCode: "",
          firstname: "",
          lastname: "",
          professionalTitle: "",
          workEmail: "",
          additionalInfo: "",
        });
        simpleValidator.current.hideMessages();
        apiService
          .getPlanSecret(
            selectedPlanObj,
            applyCouponData.couponCode,
            registertState?.data
          )
          .then((resp) => {
            if (resp && resp.data) {
              const { data } = resp.data;
              if(exist===true){
                navigate("/payment", {
                  state: { clientSecret: data?.clientSecret },
                });
              }else{
                navigate("/paymentwithoutheader", {
                  state: { clientSecret: data?.clientSecret },
                });
              }
            }
          })
          .catch((errorData) => {
            const { response } = errorData;
            const error = response;
            toast.error(error?.statusText, {
              position: "top-right",
            });
          });
      } else {
        const selectePlanStatic = [{ id: atob(planId), qty: 1 }];
        const payLoad = {
          plans: selectePlanStatic,
          medicalPracticeId: registertState?.data,
        };
        setValidated(false);
        setRegisterData({
          practiceName: "",
          practiceUrl: "",
          contactNumber: "",
          address: "",
          suite: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
          firstname: "",
          lastname: "",
          professionalTitle: "",
          workEmail: "",
          additionalInfo: "",
        });
        simpleValidator.current.hideMessages();

        apiService
          .createPayment(payLoad)
          .then((resp) => {
            if (resp) {
              navigate("/thankyou");
            }
          })
          .catch((errorData) => {
            if (registertState?.error?.data?.message) {
              toast.error(errorData?.data?.message, {
                position: "top-right",
              });
            }
            const { response } = errorData;
            const error = response;
            toast.error(error?.data?.message, {
              position: "top-right",
            });
          });
      }
    } else {
      setValidated(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registertState]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputs = (event: any) => {
    var { name, value } = event.target;
    if(value==='+'){
      return false;
    }
    let value2 = value.replace('+','')
    simpleValidator.current.showMessageFor(name);
    if(errors && errors[name]!==null){
     errors[name] = '';
    }
    setRegisterData({ ...registerData, [name]: value2 });
  };

  const handleSubmit = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
    } else {
      localStorage.setItem("email", registerData.workEmail);
      const data = {
        name: registerData.practiceName,
        practiceName: registerData.practiceName,
        workEmail: registerData.workEmail,
        website: registerData.practiceUrl,
        contactNumber: registerData.contactNumber,
        location: {
          address: registerData.address,
          suite: registerData.suite,
          city: registerData.city,
          country: registerData.country,
          state: registerData.state,
          postalCode: registerData.postalCode,
        },
        pointOfContact: {
          firstName: registerData.firstname,
          lastName: registerData.lastname,
          professionalTitle: registerData.professionalTitle,
          workEmail: registerData.workEmail,
          additionalInfo:registerData.additionalInfo,
        },
      };

      dispatch(register(data));
    }
  };

  const continueFreeTrial = () => {
    setValidated(true);
    setFreeTrialLoading(true);

    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
    } else {
      localStorage.setItem("email", registerData.workEmail);
      const data: any = {
        name: registerData.practiceName,
        practiceName: registerData.practiceName,
        workEmail: registerData.workEmail,
        website: registerData.practiceUrl,
        contactNumber: registerData.contactNumber,
        location: {
          address: registerData.address,
          suite: registerData.suite,
          city: registerData.city,
          country: registerData.country,
          state: registerData.state,
          postalCode: registerData.postalCode,
        },
        pointOfContact: {
          firstName: registerData.firstname,
          lastName: registerData.lastname,
          professionalTitle: registerData.professionalTitle,
          workEmail: registerData.workEmail,
        },
      };

      apiService.register(data).then((response) => {
        const plans: any = applyCouponState.data?.freeTrailPlans?.map((plan) => {
          return {
            id: plan?.id,
            quantity: plan?.seats
          }
        });

        const payLoad = {
          plans: [...plans, { id: 4, quantity: 1 }],
          medicalPracticeId: response?.data?.data?.medicalPracticeId,
          couponId: applyCouponState?.data?.id,
          freeTrial: applyCouponState?.data?.freeTrail,
        };

        apiService.createFreeTrial(payLoad).then((resp) => {
          if (resp) {
            apiService.thankyouemail = registerData.workEmail;
            navigate("/thankyou");
          } 
          setFreeTrialLoading(false);
        }).catch((error) => {
          setFreeTrialLoading(false);
          console.log('error: ', error);
        })

      }).catch((error) => {
        setFreeTrialLoading(false);
        console.log('error: ', error);
      });

      // dispatch(register(data));
    }
  };

  return (
    <div>
      <h1 className="preview-av">Let’s get some info</h1>
      <div className="subscription-sub-text-box">
        <span className="subscription-sub-text">
          Help us ensure that we have the right information on your practice and
          our best point of contact
        </span>
      </div>
      <div className="plans">
        <b className="lable-elm-info">Practice Info</b>
        <br />
        <br />
        <form className="signup-form" noValidate onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Practice Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="practiceName"
                  maxLength={100}
                  value={registerData.practiceName}
                />
                {simpleValidator.current.message(
                  "practiceName",
                  registerData.practiceName,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.practiceName}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Practice URL*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  maxLength={100}
                  name="practiceUrl"
                  value={registerData.practiceUrl}
                />
                {simpleValidator.current.message(
                  "practiceUrl",
                  registerData.practiceUrl,
                  "required|urlregex|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.practice_url}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Phone No.*</Form.Label>
                <PhoneInput
                  country={"us"}
                  disableDropdown={true}
                  inputProps={{
                    id: "contactNumber",
                    name: "contactNumber",
                    className: "form-elem-single",
                  }}
                  value={registerData?.contactNumber}
                  onChange={(e) =>
                    handleInputs({
                      target: {
                        name: "contactNumber",
                        value: e,
                      },
                    })
                  }
                  countryCodeEditable={false}
                />
              </Form.Group>
              <span className="color-red">{errors?.contactNumber}</span>
              {simpleValidator.current.message(
                "contactNumber",
                registerData.contactNumber,
                "required|max:15|min:11"
              )}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Address*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="address"
                  maxLength={100}
                  value={registerData.address}
                />
                {simpleValidator.current.message(
                  "address",
                  registerData.address,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.address}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Suite, Unit, Etc.</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="suite"
                  maxLength={50}
                  value={registerData.suite}
                />
                {simpleValidator.current.message(
                  "suite",
                  registerData.suite,
                  "max:50"
                )}
              </Form.Group>
              <span className="color-red">{errors?.suite}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">City*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="city"
                  maxLength={100}
                  value={registerData.city}
                />
                {simpleValidator.current.message(
                  "city",
                  registerData.city,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.city}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">State*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="state"
                  maxLength={100}
                  value={registerData.state}
                />
                {simpleValidator.current.message(
                  "state",
                  registerData.state,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.state}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Postal Code*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="postalCode"
                  maxLength={50}
                  value={registerData.postalCode}
                />  
                {simpleValidator.current.message(
                  "postalCode",
                  registerData.postalCode,
                  "required|max:50"
                )}
              </Form.Group>
              <span className="color-red">{errors?.postalCode}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Country*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="country"
                  maxLength={100}
                  value={registerData.country}
                />
                {simpleValidator.current.message(
                  "country",
                  registerData.country,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.country}</span>
            </Col>
          </Row>
          <br />
          <b className="lable-elm-info" id='point-of-contact'>Point of Contact Info</b>
          <br />
          <br />
          <Row>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">First Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="firstname"
                  maxLength={100}
                  value={registerData.firstname}
                />
                {simpleValidator.current.message(
                  "firstname",
                  registerData.firstname,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.firstname}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Last Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="lastname"
                  maxLength={100}
                  value={registerData.lastname}
                />
                {simpleValidator.current.message(
                  "lastname",
                  registerData.lastname,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.lastname}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">
                  Professional Title*
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="professionalTitle"
                  maxLength={100}
                  value={registerData.professionalTitle}
                />
                {simpleValidator.current.message(
                  "professionalTitle",
                  registerData.professionalTitle,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.professionalTitle}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">Work Email*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="workEmail"
                  maxLength={100}
                  value={registerData.workEmail}
                />
                {simpleValidator.current.message(
                  "workEmail",
                  registerData.workEmail,
                  "required|email|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.workEmail}</span>
            </Col>
          </Row>
          <br />
          <b className="lable-elm-info">Additional Info</b>
          <br />
          <br />
          <Row>
            <Col md={12}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Label className="lable-elm">
                  Anything you’d like us to know about your practice?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="additionalInfo"
                  value={registerData.additionalInfo}
                  className="form-elem-single"
                  onChange={handleInputs}
                  maxLength={200}
                  style={{
                    height: "100px",
                    background: "#eee",
                    border: "2px solid #787A6D", 
                    borderRadius: 0,
                  }}
                />
                {simpleValidator.current.message(
                  "additionalInfo",
                  registerData.additionalInfo,
                  "max:200"
                )}
              </Form.Group>
              <span className="color-red">{errors?.additionalInfo}</span>
            </Col>
          </Row>
          <br />
          <b className="lable-elm-info">Apply Coupon</b>
          <br />
          <br />

          <Row>
            <Col md={9}>
              <Form.Group as={Col} controlId="coupan-box" className="form-elem">
                <Form.Control
                  required
                  type="text"
                  name="couponCode"
                  className="form-elem-single"
                  onChange={handleCoupanInputs}
                  value={applyCouponData.couponCode}
                  maxLength={50}
                  placeholder="Apply Coupon Code"
                  style={{ marginBottom: "15px" }}
                />
                {couponValidator.current.message(
                  "couponCode",
                  applyCouponData.couponCode,
                  "required|max:50"
                )}
                {applyCouponState?.data?.status === true && (
                  <span className="text-success">
                    Coupon Applied Successfully
                  </span>
                )}
                {applyCouponState?.error && (
                  <span className="text-danger">
                    {applyCouponState?.error?.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={3}>
              <button
                type="button"
                className="coupan-btn"
                onClick={applyCouponCodeForm}
              >
                Apply Coupon
              </button>
            </Col>
          </Row>

          { applyCouponState?.data?.freeTrail && <p style={{ color: '#0d800d' }}>You are eligible for free trial</p> }

           <Row>
              <Col md={9}></Col>
              <Col md={9}></Col>
           </Row>     

          <div className="get-stated mt-5">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h1 className={`team-price ${applyCouponState?.data?.freeTrail ? 'line-through' : '' }`}>
                ${total}/mo {discount !== 0 && `(-$` + discount + `)`}
              </h1>

              {applyCouponState?.data?.freeTrail && <span className="free-trial-indicator">Free Trial</span>}
            </div>

            <Row>
              <Col md={3}></Col>
              <Col md={6} sm={12} className="mt-2 text-center">
                <span className="team-price-sub-text">
                  We’ll take your practice info next
                </span>
              </Col>
              <Col md={3}></Col>
            </Row>
            { !applyCouponState?.data?.freeTrail && <Button
              type="submit"
              onClick={handleSubmit}
              className="team-start-btn"
              disabled={registertState.loading}
            >
              {registertState.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Onto your billing info 💳
            </Button>}

            {applyCouponState?.data?.freeTrail && <Button
              type="button" 
              onClick={continueFreeTrial}
              className="team-start-btn"
              disabled={registertState.loading}
            >
               {freeTrialLoading && (
                <Spinner className="loader" animation="border" />
              )}
              Continue with Free Trial
            </Button>}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Preview;
