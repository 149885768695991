function Comingsoon() {
  return (
    <>
      <h1 className="text-center" style={{ padding: "150px" }}>
        Coming Soon
      </h1>
    </>
  );
}

export default Comingsoon;
