import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import React, { useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../../configs/app.config";
import { GetPlanState } from "../../types/Plan.type";
import { useSelector } from "react-redux";
import { SinglePlan } from "../../types/Plan.type";
import ApiService from "../../services/api.service";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import SimpleReactValidator from "simple-react-validator";
import Spinner from "react-bootstrap/Spinner";
import './pay.scss';
import * as CryptoJS from 'crypto-js';
import Grow from "../loader/Grow";

const stripePromise = loadStripe(AppConfig.stripePrimaryKey);

function Pay() {
  const planState = useSelector(
    (state: GetPlanState) => state?.planState.plans
  );
  const [data, setData]:any = useState()
  const [clientSecret,setClientSecreat]:any = useState('')
  const [loading,setLoading]:any = useState(true)
  React.useEffect(() => {
    let tempPrice = 0;
    planState?.data?.forEach((plan: any, index: number) => {
      if (plan.quantity > 0) {
        tempPrice += plan.monthlyPrice * plan.quantity;
      }
    });
    if (tempPrice <= 0) {
      // navigate("/paidsignup");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planState?.data]);

  React.useEffect(()=>{
    if(data?.medicalPracticeId)
    ApiService
    .getStripeIntent(data?.medicalPracticeId)
    .then((resp) => {
      if (resp && resp.data) {
        const { data } = resp.data;
        setClientSecreat(data?.clientSecret)
      }
      setLoading(false)
    })
    .catch((errorData) => {
      const { response } = errorData;
      const error = response;
      toast.error(error?.statusText, {
        position: "top-right",
      });
      setLoading(false)
    });
  },[data])

  const appearance = {
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#eee",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Montserrat",
      // See all possible variables below
    },
  };
  function isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
 
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cipherText = urlParams.get('invite');

    if (cipherText) {
      const decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
      const bytes = CryptoJS.AES.decrypt(decData, AppConfig.encodeKey).toString(CryptoJS.enc.Utf8);

      if (bytes && isJsonString(bytes)) {
        const results = JSON.parse(bytes);
        setData(results)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

    return loading===false ? 
    <>
      { clientSecret!=='' ?
      <>
      <section className="stripe-section">
        <Container>
          <Elements stripe={stripePromise} options={{
              // passing the client secret obtained from the server
              clientSecret: clientSecret,
              appearance: appearance,
            }}>
            <StripeIntegration />
          </Elements>
        </Container>
      </section>
      </>
      :
      <section className="stripe-section">
        <Container style={{padding:'200px'}}>
            <h1 style={{textAlign:'center'}}>Something Went Wrong</h1>
        </Container>
      </section>
      }
    </>
    : 
    <>
      <section className="stripe-section">
        <Container style={{padding:'100px'}}>
          <Grow />
        </Container>
      </section>
    </>
}

function StripeIntegration() {
  const stripe = useStripe();
  const elements = useElements();
  const planState = useSelector((state: GetPlanState) => state.planState.plans);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disablebtn, setDisablebtn] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [billingInfo, setBillingInfo] = useState({
    address: "",
    suite: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    code: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const [data, setData]:any = useState()

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
    })
  );

  const navigate = useNavigate();
  function isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cipherText = urlParams.get('invite');

    if (cipherText) {
      const decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
      const bytes = CryptoJS.AES.decrypt(decData, AppConfig.encodeKey).toString(CryptoJS.enc.Utf8);

      if (bytes && isJsonString(bytes)) {
        const results = JSON.parse(bytes);
        setData(results)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);


  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      setValidated(true);
      simpleValidator.current.showMessages();
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setDisablebtn(true);
      await stripe
        .confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: "https://app.alchemyvision.com/my-practice#billing-details",
          },
          redirect: "if_required",
        })
        .then((result) => {
          if (result && !Object.hasOwn(result, "error")) {
              const payLoad = {
                subscriptionId:  data?.subscriptionId,
                medicalPracticeId: data?.medicalPracticeId,
                payment_method: result?.setupIntent?.payment_method
              };

              toast.warn("The process will take some time - so don't refresh the page.", {
                position: "top-right",
              });

            ApiService.createPayment2(payLoad)
              .then((resp) => {
                if (resp) {
                  ApiService.thankyouemail = data?.email;
                  navigate("/thankyou");
                }
              })
              .catch((errorData) => {
                const { response } = errorData;
                const error = response;
                toast.error(error?.statusText, {
                  position: "top-right",
                });
              });
          } else {
            toast.error(result?.error?.message, {
              position: "top-right",
            });
            const section = document.querySelector("#stripe-integration");
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
            setDisablebtn(false);
          }
        });
    }
  };

  React.useEffect(() => {
    var tempTotal = 0;
    planState?.data?.map((item: SinglePlan, index: number) => {
      if (item.quantity && item.monthlyPrice) {
        tempTotal = tempTotal + item.quantity * item.monthlyPrice;
      }
      return item;
    });
  }, [planState]);
  
  return (
    <div className='pay'>
      <h2 className="text-center ">Let’s get your card info</h2>
      <div className="subscription-sub-text-box mb-5">
        <span className="subscription-sub-text">
          Help us ensure that we have the right information on your practice and
          our best point of contact
        </span>
      </div>
     <form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
            <Row>
              <Col md={6} xs={6}>
                   <span className='heading-title'>Practice Name</span><br/>
                   <span className='heading-description'>{data?.practiceName}</span>
                   <br/><br/>
              </Col>              
              <Col md={6} xs={6}>
                  <span className='heading-title'>Contact Number</span><br/>
                  <span className='heading-description'>{data?.contactNumber}</span>
                  <br/><br/>
              </Col>
              <Col md={6} xs={6}>
                  <span className='heading-title'>Website</span><br/>
                  <span className='heading-description'>{data?.website}</span>
                  <br/><br/>
              </Col>
              <Col md={6} xs={6}>
                  <span className='heading-title'>Practice Email</span><br/>
                  <span className='heading-description'>{data?.email}</span>
                  <br/><br/>
              </Col>
            </Row>
        </Col>
        <Col md={6}>
            <div id="stripe-integration">
                <PaymentElement />
              </div>
                <Row>
                  <Container className="small-container ">
                    <Row className="gx-5">
                      <Col md={12} className="mb-5" style={{textAlign:'right'}}>
                        <Button
                          onClick={(e) => handleSubmit(e)}
                          className="team-start-btn"
                          disabled={disablebtn}
                        >
                          {disablebtn && (
                            <Spinner className="loader" animation="border" />
                          )}
                          Onto the confirmation 👌🏽
                        </Button>
                      </Col>
                    </Row>
                  </Container>
              </Row>
        </Col>
      </Row>
      </form>
    </div>
  );
}

export default Pay;
