import React, { useState, useRef } from "react";
import "./Leaderlogin.scss";
import Avwhitelogo from "../../assets/images/av-logo-white.png";
import Loginbtn from "../../assets/images/LoginButton.png";
import apiService from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import SimpleReactValidator from "simple-react-validator";

function Leaderlogin() {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginerror, setLoginError] = useState("");

  const navigate = useNavigate();
  const removeEmojis = (text: any) => {
    if (!text) {
      return "";
    }
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
  };
  const handleInputs = (event: any) => {
    var { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setLoginData({ ...loginData, [name]: removeEmojis(value) });
  };
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
      },
      validators: {
        password: {
          message: "Please Enter Valid Password",
          rule: (val: any, params: any): any => {
            const regex = new RegExp(
              "^(?=.*?[0-9])(?=.*?[a-z])(?=.*?[#?!@$%^&*-_]).{8,}$"
            );
            return regex.test(val);
          },
        },
        emailregex: {
          message: "Please Enter Valid Email",
          rule: (val: any, params: any): any => {
            const regex = new RegExp(
              "^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$"
            );
            return regex.test(val);
          },
        },
      },
    })
  );
  React.useEffect(() => {
    simpleValidator.current.hideMessages();
  }, []);
  const handleSubmit = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
    } else {
      setLoading(true);
      apiService
        .adminLogin(loginData)
        .then((resp) => {
          if (resp && resp.data) {
            setLoginError("");
            setLoading(false);
            const { data } = resp.data;
            if (data.profile.roleId === 2) {
              window.open(data?.url, "_self");
              // navigate("/preview/login");
            } else {
              navigate("/dashboard");
            }
            localStorage.setItem("profile", JSON.stringify(data.profile));
          }
        })
        .catch((errorData) => {
          setLoading(false);
          const { response } = errorData;
          const error = response;

          if (
            error?.data?.message ===
            "We cannot find an account with that email address"
          ) {
            setLoginError(
              "The email you entered doesn’t match our records. 🤔 Give it another try."
            );
          }
          if (error?.data?.message === "Password is invalid!") {
            setLoginError(
              "The password you entered doesn’t match our records. 🤔 Give it another try."
            );
          }
        });
    }
  };

  return (
    <>
      <div className="dashboard-login ">
        <div className="login">
          <div className="wrapper flex_box">
            <div className="form">
              <div className="row">
                <img
                  src={Avwhitelogo}
                  className="mb-5"
                  alt="Av-logo"
                  style={{ width: "400px" }}
                />
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                  autoComplete="false"
                >
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="placeholder" data-placeholder="Email">
                      <input
                        type="email"
                        className="text-form space-left"
                        name="email"
                        value={loginData.email}
                        onChange={(e) => {
                          handleInputs(e);
                        }}
                        autoComplete="off"
                      />
                      {simpleValidator.current.message(
                        "email",
                        loginData.email,
                        "required|max:40|email"
                      )}
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-md-9 col-sm-9 col-9">
                        <div
                          className="placeholder"
                          data-placeholder="Password"
                        >
                          <input
                            type="password"
                            name="password"
                            className="text-form mt-1 space-left password"
                            value={loginData.password}
                            autoComplete="off"
                            onChange={(e) => {
                              handleInputs(e);
                            }}
                          />
                          {simpleValidator.current.message(
                            "password",
                            loginData.password,
                            "required|max:25|password"
                          )}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-2 mt-1">
                      {!loading ? 
                        <button
                          type="submit"
                          className="no-effect"
                        >
                          <img src={Loginbtn} alt="login-btn" />
                        </button>
                        :
                        <button
                          type="submit"
                          className="loader-effect"
                          disabled={true}
                        >
                           <Spinner className="loader" animation="border" />
                        </button>
                      }
                       
                      </div>
                      <div
                        className="col-md-12 mt-5"
                        style={{ marginLeft: "10px" }}
                      >
                        <span className="mt-2 " style={{ color: "white" }}>
                          I forgot my password{" "}
                        </span>
                      </div>
                      <div
                        className="col-md-10 mt-3 "
                        style={{ marginLeft: "12px" }}
                      >
                        <span className="mt-2 " style={{ color: "#A34F52" }}>
                          {loginerror}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <section className="admin-footer">
            <div className="footer-text">
              <span>
                NEED HELP? CALL +1 213 510
                8050&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;EMAIL
                SUPPORT&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;FAQs&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;TERMS
                &amp; CONDITIONS{" "}
              </span>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Leaderlogin;
