import apiService from "../../services/api.service";
import * as actionTypes from "./actions";
import { SingleLicenceAllocation } from "../../types/licenceallocation.type";

export const applyLicenceAllocation = (
  applyLicense: SingleLicenceAllocation,
  isLeader:any
): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.LICENCEALLOCATION,
    });
    apiService
      .applyLicenceAllocation(applyLicense,isLeader)
      .then(({ data, ...rest }) => {
        dispatch({
          type: actionTypes.LICENCEALLOCATION_SUCCESS,
          data: data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.LICENCEALLOCATION_FAILED,
          error,
        });
      });
  };
};

export const getLicence = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_LICENCE,
    });
    apiService
      .getLicence()
      .then(({ data, ...rest }) => {
        const response = data;

        dispatch({
          type: actionTypes.GET_LICENCE_SUCCESS,
          data: response.data.licenses,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LICENCE_FAILED,
          error,
        });
      });
  };
};

export const experienceLevel = (planId:number): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_EXPERIENCE,
    });
    apiService
      .experienceLevel(planId)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_EXPERIENCE_SUCCESS,
          data: response.data.experienceLevel,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EXPERIENCE_FAILED,
          error,
        });
      });
  };
};

export const cancelLicence = (allocationId:number): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.DELETE_EXPERIENCE,
    });
    apiService
      .deleteLicence(allocationId)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.DELETE_EXPERIENCE_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_EXPERIENCE_FAILED,
          error,
        });
      });
  };
};

export const updateLicence = (payload:number): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.EDIT_LICENCE,
    });
    apiService
      .uppdateLicence(payload)
      .then(({ data, ...rest }) => {
        dispatch({
          type: actionTypes.EDIT_LICENCE_SUCCESS,
          data,
        });
      })
     .catch((errorData) => {
          const { response } = errorData;
          const error = response;
        dispatch({
          type: actionTypes.EDIT_LICENCE_FAILED,
          error,
        });
      });
  };
};


export const changeLicence = (payload: any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.CHANGE_LICENCE,
    });
    apiService
      .changeLicenceTire(payload)
      .then(({ data, ...rest }) => {
        dispatch({
          type: actionTypes.CHANGE_LICENCE_SUCCESS,
          data
        });
      })
     .catch((errorData) => {
          const { response } = errorData;
          const error = response;
        dispatch({
          type: actionTypes.CHANGE_LICENCE_FAILED,
          error,
        });
      });
  };
};
