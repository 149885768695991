import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getsubscription = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_SUBSCRIPTION,
    });
    apiService
      .getsubscription()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_SUBSCRIPTION_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_SUBSCRIPTION_FAILED,
          error,
        });
      });
  };
};


export const deleteSubscription  = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.DELETE_SUBSCRIPTION,
    }); 
    apiService
      .deletesubscription()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.DELETE_SUBSCRIPTION_SUCCESS,
          data: response,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.DELETE_SUBSCRIPTION_FAILED,
          error,
        });
      });
  };
};

export const subscriptionList  = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.LIST_SUBSCRIPTION,
    }); 
    apiService
      .subscriptionList()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.LIST_SUBSCRIPTION_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.LIST_SUBSCRIPTION_FAILED,
          error,
        });
      });
  };
};

export const getSubscriptionType  = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.SET_SUBSCRIPTION_TYPE,
    }); 
    apiService
      .getSubscriptionType()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.SET_SUBSCRIPTION_TYPE_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.SET_SUBSCRIPTION_TYPE_FAILED,
          error,
        });
      });
  };
};