import "./footer.css";

function MainFooter() {
  return (
    <section className="admin-footer">
      <div className="footer-text">
        <span>
          NEED HELP? <a className='no-color' href='tel:+12135108050'> CALL +1 213 510 8050</a>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <a className='no-color' href='mailto:hello@alchemyvision.com'>EMAIL SUPPORT</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; <a className='no-color' target='_blank' rel='noreferrer' href='https://www.alchemyvision.com/terms'>TERMS
          & CONDITIONS{" "}</a>
        </span>
      </div>
    </section>
  );
}

export default MainFooter;
