import "./BillingDetailsForm.scss";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { AppConfig } from "../../configs/app.config";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import * as CryptoJS from 'crypto-js';

function isJsonString(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const stripePromise = loadStripe(AppConfig.stripePrimaryKey);

const BillingDetailsForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [processCompleted, setProcessCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cipherText = urlParams.get('clientSecret');

    if (cipherText) {
      try {
        const decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
        const bytes = CryptoJS.AES.decrypt(decData, AppConfig.encodeKey).toString(CryptoJS.enc.Utf8);

        if (bytes && isJsonString(bytes)) {
          const results = JSON.parse(bytes);
          setClientSecret(results);
        }
      } catch (error) {
        setErrorMessage('Failed to load setup intent. Please try again.');
      }
    }
  }, []);


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    try {
      setLoading(true);

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "https://app.alchemyvision.com",
        },
        redirect: "if_required",
      });

      if (result.error) {
        console.log('result.error: ', result.error);
        // Show error to your customer (e.g., insufficient funds)
        setErrorMessage(result.error.message || 'An unexpected error occurred.');
        setLoading(false);
      } else {
        // The setup has succeeded. You can redirect the user or show a success message.
        console.log('Setup succeeded:', result.setupIntent);
        setProcessCompleted(true);
        setLoading(false);

        setTimeout(() => {
          window.location.href = 'https://app.alchemyvision.com';
        }, 3000);
      }
    } catch (error: any) {
      console.log('error: ', error);
      // Handle unexpected errors
      setErrorMessage(error?.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="stripe-payment-element">
      {clientSecret && !processCompleted && (
        <div className="billing-details-form">
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="action-btn-container">
              {errorMessage && <h2 className="stripe-error">{errorMessage}</h2>}
              <Button
                onClick={(e) => handleSubmit(e)}
                className="team-start-btn"
                disabled={loading}
              >
                {loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Confirm subscription 💯
              </Button>
            </div>
          </form>
        </div>
      )}

      {
        processCompleted && <div className="success-container">
          <h2 className="success-message">
            Your billing details have been successfully added. You will be redirected to the Alchemy Vision app shortly...
            <Spinner className="loader" animation="grow" />
          </h2>
        </div>
      }
    </div>
  );
};


function BillingDetails() {
  const [clientSecret, setClientSecret] = useState<string>("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cipherText = urlParams.get('clientSecret');

    if (cipherText) {
      try {
        const decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
        const bytes = CryptoJS.AES.decrypt(decData, AppConfig.encodeKey).toString(CryptoJS.enc.Utf8);

        if (bytes && isJsonString(bytes)) {
          const results = JSON.parse(bytes);
          setClientSecret(results);
        }
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }, []);

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <BillingDetailsForm />
    </Elements>
  );
}

export default BillingDetails;
