import { AppConfig } from "@configs";
import "./Auth.scss";
import apiService from "@services/api.service";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Auth() {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [log, setLog] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    if (window.location.href) {
      const url = new URL(window.location.href);
      const tokenUrl = url?.href;

      if (tokenUrl) {
        setLog(tokenUrl);
        apiService
          .acquireToken(tokenUrl)
          .then((res) => {
            const response = res?.data;

            if (!response?.success) {
              toast.error("Something went wrong try again latter!", {
                position: "top-right",
              });
            }

            // user flows
            if (response?.data?.userFlowUrl) {
              return window.open(response.data.userFlowUrl, "_self");
            }

            // learner login
            if (response?.data?.profile.roleId === 2) {
              return window.open(response.data.url, "_self");
            } 

            // learner login
            if (response?.data?.profile.roleId === 4) {
              setUserData(response.data.profile);
              localStorage.setItem("profile", JSON.stringify(response.data.profile));
              localStorage.setItem("auth", JSON.stringify(response.data.auth));
              return navigate("/junction");
            } 

            // leader login
            if (response?.data) {
              setUserData(response.data.profile);
              localStorage.setItem("profile", JSON.stringify(response.data.profile));
              localStorage.setItem("auth", JSON.stringify(response.data.auth));
              navigate("/dashboard");
            }
          }).catch((error) => {
            console.log('error: ', error); 
            toast.error(error?.response?.data?.message, {
              position: "top-right",
            });

            setTimeout(() => {
              localStorage.clear();
              window.open(AppConfig.logoutRedirectUrl, "_self");
            }, 4000);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="auth-container">
        <div className="auth-preloader">
          <svg
            viewBox="0 0 102 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="big-circle"
              d="M101 51C101 78.6142 78.6142 101 51 101C23.3858 101 1 78.6142 1 51"
              stroke="#252525"
              stroke-width="2"
            />
            <path
              className="small-circle"
              d="M91 51C91 28.9086 73.0914 11 51 11C28.9086 11 11 28.9086 11 51"
              stroke="#252525"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default Auth;
