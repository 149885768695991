// subscription actions
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILED = "GET_SUBSCRIPTION_FAILED";

// delete subscription actions
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILED = "DELETE_SUBSCRIPTION_FAILED";

export const RESET_DELETESUBSCRIPTION = "RESET_DELETESUBSCRIPTION";

// subscription actions
export const LIST_SUBSCRIPTION = "LIST_SUBSCRIPTION";
export const LIST_SUBSCRIPTION_SUCCESS = "LIST_SUBSCRIPTION_SUCCESS";
export const LIST_SUBSCRIPTION_FAILED = "LIST_SUBSCRIPTION_FAILED";

// subscription actions
export const SET_SUBSCRIPTION_TYPE = "SET_SUBSCRIPTION_TYPE";
export const SET_SUBSCRIPTION_TYPE_SUCCESS = "SET_SUBSCRIPTION_TYPE_SUCCESS";
export const SET_SUBSCRIPTION_TYPE_FAILED = "SET_SUBSCRIPTION_TYPE_FAILED";

