/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useRef, useEffect } from "react";
import moment from 'moment';
import Avicon from "../../assets/images/Logo-Training-History-svg.png"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Myteam.scss";
import Profileicon from "../../../src/assets/images/profilesvg.svg";
import ManageIcon from "../../../src/assets/images/Manage-Icon.svg";
import Pagination from "../mypractice/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getLearners } from "../../store/learner/effect";
import { GetLearnerState } from "../../types/Learners.type";
import addicon from "../../assets/images/add-icon.svg";
import Modal from "react-bootstrap/Modal";
import Learner from "../../assets/images/learner.png";
import Exclamation from "../../../src/assets/images/exclamation.svg";
import SimpleReactValidator from "simple-react-validator";
import defaultUser from "../../assets/images/defaultuser.png";
import historyIcon from "../../assets/images/history-Icon.svg";
import {
  applyLicenceAllocation,
  experienceLevel,
  cancelLicence,
  updateLicence,
  changeLicence,
} from "../../store/licenceallocation/effect";
import {
  getLeaders,
  insertLeader,
  removeLeader,
} from "../../store/leaders/effect";
import {
  GetLicenceAllocationState,
  GetDeleteLicenceState,
  GetEditLicenceState,
  GetChangeLicenceState,
} from "../../types/licenceallocation.type";
import {
  GetLeaderState,
  AddLeaderState,
  DeleteLeaderState,
} from "../../types/leaders.type";
import { toast } from "react-toastify";
import Bigprofile from "../../../src/assets/images/bigprofile.svg";
import {
  RESET_LICENCEALLOCATION,
  RESET_EDIT_LICENCE,
  RESET_LICENCE_DELETE,
  RESET_CHANGE_LICENCE,
} from "../../store/licenceallocation/actions";
import { RESET_ADD_LEADER , RESET_REMOVE_LEADER } from "../../store/leaders/actions";
import right from "../../assets/images/right2.svg";
import cross from "../../assets/images/cross2.svg";
import Spinner from "react-bootstrap/Spinner";
import Minusicon from "../../../src/assets/images/minusicon.svg";
import Leader from "../../../src/assets/images/Leader.svg";
import "react-tooltip/dist/react-tooltip.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Tooltip } from "react-tooltip";
import Grow from "../loader/Grow";
import { Link } from "react-router-dom";
import {
  SinglePlan,
  GetPracticePlanState,
  GetEditPracticePlanState,
} from "../../types/Plan.type";
import {
  getPracticePlans,
  changeLicenceCountEffect,
} from "../../store/plans/effect";
import Plan from "../../pages/myteam/Plan";
import Rightcheck from "../../assets/images/rightcheck.svg";
import {
  INCREMENT_PRACTICE,
  DECREMENT_PRACTICE,
  RESET_EDIT_LICENSE,
} from "../../store/plans/actions";
import Chooseplan from "./Chooseplan";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ApiService from "../../services/api.service";
import parse from "html-react-parser";
import {View,Text,Document, Page, Image, pdf,Font } from '@react-pdf/renderer'
import apiService from "../../services/api.service";
import { saveAs } from 'file-saver';
import regular from "../../fonts/SpaceGrotesk-Regular.ttf";
import bold from "../../fonts/SpaceGrotesk-Bold.ttf";
import light from "../../fonts/SpaceGrotesk-Light.ttf";
import semibold from "../../fonts/SpaceGrotesk-SemiBold.ttf";
import med from "../../fonts/SpaceGrotesk-Medium.ttf";

const styles:any = {
  
   row: {
     flexDirection: "row",
     paddingTop:"3",
     paddingLeft:"5",
     paddingRight:"5",
     paddingBottom:"3",
     width:'93%'
   },
   row2: {
      paddingTop:'4px',
      paddingLeft:'3px',
      paddingBottom:'4px',
      flexDirection: "row",
      alignItems: "center",
      // backgroundColor:'#f8f8f8'
    },
    row3: {
      flexDirection: "row",
      alignItems: "center",
    },
  
    rowhead: {
      marginRight:35,
      marginLeft:35,
      marginTop:'5px',
      paddingTop:'8px',
      paddingBottom:'8px',
      flexDirection: "row",
      alignItems: "center",
      // backgroundColor:'#e8e8e8'
    },

   courseName: {
     width: "40%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk-bold',
     paddingTop:'6',
     paddingBottom:'6',
     paddingLeft:'5',
     color:'#000',
      backgroundColor:'#f8f8f8'
    },
    courseNameth: {
      width: "40%",
      fontSize:"10px",
      fontFamily:'SpaceGrotesk-med',
      paddingTop:'10',
      paddingBottom:'10',
     },
   status: {
      width: "12%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk',
     paddingTop:'6',
     paddingBottom:'6',
      backgroundColor:'#f8f8f8'
    },
   enrolled:{
      width:"12%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk',
     paddingTop:'6',
     paddingBottom:'6',
      backgroundColor:'#f8f8f8' 
    },
   completed:{
      width:"12%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk',
       paddingTop:'6',
     paddingBottom:'6',
      backgroundColor:'#f8f8f8'
    },
   score:{
      width:"12%",
     fontSize:"10px",
     alignItem:'center',
     backgroundColor:'#cae2cd',
     fontFamily:'SpaceGrotesk-bold',
     color:'#40916c',
     paddingLeft:'6',
     paddingTop:'6',
     paddingBottom:'6'
   },
   passfail:{
      width:"11%",
     fontSize:"10px",
     alignItem:'center',
     fontFamily:'SpaceGrotesk-bold',
     paddingTop:'6',
     paddingBottom:'6',
     backgroundColor:'#cae2cd',
     color:'#40916c',
     paddingLeft:'5',
   },
   scorefail:{
    width:"12%",
   fontSize:"10px",
   paddingTop:'6',
   paddingBottom:'6',
   alignItem:'center',
   fontFamily:'SpaceGrotesk-bold',
   backgroundColor:'#f9c4be',
   paddingLeft:'5',
   color:'#a34f52'
 },
 passfailfail:{
    width:"11%",
   paddingTop:'6',
   paddingBottom:'6',
   alignItem:'center',
   fontSize:"10px",
   fontFamily:'SpaceGrotesk-bold',
   paddingLeft:'5',
   backgroundColor:'#f9c4be',
   color:'#a34f52'
 },
   courseLength:{
      width:"11%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk'
    },
   statusth: {
      width: "12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   enrolledth:{
      width:"12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   completedth:{
      width:"12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   scoreth:{
      width:"12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   passfailth:{
      width:"11%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   courseLengthth:{
      width:"11%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
    },
   tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginLeft: 35,
      marginRight: 35,
      marginTop: 25,
      // backgroundColor:'#f2f6fc',
      // color:'#4f4f4f',
      fontWeight:'normal',
    },
    headerSection1:{
      width:'18%',  
    },
    headerSection2:{
      width:'30%',
      backgroundColor:"#eee",
      paddingTop:3,
      paddingLeft:6,
      paddingRight:5,
      marginRight:'10',
      borderRadius:3,
      fontFamily:'SpaceGrotesk'
    },
    headerSection3:{
        width:'20%',
      backgroundColor:"#eee",
      marginRight:'10',
      paddingTop:3,
      paddingLeft:6,
      paddingRight:5,
      borderRadius:3,
      fontFamily:'SpaceGrotesk'
    },
    headerSection4:{
        width:'20%',
      backgroundColor:"#eee",
      marginRight:'10',
        paddingTop:3,
      paddingLeft:6,
      paddingRight:5,
      borderRadius:3,
      fontFamily:'SpaceGrotesk',
    },
    headerSection5:{
        width:'20%',
      // backgroundColor:"#eee",
      borderRadius:3,
      fontFamily:'SpaceGrotesk',
      alignItems:'center',
      textAlign:'right',
      right:'0'
    },
    section: { color: 'white', textAlign: 'center', margin: 30 },
    image:{
      width:'100'
    },
    name:{
      fontFamily:'SpaceGrotesk-bold',
      color:'#000',
      fontSize:'11'
    },
    history:{
      fontSize:'18',
    },
    mainContent:{
      backgroundColor:'white',
      marginRight:30,
      marginLeft:30
    },
    emptyColumn:{
      width:'2%',
      backgroundColor:'#f2f6fc'
    },
    footerright: {
      position: 'absolute',
      fontFamily:'SpaceGrotesk-light',
      color:'#a5a5a5',
      bottom: 30,
      left: 0,
      right: 30,
      fontSize:8,
      textAlign: 'right',
    },
    footerleft: {
      position: 'absolute',
      fontFamily:'SpaceGrotesk-light',
      color:'#a5a5a5',
      bottom: 30,
      left: 30,
      right: 0,
      fontSize:8,
      textAlign: 'left',
    },
    
    mainContainer:{
      
    },
    generatedFor:{
      fontSize:'8',
      marginTop:'2',
      fontfamily:'SpaceGrotesk-med'
    },
    generatedForText:{
      fontSize:'10',
      fontFamily:'SpaceGrotesk-med',
      color:'#000',
      marginTop:'2'
    },
    empty:{
      backgroundColor:'#e9e9e9',
      width:"12%",
      fontSize:"9px",
      alignItem:'center',
      fontFamily:'SpaceGrotesk-med',
      paddingLeft:'5',
      paddingTop:'10',
      paddingBottom:'10'
    },
    logoIcon:{
      width:'100%',
      height:'25px',
      objectFit:'contain'
    },
    imageSection:{
      width:'7%',
      display: 'flex',
      marginTop:10,
      marginBottom:10,
    },
    noDataFound:{
      fontSize:"20",
      display:'flex',
      verticalAlign:'middle',
      fontFamily:'SpaceGrotesk-med',
      textAlign:'center',
      marginTop:'20%'
    }
 };
// Create Document Component
const Template = ({ pdfDataa,userName }:any) => {
  const [pdfData,setPdfData]:any =  useState();
  const [lastLogin,setLastLogin]:any =  useState();
 const [historyAsOf,setHistoryAsOf]:any =  useState();

 Font.register({
    family: "SpaceGrotesk",
    src: regular
  })
  Font.register({
    family: "SpaceGrotesk-bold",
    src: bold
  })
  Font.register({
    family: "SpaceGrotesk-light",
    src: light
  })
  Font.register({
    family: "SpaceGrotesk-semibold",
    src: semibold
  })
  Font.register({
    family: "SpaceGrotesk-med",
    src: med
  })

   useEffect(()=>{
       setPdfData(pdfDataa)
       setLastLogin(moment(pdfDataa.data?.lastLogin).format('DD MMM YYYY'));
       setHistoryAsOf(moment(pdfDataa.data?.lastUpdatedDateOfCourseEnrollments).format('DD MMM YYYY'));
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[]) 
   useEffect(()=>{
     let pageArray = [];
     if(pdfData){
       for (let index = 0; index < Math.ceil(pdfData?.length/10); index++) {
         pageArray.push(index);
       }
     }
   },[pdfData])
 
   let date = new Date();
   let year = date.getFullYear();
 
   return (
    <Document>
    <Page wrap  size='A4' orientation="landscape" style={styles}  >
    {/* <View break style={styles.body}> <Text>Page 1</Text> <View break>This will not break - Page 2</View> </View> */}

      <View style={styles.mainContainer}>
        <View style={styles.tableContainer} fixed>
            <View  style={styles.row} >
                    <View style={styles.headerSection1}><Text style={styles.name}>{`SINGLE LEARNER \nCOURSE HISTORY`}</Text></View>
                    <View style={styles.headerSection2}><Text style={styles.generatedFor}>{`Report Generated For`}</Text><Text style={styles.generatedForText}>{userName}</Text></View>
                    <View style={styles.headerSection3}><Text style={styles.generatedFor}>{`History as of`}</Text><Text style={styles.generatedForText}>{lastLogin}</Text></View>
                    <View style={styles.headerSection4}><Text style={styles.generatedFor}>{`Report Generated For`}</Text><Text style={styles.generatedForText}>{historyAsOf}</Text></View>
                    <View style={styles.headerSection5}></View>
                    {/* <View style={styles.headerSection3}><Text style={styles.history}>COURSE HISTORY</Text></View> */}
            </View>
            <View style={styles.imageSection}>
            <Image src={Avicon} style={styles.logoIcon} />
            </View>
        </View>
            <View style={styles.rowhead} fixed>
              <Text style={styles.courseNameth}></Text>
              <Text style={styles.statusth}>{`Engagement\nstatus`}</Text>
              <Text style={styles.enrolledth}>{`Enrollment\ndate`}</Text>
              <Text style={styles.completedth}>{`Completion\ndate`}</Text>
              <Text style={styles.scoreth}>{`Course\nScore`}</Text>
              <Text style={styles.passfailth}>{`Completion\nstatus`}</Text>
            </View>
            {
            pdfData?.enrollmentDetails?.length > 0 ? pdfData?.enrollmentDetails?.map((dataArray:any,mainindex:any)=>{
              return(
              <>
                <View style={styles.mainContent} break={mainindex > 0}  key={mainindex}>
                  {
                  dataArray && dataArray.map((data:any,index:number)=>{
                        return (
                          <>
                              <View style={styles.row2} key={index} >
                                <Text style={styles.courseName}>{data?.luCourseName}</Text>
                                <Text style={styles.status}>Completed</Text>
                                <Text style={styles.enrolled}>{moment(data?.dateEnrolled).format('DD MMM YYYY')}</Text>
                                <Text style={styles.completed}>{moment(data?.dateCompleted).format('DD MMM YYYY')}</Text>
                                {
                                  data?.luCourseStatus ==='passed' &&
                                  <>
                                  <Text  style={styles.score}>{data?.luPercentage}%</Text>
                                  <Text style={styles.passfail}>Pass</Text>
                                  </>
                                  }
                                  {
                                  data?.luCourseStatus ==='failed' &&
                                  <>
                                  <Text style={styles.scorefail}>{data?.luPercentage}%</Text>
                                  <Text style={styles.passfailfail}>Fail</Text>
                                  </>
                                 }
                                    {
                                  data?.luCourseStatus ==='' &&
                                  <>
                                  <Text style={styles.empty}>—</Text>
                                  <Text style={styles.empty}>—</Text>
                                  </>
                                 }

                              </View>
                              <View style={styles.row3} key={index} >
                              </View>
                              
                          </>
                        )
                    })
                  }
                </View>
              </>
              )
            })  
            :
            <Text style={styles.noDataFound}>No Data Found</Text>
            }
      </View>
      <View fixed style={styles.footerleft}>
              <Text>{`Internal Practice Information – Be Mindful of Privacy`}</Text>
      </View>
      <View fixed style={styles.footerright}>
              <Text>{`Alchemy Vision – Copyright `+year}</Text>
      </View>
    </Page>
  </Document>
   )
 }

function Manageteam() {

  let profile = JSON.parse(localStorage.getItem("profile") || "");
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getPracticePlans(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [pdfLoaderLuid, setPdfLoaderLuid] = useState(0);
  const learnerState = useSelector(
    (state: GetLearnerState) => state.learnerState.learnerlist
  );
  const deleteLeaderState = useSelector(
    (state: DeleteLeaderState) => state.leaderState.removeleader
  );
  const changeLicenceState = useSelector(
    (state: GetChangeLicenceState) => state.LicenceAllocationState.changelicence
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeTireSuccess, setChangeTireSuccess] = useState(false);

  const generatePdf = async (luid:number,userName:string) =>{
      setPdfLoader(true)
      setPdfLoaderLuid(luid)
      const fileName = luid+`.pdf`;
      let blob;
      let pdfData;
      apiService
     .generatePdfData(luid)
     .then(async ({data}:any) => {
      pdfData = data.data;
      blob = await pdf(<Template pdfDataa={pdfData} userName={userName} />).toBlob();
      console.log('bolf',blob)
      saveAs(blob, fileName);
      setPdfLoader(false)
     })
     .catch((error:any) => {
      console.log('error',error)
     });
  }

  React.useEffect(() => {
    if (changeLicenceState?.data?.success === true) {
      let id = changeLicenceState?.data?.license?.id;
      dispatch({ type: RESET_CHANGE_LICENCE });
      dispatch(getPracticePlans(1));
      dispatch(getLearners());
      handleLicenceTireClose();
      setChangeTireSuccess(true);
      setDeleteSuccess(false);
      let planName;
      planName = planData?.map((plan: any, index: number) => {
        if(plan.id === changeTire.planId){
          return plan.name ;
        }else{
          return '';
        }
      })
   
      setLearnerDetails({
        ...learnerDetail,
        licensetier: planName,
        planId: changeTire.planId,
        licenseAllocationId:id
      });
    }
    if (changeLicenceState?.error) {
      toast.error(changeLicenceState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_CHANGE_LICENCE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLicenceState]);



  const [addLearner, setAddLearner] = useState(false);
  const [manageLicense, setManageLicense] = useState(false);
  const [learnerInfo, setLearnerInfo] = useState(false);
  const [assignNewLearner, setAssignNewLearner] = useState(false);
  const [deleteLicence, setDeleteLicence] = useState(false);
  const [manageLeader, setManageLeader] = useState(false);
  const [addLeader, setAddLeader] = useState(false);
  const [adminSuccess, setAdminSuccess] = useState(false);
  const [changeLicenceTire, setChangeLicenceTire] = useState(false);
  const [licenceUpdateSuccess, setLicenceUpdateSuccess] = useState(false);
  const [planData, setPlanData] = useState<any>();
  const [profileData, setProfileData] = useState<any>();
  const [isLeader, setIsleader] = useState(false);

  const handleLeaderCheckbox = () => {
    setIsleader(!isLeader);
  };

  useEffect(() => {
    if (localStorage.getItem('profile')) {
      const profile = JSON.parse(localStorage.getItem('profile') as string);
      setProfileData(profile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('profile')]);

  const planState = useSelector(
    (state: GetPracticePlanState) => state.planState.practicePlans
  );

  React.useEffect(() => {
    setPlanData(planState?.data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planState?.data]);

  const [learnerDetail, setLearnerDetails] = useState({
    id: 0,
    name: "",
    surname: "",
    planId: "",
    email: "",
    licensetier: "",
    jobtitle: "",
    currentPeriodEnd: "",
    licenseAllocationId:0,
    cancelAt: "",
    profileId: 0,
    legacyPlan:false
  });
  const [planName, setplanName] = useState("");
  const [licenceData, setLicenceData]: any = useState({
    email: "",
    planId: "",
    subscriptionId: profile?.subscriptionId,
    surname: "",
    name: "",
    experienceLevel: null,
  });
  const [leaderData, setLeaderData]: any = useState({
    name: "",
    surname: "",
    email: "",
    subscriptionId: profile?.subscriptionId,
    jobTitle: "",
  });
  const [editLicenceData, setEditLicenceData]: any = useState({
    email: "",
    planId: 0,
    name: "",
    surname: "",
    experienceLevel: null,
    licenseAllocationId: 0,
    subscriptionId: profile?.subscriptionId,
  });

  const [changeTire, setChangeTire]: any = useState({
    planId: "",
    profileId: "",
    subscriptionId: profile?.subscriptionId,
    medicalPracticeId: profile?.medicalPracticeId,
    licenseAllocationId: learnerDetail?.licenseAllocationId,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const licenceAllocation = useSelector(
    (state: GetLicenceAllocationState) =>
      state.LicenceAllocationState.licenceAllocation
  );
  // const experience = useSelector(
  //   (state: GetExperienceState) => state.LicenceAllocationState.experience
  // );
  const cancelLicenceState = useSelector(
    (state: GetDeleteLicenceState) => state.LicenceAllocationState.deleteLicence
  );
  const leadersState = useSelector(
    (state: GetLeaderState) => state.leaderState.leaderlist
  );
  const addLeadersState = useSelector(
    (state: AddLeaderState) => state.leaderState.addleader
  );
  const editLicenceState = useSelector(
    (state: GetEditLicenceState) => state.LicenceAllocationState.editLicence
  );

  const updatePlanState = useSelector(
    (state: GetEditPracticePlanState) => state.planState.editPlan
  );

  const deleteLeader = (id: number) => {
    dispatch(removeLeader(id));
  };

  const getToolTip = (planname:string) => {
   let infoTooltip =  planState?.data?.map((plan: any, index: number) => {
      if(plan.name === planname){
          if( plan.infoTooltip  !== null)
          return parse(plan.infoTooltip);
          else if( plan.description !== null )
          return parse(plan.description);
          else  
          return '';
      }
      else {
        return ''
      }
    })
    return infoTooltip
  }

  React.useEffect(() => {
    if (updatePlanState?.data?.success === true) {
      toast.success(updatePlanState?.data?.message, {
        position: "top-right",
      });
      setManageLicense(false);
      dispatch({ type: RESET_EDIT_LICENSE });
      dispatch(getPracticePlans(1));
      dispatch(getLearners());
      setLicenceUpdateSuccess(true);
      setLicenceAllocationSuccess(false)
    }
    if (updatePlanState?.error) {
      toast.error(updatePlanState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_EDIT_LICENSE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlanState]);

  const [deleteSuccess, setDeleteSuccess] = useState(false);

  React.useEffect(() => {
    if (deleteLeaderState?.data?.success === true) {
      setDeleteSuccess(true);
      setAdminSuccess(false);
      dispatch(getLeaders());
      dispatch({type:RESET_REMOVE_LEADER})
    }
    if (deleteLeaderState?.error) {
      toast.error(deleteLeaderState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({type:RESET_REMOVE_LEADER})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLeaderState]);

  const [deleteLearner, setDeleteLearner] = useState(false);
  const [from, setFrom] = useState("");
  const [assignEdit, setAssignEdit] = useState(false);
  const [price, setPrice] = useState(0);
  const [proratePrice, setProrateprice] = useState(0);
  const [licenceAllocationSuccess,setLicenceAllocationSuccess] = useState(false)

  function useForceUpdate() {
    return () => setValue((value) => value + 1);
  }

  const forceUpdate = useForceUpdate();

  const incNum = (id: any, value: any, monthlyPrice: any) => {
    dispatch({ type: INCREMENT_PRACTICE, id: id, total: planState?.total,cancelSeatsTotal:planState?.cancelSeatsTotal,legacySeatsTotal:planState?.legacySeatsTotal });
  };

  const decNum = (id: any, value: any, monthlyPrice: any) => {
    dispatch({ type: DECREMENT_PRACTICE, id: id, total: planState?.total,cancelSeatsTotal:planState?.cancelSeatsTotal,legacySeatsTotal:planState?.legacySeatsTotal});
  };

  const MySwal = withReactContent(Swal)

  const resendEmail = (id:any,roleId:any) => {
    MySwal.fire({
      title: <p>Are you sure you want to resend email?</p>,
      icon: 'info',
      showCancelButton: true,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: 'Send',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let data = {
          licenseAllocationId:id,
          roleId: roleId
        }
       return ApiService.sendEmail(data)
          .then(response => {
            toast.success('Email Sent Successfully')
          })
          .catch(error => {
            toast.error('Something Went Wrong')
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
  }
  function add(accumulator:any, a:any) {
    return accumulator + a;
  }
  
  React.useEffect(() => {
    var totleArray:any = [];
    if (planState.data) {
      let price = 0;
      planState?.data?.forEach((item: any, index: number) => {
        let diffamount = 0;
        if(item.totalSeats !== 0){
        price = price + item.totalSeats * item.monthlyPrice;
      }
      let diff = item.totalSeats - item.oldQty;
      if(diff > 0){
        diffamount = diff*item.proratedPrice;
      }
      totleArray[index] = diffamount
      });
      const sum = totleArray.reduce(add, 0);
      setProrateprice(sum)
      
      if(planState?.legacySeatsTotal){
      setPrice(price+planState?.legacySeatsTotal);
      }else{
      setPrice(price);
      }
    }
  }, [planState]);

  React.useEffect(() => {
    if (editLicenceState?.data?.success === true) {
      dispatch({ type: RESET_EDIT_LICENCE });
      dispatch(getLearners());
      handleAssignNewLearnerClose();
      setAssignEdit(true);
      setDeleteLearner(false);
      setChangeTireSuccess(false);
      setLearnerDetails({
        ...learnerDetail,
        name: editLicenceData.name,
        email: editLicenceData.email,
        surname: editLicenceData.surname,
      });
      setChangeTire({
        ...changeTire,
        profileId: "",
        planId: "",
      });
      setEditLicenceData({
        ...editLicenceData,
        name: "",
          surname: "",
          email: "",
          jobTitle: null,
          experienceLevel: null,
      });
    }
    if (editLicenceState?.error) {
      toast.error(editLicenceState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_EDIT_LICENCE });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLicenceState]);

  React.useEffect(() => {
    if (addLeadersState?.data?.success === true) {
      setAdminSuccess(true);
      setDeleteSuccess(false);
      setLeaderData({
        ...leaderData,
        name: "",
        surname: "",
        email: "",
        jobTitle: null,
      });
      addLeaderClose();
      dispatch({ type: RESET_ADD_LEADER });
      dispatch(getLeaders());
      setAddLeader(false);
    }
    if (addLeadersState?.error) {
      toast.error(addLeadersState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({type:RESET_ADD_LEADER})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLeadersState]);

  React.useEffect(() => {
    if (cancelLicenceState?.data) {
        if (cancelLicenceState?.data?.success === true) {
          setAssignEdit(false);
          setDeleteLearner(true);
          setChangeTireSuccess(false);
          // dispatch(getLearners());
        }
      dispatch({ type: RESET_LICENCE_DELETE });
      deleteLicenceClose();
      if (!learnerDetail.cancelAt) {
        setLearnerDetails({ ...learnerDetail, cancelAt: learnerDetail?.currentPeriodEnd });
      } else {
        setLearnerDetails({ ...learnerDetail, cancelAt: "" });
      }
      }
      if (cancelLicenceState?.error) {
        toast.error(cancelLicenceState?.error?.data?.message, {
          position: "top-right",
        });
      dispatch({ type: RESET_LICENCE_DELETE });
      }
     
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelLicenceState]);

  React.useEffect(() => {
    if (licenceAllocation?.data?.success === true) {
      setLicenceAllocationSuccess(true)
      setLicenceUpdateSuccess(false)
      setLearnerDetails({
        ...learnerDetail,
        name: "",
        surname:"",
        licenseAllocationId:0,
        email: "",
        licensetier: "",
        id: 0,
        currentPeriodEnd: "",
        cancelAt: "",
        planId: "",
        legacyPlan:false
      });
      
      reset("", "");
      dispatch({ type: RESET_LICENCEALLOCATION });
      dispatch(getLearners());
      dispatch(getPracticePlans(1));
      setAddLearner(false);
      handleLearnerClose()
    }
    if (licenceAllocation?.error) {
      toast.error(licenceAllocation?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_LICENCEALLOCATION });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenceAllocation]);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
      },
      validators: {
        emailregex: {
          message: "Please Enter Valid Email",
          rule: (val: any, params: any): any => {
            const regex = new RegExp(
              "^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$"
            );
            return regex.test(val);
          },
        },
      },
    })
  );

  const leaderValidator = useRef(new SimpleReactValidator({ 
  validators: {
    emailregex: {
      message: "Please Enter Valid Email",
      rule: (val: any, params: any): any => {
        const regex = new RegExp(
          "^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$"
        );
        return regex.test(val);
      },
    },
  },}));

  const assignValidator = useRef(new SimpleReactValidator({ 
    validators: {
      emailregex: {
        message: "Please Enter Valid Email",
        rule: (val: any, params: any): any => {
          const regex = new RegExp(
            "^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$"
          );
          return regex.test(val);
        },
      },
    },}));

  const removeLicence = (id: number, from: string) => {
    dispatch(cancelLicence(id));
    setFrom(from);
  };

  //addlearner
  const handleLearnerClose = () => {
    setFrom("");
    setLicenceData({
      ...licenceData,
      email: "",
      planId: "",
      surname: "",
      name: "",
      experienceLevel: null,
    });
    simpleValidator.current.hideMessages();
    simpleValidator.current.hideMessageFor("name");
    simpleValidator.current.hideMessageFor("surname");
    simpleValidator.current.hideMessageFor("email");
    simpleValidator.current.hideMessageFor("experienceLevel");
    forceUpdate();
    setAddLearner(false);
    setChangeLicenceTire(false);
  };

  const handleLicenceTireOpen = () => {
    setChangeLicenceTire(true);
  };

  const handleLicenceTireClose = () => {
    reset("", "");
    setChangeLicenceTire(false);
  };

  const handleLearnerInfoClose = () => {
    setDeleteLearner(false);
    setLearnerInfo(false);
    setAssignEdit(false);
    setChangeTireSuccess(false);
  };

  const showManageLicense = () => {
    setManageLicense(true);
  };

  const hideManageLicense = () => {
    setManageLicense(false);
  };

  const addLeaderOpen = () => {
    setAddLeader(true);
  };

  const addLeaderClose = () => {
    leaderValidator.current.hideMessages();
    leaderValidator.current.hideMessageFor("name");
    leaderValidator.current.hideMessageFor("surname");
    leaderValidator.current.hideMessageFor("email");
    leaderValidator.current.hideMessageFor("jobTitle");
    setLeaderData({
      ...leaderData,
      name: "",
      surname: "",
      email: "",
      jobTitle: null,
    });
    forceUpdate();
  
    setAddLeader(false);
  };

  const handleAssignNewLearnerOpen = (planId: any) => {
    dispatch(experienceLevel(planId));
    setDeleteLearner(false);
    setAssignNewLearner(true);
  };

  const manageLeaderClose = () => {
    setManageLeader(false);
    setDeleteSuccess(false);
    setAdminSuccess(false);
  };

  const manageLeaderOpen = () => {
    setManageLeader(true);
    dispatch(getLeaders());
  };

  const handleAssignNewLearnerClose = () => {
    assignValidator.current.hideMessages();
    assignValidator.current.hideMessageFor("name");
    assignValidator.current.hideMessageFor("surname");
    assignValidator.current.hideMessageFor("email");
    assignValidator.current.hideMessageFor("experienceLevel");
    setEditLicenceData({
      ...editLicenceData,
      name: '',
      email: '',
      surname: '',
    });
    forceUpdate();
    setAssignNewLearner(false);
  };

  const deleteLicenceOpen = () => {
    setDeleteLicence(true);
  };

  const deleteLicenceClose = () => {
    setDeleteLicence(false);
  };

  const handleLearnerShow = (item: any) => {
    setplanName(item?.plan?.name);
    setAddLearner(true);
    setLicenceData({ ...licenceData, ...{ planId: item?.plan?.id, id: item.id } });
    dispatch(experienceLevel(item?.plan?.id));
  };

  const assignSeatNewLearner = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = assignValidator.current.allValid();
    if (!formValid) {
      assignValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(updateLicence(editLicenceData));
    }
  };

  const changeLicenceCount = () => {
    let payload: any = {
      medicalPracticeId: profile.medicalPracticeId,
      plans: [],
    };

    planState?.data?.forEach((plan: any, index: number) => {
      let obj: any = {
        id: 0,
        seats: 0,
      };
      // if(plan.totalSeats !== 0){
      obj.id = plan.id;
      obj.seats = plan.totalSeats;
      payload.plans.push(obj);
      // }
    });
    dispatch(changeLicenceCountEffect(payload));
  };

  const handleLearnerInfo = (
    email: string,
    name: string,
    surname: string,
    licensetier: string,
    id: number,
    currentPeriodE: string,
    cancelAt: string,
    planId: string,
    profileId: number,
    legacyPlan:any
  ) => {
    setLearnerInfo(true);
    setLearnerDetails({
      ...learnerDetail,
      name: name,
      surname: surname,
        email: email,
        licensetier: licensetier,
        licenseAllocationId:id,
        id: id,
        currentPeriodEnd: currentPeriodE,
        cancelAt: cancelAt,
        planId: planId,
        profileId: profileId,
        legacyPlan:legacyPlan
    });

  };

  React.useEffect(() => {
    if (learnerDetail) {
      setEditLicenceData({
        ...editLicenceData,
        licenseAllocationId: learnerDetail.id,
        planId: learnerDetail.planId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerDetail]);

  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return learnerState?.data?.learners?.slice(firstPageIndex, lastPageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, learnerState?.data?.learners]);

  React.useEffect(() => {
    simpleValidator.current.hideMessages();
    leaderValidator.current.hideMessages();
    assignValidator.current.hideMessages();
  }, []);

  React.useEffect(() => {
    dispatch(getLearners());
  }, [dispatch]);

  const updateValue = (event: any) => {
    const { name, value } = event.target;
    let value2 = value.replace('+','')
    if(value==='+'){
      return false;
    }
    simpleValidator.current.showMessageFor(name);
    setLicenceData({ ...licenceData, [name]: value2 });
  };

  const updateValueEdit = (event: any) => {
    const { name, value } = event.target;
    let value2 = value.replace('+','')
    if(value==='+'){
      return false;
    }
    assignValidator.current.showMessageFor(name);
    if (name === "experienceLevel") {
      setEditLicenceData({ ...editLicenceData, [name]: parseInt(value) });
    } else {
      setEditLicenceData({ ...editLicenceData, [name]: value2 });
    }
  };
  const updateLeaderData = (event: any) => {
    const { name, value } = event.target;
    let value2 = value.replace('+','')
    if(value==='+'){
      return false;
    }
    if (value !== "") {
      leaderValidator.current.showMessageFor(name);
    }
    setLeaderData({ ...leaderData, [name]: value2 });
  };

  const addNewLeader = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = leaderValidator.current.allValid();
    if (!formValid) {
      leaderValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(insertLeader(leaderData));
    }
  };

  React.useEffect(() => {}, [changeTire]);



  const reset = (plan: any, learnerId: any) => {
    const data = planData?.map((resp: any) => {
      resp.activeSelect = resp.id === plan.id;
      return resp;
    });

    setChangeTire({
      ...changeTire,
      profileId: learnerId,
      planId: plan.id,
    });

    setPlanData(data);
  };

  const updateTire = () => {
    const request = {
      ...changeTire,
      licenseAllocationId: learnerDetail.licenseAllocationId
    };
    dispatch(changeLicence(request));
  };

  const assignLicense = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(applyLicenceAllocation({...licenceData, licenseAllocationId: licenceData.id},isLeader));
    }
  };

  const formatDate = (date: any) => {
    var d = new Date(date),
      day = "" + d.getDate(),
      year = d.getFullYear();
    let month = d.toLocaleString("default", { month: "long" });
    if (day.length < 2) day = "0" + day;

    return day + ` ` + month + `, ` + year;
  };
  return (
    <>

    {/* <Pdf/> */}
      <Modal show={changeLicenceTire} onHide={handleLicenceTireClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Change License Tier</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <br />
            <span className="new-learner">
              CHOOSE A LICENSE TIER FOR {learnerDetail.name}...
            </span>
            <Row>
              {planState.loading ? (
                <Grow />
              ) : (
                planData?.map((plan: any, index: number) => {
                  return (
                    <Chooseplan
                      key={index}
                      plan={plan}
                      reset={reset}
                      active={plan.activeSelect}
                      currentLicence={plan.id === learnerDetail.planId}
                      learnerId={learnerDetail.profileId}
                    />
                  );
                })
              )}
            </Row>
          </Row>

          <Row className="mt-5">
            <b className="add-learner-extra">
              Your subscription price will adjusted and pro-rated for your next
              billing cycle.
            </b>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleLicenceTireClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            {changeTire.planId !== "" &&
            changeTire.planId !== learnerDetail.planId ? (
              <button 
                disabled={changeLicenceState?.loading}
                className="assign-btn"
                onClick={() => updateTire()}
              >
                {changeLicenceState?.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Update License
              </button>
            ) : (
              <button disabled={true} className="assign-btn">
                Update License
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Modal show={addLearner} onHide={handleLearnerClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Learners</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <img src={Learner} alt="learner" />
          <span className="new-learner">
            &nbsp;&nbsp;&nbsp;&nbsp;ADD A NEW LEARNER
          </span>
          <form
            noValidate
            onSubmit={(e) => {
              assignLicense(e);
            }}
          >
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">First Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value={licenceData.name}
                  name="name"
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    updateValue(e);
                  }}
                />
                {simpleValidator.current.message(
                  "name",
                  licenceData.name,
                  "required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Last Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value={licenceData.surname}
                  name="surname"
                  placeholder="Enter Last Name"
                  onChange={(e) => {
                    updateValue(e);
                  }}
                />
                {simpleValidator.current.message(
                  "surname",
                  licenceData.surname,
                  "required|max:100"
                )}
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <input
                  type="email"
                  className="form-control mt-1 no-radious"
                  value={licenceData.email}
                  name="email"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    updateValue(e);
                  }}
                />
                {simpleValidator.current.message(
                  "email",
                  licenceData.email,
                  "email|required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">License Tier</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">{planName}</span>
                  <input
                    type="hidden"
                    name="planId"
                    value={licenceData.planId}
                  />
                  &nbsp;&nbsp;
                  <button
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="no-border info-img no-bg"
                  >
                    <img src={Exclamation} alt="Exclamation" className="mb-2" />
                  </button>
                  <div
                      className="dropdown-menu no-margin small-width"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="info-desc">
                        {getToolTip((planName))}
                      </div>
                    </div>
                </div>
              </Col>
            </Row>
            <div className="mt-2">
                <input
                  type="checkbox"
                  name="inviteAsLeader"
                  id='inviteAsLeader'
                  className="mt-3 checkbox-select"
                  checked={isLeader}
                  onChange={()=>{handleLeaderCheckbox()}}
                />
                <label className="learner-label" htmlFor="inviteAsLeader">
                Invite as a Leader (if checked, this user will have access to both Learner content and your Leader Dashboard)
                </label>
            </div>
            {/* {experience?.data?.length > 1 && (
              <>
                <Row className="mt-4 gx-5">
                  <label className="learner-label" htmlFor="beginner">
                    Learner Experience Level
                  </label>
                  <br />
                  {experience?.data?.map((exp: any) => {
                    return (
                      <>
                        <Col md={6} xs={12} key={exp.id}>
                          <input
                            type="radio"
                            onChange={(e) => {
                              updateValue(e);
                            }}
                            id={exp.id}
                            name="experienceLevel"
                            value={exp.id}
                            className="mt-3"
                          />
                          &nbsp;&nbsp;
                          <label htmlFor={exp.id} className="checkbox-class">
                            {exp.level}
                          </label>
                        </Col>
                      </>
                    );
                  })}
                  {simpleValidator.current.message(
                    "experienceLevel",
                    licenceData.experienceLevel,
                    "required"
                  )}
                </Row>
              </>
            )} */}
            <Row className="mt-4">
              <p className="add-learner-extra">
                <br />
                This learner will be emailed so that they can continue setting
                up their account.
                <br />
                {planName !== "ONBOARDING" && (
                  <>
                    The experience level you choose above will determine the
                    learning path on which they’ll start.
                  </>
                )}
              </p>
            </Row>
          </form>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleLearnerClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              type="button"
              disabled={licenceAllocation?.loading}
              onClick={(e) => {
                assignLicense(e);
              }}
              className="assign-btn"
            >
              {licenceAllocation?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Assign Seat to this Learner
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal show={manageLicense} onHide={hideManageLicense} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Licenses</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <div className="get-plans-2">
            {!planState?.loading ? (
              <div>
                {planState?.data && planState?.data?.length > 0 ? (
                  <Row>
                    {planState?.data?.map((plan: SinglePlan, index: number) => {
                      return (
                        <Plan
                          incNum={incNum}
                          decNum={decNum}
                          key={index}
                          plan={plan}
                        />
                      );
                    })}
                  </Row>
                ) : (
                  <div className="no-data-found">
                    <h4>No Data Available</h4>
                  </div>
                )}
              </div>
            ) : (
              <Grow />
            )}
            <Row className="mt-4 mb-4">
              <Col md={5} xs={12} style={{ textAlign: "center" }}>
                <span style={{ paddingRight: "35px" }}>Current Rate</span>
                <br />
                <h2>${planState?.total}/mo</h2>
              </Col>
              <Col md={3} xs={12}>
                <span style={{ paddingRight: "35px" }}>New Rate</span>
                <br />  
                <h2>{planState?.total === price ? "-" : "$" + (price-planState?.cancelSeatsTotal) + "/mo"}</h2>
              </Col>
              <Col md={4} xs={12}>
                <span style={{ paddingRight: "35px" }}>Due Today(prorated)</span>
                <br />  
                <h2>{proratePrice <= 0 ? "-" : "$" + (proratePrice.toFixed(2)) + "/mo"}</h2>
              </Col>

            </Row>
            <p style={{textAlign:'center',fontSize:'18px',marginTop:20}}>Rate changes will occur on your next billing cycle. Any deleted licenses will be accessible until then.</p>
          </div>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                hideManageLicense();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              type="button"
              disabled={updatePlanState?.loading}
              onClick={() => {
                changeLicenceCount();
              }}
              className="assign-btn"
            >
              {updatePlanState?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Confirm New License Count
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal show={learnerInfo} onHide={handleLearnerInfoClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Learner</Modal.Title>
          {deleteLearner ? (
            <>
              <div className="update-class">
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  {from === "fromDelete"
                    ? "License deletion successful"
                    : "Undo deletion successful"}
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
          {changeTireSuccess && (
            <>
              <div className="update-class">
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  Learner Tire Updated Successfully
                </span>
              </div>
            </>
          )}
          {assignEdit ? (
            <>
              <div>
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  New learner details have been saved
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <Col md={3} xs={12}>
              <img src={Bigprofile} alt="profile-img" />
            </Col>
            <Col md={4} xs={12}>
              <label className="learner-label">Name</label>
              <br />
              <div className="mt-2">
                <span className="team-training-text ">
                  {learnerDetail.name+` `+learnerDetail.surname}
                </span>
                &nbsp;&nbsp;
              </div>
            </Col>
            <Col md={4} xs={12}>
              <label className="learner-label">Email</label>
              <br />
              <div className="mt-2">
                <span className="team-training-text ">
                  {learnerDetail.email}
                </span>
                &nbsp;&nbsp;
              </div>
            </Col>
          </Row>
          <Row className="gx-5">
            <Col md={3} xs={12}></Col>
            <Col md={4} xs={12}>
              <label className="learner-label">License Tier</label>
              <div className="mt-2">
                <span className="team-training-text ">
                  {learnerDetail.licensetier}
                </span>
                &nbsp;&nbsp;
                <button
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="no-border info-img no-bg"
              >
                  <img src={Exclamation} alt="Exclamation" className="mb-2" />
              </button>
                  <div
                      className="dropdown-menu no-margin small-width"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="info-desc">
                        {getToolTip(learnerDetail.licensetier)}
                      </div>
                    </div>
              <br />
                <br />
                {!learnerDetail.cancelAt ? (
                  <>
                    <button
                      onClick={() => {
                        handleLicenceTireOpen();
                      }}
                      className="change-license-tier mt-2"
                    >
                      Change License Tier
                    </button>
                    <br />
                    <button
                      className="delete-license-tier mt-2"
                      onClick={() => {
                        deleteLicenceOpen();
                      }}
                    >
                      Delete License
                    </button>
                  </>
                ) : (
                  <h4 style={{ color: "#A34F52" }}>
                    License active until
                    <br /> {formatDate(learnerDetail?.cancelAt)}
                  </h4>
                )}
              </div>
            </Col>
            <Col md={4} xs={12}>
              <label className="learner-label">Job Title</label>
              <br />
              <div className="mt-2">
                <span className="team-training-text ">{learnerDetail?.jobtitle ? learnerDetail?.jobtitle : '-'}</span>
                &nbsp;&nbsp;
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <p className="add-learner-extra">
              By clicking “Change License Tier” above, you can upgrade or
              downgrade this learner’s license (your subscription price will be
              automagically updated). If you’d rather keep the license tier as
              it is, but assign it to another team member, click “Assign Seat to
              New Learner” below.
            </p>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleLearnerInfoClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            {learnerDetail.cancelAt !== "" ? (
              <button
                className="assign-btn mt-2"
                onClick={() => {
                  deleteLicenceOpen();
                }}
              >
                Undo License Deletion
              </button>
            ) : (
              <button
                className="assign-btn"
                onClick={() => {
                  handleAssignNewLearnerOpen(learnerDetail.planId);
                }}
              >
                Assign Seat to a New Learner
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Modal show={deleteLicence} onHide={deleteLicenceClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Delete License</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          {learnerDetail.cancelAt === "" ? (
            <>
              <Row className="gx-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Here’s what you should know before you cancel:
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center">
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <img src={right} alt="cancel" />
                  </div>
                  <div className="mt-5">
                    <span className="cancel-text">
                      The learner will continue to have access to their course
                      content until {formatDate(learnerDetail.currentPeriodEnd)}
                      , then your plan rate will adjust for the new billing
                      cycle.
                    </span>
                  </div>
                </Col>
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <img src={cross} alt="right" />
                  </div>
                  <div className="mt-5">
                    {
                      learnerDetail?.legacyPlan===false ?
                    <>
                    <span className="cancel-text">
                      We save the learner progress and data, so you can alway
                      re-add them to another license in the future.
                    </span>
                    </>
                    :
                    <>
                    <span className="cancel-text" style={{color:'#A34F52'}}>
                      This is a Legacy license providing access to all content on the platform.
                      This license is no longer offered, so <span style={{borderBottom:'1px solid'}}>deleting it is irreversible.</span>&nbsp;
                      Licenses added to the account in the future will follow current pricing.
                    </span>
                    </>
                    }
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="gx-5 mt-5 mb-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Undo Delete License:
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center"></Row>
            </>
          )}
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                deleteLicenceClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>

          <Col
            md={6}
            xs={12}
            className="footer-section-2 text-center"
            style={{ background: "#A34F52" }}
          >
            {learnerDetail.cancelAt === "" ? (
              <button
                className="assign-btn"
                onClick={() => {
                  removeLicence(learnerDetail.id, "fromDelete");
                }}
                disabled={cancelLicenceState.loading}
                style={{ background: "#A34F52" }}
              >
                {cancelLicenceState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Delete License
              </button>
            ) : (
              <button
                className="assign-btn"
                onClick={() => {
                  removeLicence(learnerDetail.id, "fromUndo");
                }}
                disabled={cancelLicenceState.loading}
                style={{ background: "#A34F52" }}
              >
                {cancelLicenceState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Undo Delete License
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Modal
        show={assignNewLearner}
        onHide={handleAssignNewLearnerClose}
        size="xl"
      >
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Assign New Learner</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <Col md={6} xs={12} className="border-right-custom">
              <br />
              <span className="new-learner">CURRENT LEARNER INFORMATION</span>
              <Row>
                <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                  <label className="learner-label">Name</label>
                  <br />
                  <div className="mt-2">
                    <span className="team-training-text ">
                      { learnerDetail.name+ ` `+ learnerDetail.surname} 
                    </span>
                    &nbsp;&nbsp;
                  </div>
                </Col>
                <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                  <label className="learner-label">Email</label>
                  <br />
                  <div className="mt-2">
                    <span className="team-training-text ">
                      {learnerDetail.email}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                </Col>
                <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                  <label className="learner-label">License Tier</label>
                  <br />
                  <div className="mt-2">
                    <span className="team-training-text ">
                      {learnerDetail?.licensetier}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} xs={12}>
              <br />
              <span className="new-learner">NEW LEARNER INFORMATION</span>
              <Row>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">First Name</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="Enter First Name"
                    name="name"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.name}
                  />
                  {assignValidator.current.message(
                    "name",
                    editLicenceData.name,
                    "required|max:100"
                  )}
                </Col>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="Enter Last Name"
                    name="surname"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.surname}
                  />
                  {assignValidator.current.message(
                    "surname",
                    editLicenceData.surname,
                    "required|max:100"
                  )}
                </Col>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">Email</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="lorem@ipsum.io"
                    name="email"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.email}
                  />
                  {assignValidator.current.message(
                    "email",
                    editLicenceData.email,
                    "email|required|max:100"
                  )}
                </Col>
                {/* {experience?.data?.length > 1 && (
                  <>
                    <Row className="mt-4 gx-5">
                      <label className="learner-label" htmlFor="beginner">
                        Learner Experience Level
                      </label>
                      <br />
                      {experience?.data?.map((exp: any) => {
                        return (
                          <>
                            <Col md={12} xs={12} key={exp.id}>
                              <input
                                type="radio"
                                onChange={(e) => {
                                  updateValueEdit(e);
                                }}
                                id={exp.id}
                                name="experienceLevel"
                                value={exp.id}
                                className="mt-3"
                              />
                              &nbsp;&nbsp;
                              <label
                                htmlFor={exp.id}
                                className="checkbox-class"
                              >
                                {exp.level}
                              </label>
                            </Col>
                          </>
                        );
                      })}
                      {assignValidator.current.message(
                        "experienceLevel",
                        editLicenceData.experienceLevel,
                        "required"
                      )}
                    </Row>
                  </>
                )} */}
              </Row>
            </Col>
          </Row>

          <Row className="mt-5">
            <p className="add-learner-extra">
              The current learner will be notified of their account suspension,
              the new learner will be notified
              <br /> about setting up their account, and there will be no
              changes to your subscription licenses or price.
            </p>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleAssignNewLearnerClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              className="assign-btn"
              disabled={editLicenceState?.loading}
              onClick={(e) => {
                assignSeatNewLearner(e);
              }}
            >
              {editLicenceState?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Assign Seat to this Learner
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal
        scrollable={true}
        show={manageLeader}
        onHide={manageLeaderClose}
        size="xl"
      >
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Leaders</Modal.Title>
          {deleteSuccess ? (
            <>
              <div className="update-class">
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  Admin deletion successful
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
          {adminSuccess ? (
            <>
              <div className="update-class">
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  Admin Addition successful
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <table className="table table-borderless responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th> 
                    <th>Job Title</th>
                    <th>Last Login</th>
                  </tr>             
                </thead>
                <tbody className="border-top-custom ">
                  <br />
                  {!leadersState.loading ? (
                    leadersState?.data?.leaders?.map(
                      (leader: any, index: number) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>
                                <img
                                  height="65px"
                                  width="65px"
                                  src={defaultUser}
                                  alt="profileicon"
                                />
                              </td>
                              <td>
                                <span className="table-name">
                                  {leader.name
                                    ? leader.name + ` ` + leader.surname
                                    : "-"}
                                </span>
                              </td>
                              <td className="licence-text">
                                {leader.jobTitle ? leader.jobTitle : "-"}
                              </td>
                              <td className="last-login-date">
                                {leader.activeStatus}
                                { leader?.activeStatus==='Invite sent' && (
                                    <>
                                      <div>
                                        <a className='no-border no-bg resend-email' style={{color:'#212529',textDecoration:'none',cursor:'pointer'}} onClick={()=>{resendEmail(leader?.id,1)}}>(Click here to resend email)</a>
                                      </div>
                                    </>
                                  ) }
                              </td>

                              <td>
                                {
                                  leader.activeStatus === 'Unassigned' ? 
                                  <img 
                                   onClick={() => {
                                    addLeaderOpen();
                                  }}
                                  src={addicon} alt="addicon" height={60} width={60} />
                                  :
                                  !leader?.isDefault && (
                                    <>
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={Minusicon} alt="minusicon" />
                                        </a>
                                        <div
                                          className="dropdown-menu "
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <div className="delete-leader delete-description">
                                            Are you sure you want to remove {leader.name} {leader.surname} as a Leader? They’ll receive a
                                            notification via email.
                                          </div>
                                          <div className="delete-leader-button text-center">
                                            <button
                                              className="delete-button"
                                              onClick={() =>
                                                deleteLeader(leader.id)
                                              }
                                            >
                                              Remove this Leader
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <Grow />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                manageLeaderClose();
              }}
              className="cancel-btn"
            >
              Back to Manage Team
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              onClick={() => {
                addLeaderOpen();
              }}
              className="assign-btn"
            >
              Add a New Leader
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal show={addLeader} onHide={addLeaderClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Leaders</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <img src={Leader} alt="learner" />
          <span className="new-learner">ADD A NEW LEADER</span>
          <form id="leader-form">
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">First Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter First Name"
                  name="name"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.name}
                />
                {leaderValidator.current.message(
                  "name",
                  leaderData.name,
                  "required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Last Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter Last Name"
                  name="surname"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.surname}
                />
                {leaderValidator.current.message(
                  "surname",
                  leaderData.surname,
                  "required|max:100"
                )}
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <input
                  type="email"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter Email"
                  name="email"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.email}
                />
                {leaderValidator.current.message(
                  "email",
                  leaderData.email,
                  "email|required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Job Title</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter Jobtitle"
                  name="jobTitle"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.jobTitle}
                />
                {leaderValidator.current.message(
                  "jobTitle",
                  leaderData.jobTitle,
                  "required|max:100"
                )}
              </Col>
            </Row>
            <br />
            <br />
          </form>
          <Row className="mt-4">
            <p className="add-learner-extra">
              This leader will be emailed of their account creation, so they can
              continue setting up their account. They will have the same
              administrative privileges as you, including the ability to:
              <br />
              <b>
                manage learners, your Alchemy Vision subscription, your practice
                information, and payment type
              </b>
            </p>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                addLeaderClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              onClick={(e) => {
                addNewLeader(e);
              }}
              disabled={addLeadersState?.loading}
              className="assign-btn"
            >
              {addLeadersState?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Add New Leader
            </button>
          </Col>
        </Row>
      </Modal>
      <Col md={12} className="mt-5" id="manage-team">
        <div className="common-box">
          <div className="partition practice-color ">
            <Row>
              <Col md={3}>
                <span className="common-text">Manage Learners</span>
              </Col>
              <Col md={9} className="text-right update-class">
                {licenceUpdateSuccess ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    &nbsp;&nbsp;
                    <span>&nbsp;&nbsp;License updates successfully</span>
                  </>
                ) : (
                  <></>
                )}
                {licenceAllocationSuccess ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    &nbsp;&nbsp;
                    <span>&nbsp;&nbsp;License Allocated successfully</span>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <br />
            </Row>
          </div>
          <div className="partition">
            <div className="table-responsive-lg">
              <table className="table table-borderless responsive">
                <thead>
                  <tr className="manage-learner-heading">
                    <th></th>
                    <th>Name</th>
                    <th>License Tier</th>
                    <th>Status</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody className="border-top-table">
                  {!learnerState.loading ? (
                    currentTableData && currentTableData?.length > 0 ? (
                      currentTableData.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            {item.email ? (
                              <>
                                <td>
                                  {
                                    !item?.profile?.profilePicture ? (
                                      <img src={Profileicon} alt="profileicon" height={65} width={65} />
                                    ):(
                                      <img src={item?.profile?.profilePicture} alt="profileicon" className='prof-icon' height={65} width={65}/>
                                    )
                                  }
                                </td>
                                <td>
                                  <span className="table-name">
                                    { item?.name + ` ` + item?.surname}
                                  </span>
                                  <br />
                                  <span className="table-email">
                                    {item?.email}
                                  </span>
                                </td>
                                <td className="licence-text">
                                  {item?.plan?.name}
                                </td>
                                <td className="last-login-date">
                                  { item?.activeStatus }
                                  { item?.activeStatus==='Invite sent' && (
                                    <>
                                      <div>
                                        <a className='no-border no-bg resend-email' onClick={()=>{resendEmail(item?.id,2)}}>(Click here to resend email)</a>
                                      </div>
                                    </>
                                  ) }
                                </td>
                                { item?.profileId !== null ? 
                                (
                                  <>
                                  <td>
                                  
                                  { pdfLoader && pdfLoaderLuid===item?.profile?.luId ? 
                                                  <Spinner className="manage-icon" style={{width:30,height:30,marginRight:50,verticalAlign: 'middle'}} animation="grow" />
                                          :
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      generatePdf(item?.profile?.luId,item.name+' '+item.surname)
                                    }}
                                    className="manage-icon"
                                  >
                                    <img src={historyIcon} alt="historyIcon" height={60} width={60} />
                                  </Link>
                                    }

                                  <Link
                                    to="#"
                                    onClick={() => {
                                      handleLearnerInfo(
                                        item.email,
                                        item.name,
                                        item.surname,
                                        item?.plan?.name,
                                        item.id,
                                        item.subscription.currentPeriodEnd,
                                        item.cancelAt ? item.cancelAt : "",
                                        item?.plan?.id,
                                        item.profileId,
                                        item?.plan?.legacyPlan
                                      );
                                    }}
                                    className="manage-icon"
                                  >
                                    <img src={ManageIcon} alt="profileicon" height={65} width={65} />
                                  </Link>
                                </td>
                                    </>
                                ):(
                                <td>
                                  <img 
                                   onClick={() => {
                                    handleLearnerShow(item);
                                  }}
                                  src={addicon} alt="addicon" height={60} width={60} />
                                </td>
                                ) 
                              }
                              </>
                            ) : (
                              <>
                                <td>
                                  <img src={Profileicon} alt="profileicon" height={65} width={65} />
                                </td>
                                <td>
                                  <span className="table-name">
                                    Unassigned Learner
                                  </span>
                                  <br />
                                </td>
                                <td className="licence-text">
                                 {item?.plan?.name}
                                </td>
                                <td className="last-login-date">
                                  No logins yet
                                </td>
                                <td>
                                  {/* <Link
                                    to="#"
                                    onClick={() => {
                                      handleLearnerShow(
                                        item?.plan?.name,
                                        item?.plan?.id
                                      );
                                    }}
                                  >
                                    <img src={addicon} alt="addicon" />
                                  </Link> */}
                                  <img 
                                    onClick={() => {
                                      handleLearnerShow(item);
                                    }} 
                                    src={addicon} alt="addicon" height={60} width={60} />
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="no-data-found" colSpan={5}>
                          <h4>No Data Found</h4>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr className="mt-5 mb-5">
                      <td className="no-data-found" colSpan={5}>
                        <Grow />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={learnerState?.data?.learners?.length}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
          <div className="partition-last">
            <Row className="gx-32 update-btn-border">
              <Col md={6} className="text-center main-div-last">
                <button
                  style={{
                    border: "none",
                    background: "none",
                    marginTop: "10px",
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    manageLeaderOpen();
                  }}
                >
                  Manage Leaders
                </button>
              </Col>
              <Col
                md={6}
                className="text-center main-div-last save-my-details-div"
                style={{ background: "#787A6D" }}
              >
                <button
                  className="save-my-details"
                  style={{
                    border: "none",
                    background: "none",
                    marginTop: "10px",
                    fontWeight: 500,
                    color: "white",
                  }}
                  onClick={() => {
                    if (!profileData?.freeTrial) {
                      showManageLicense();
                    }
                  }}
                  disabled={profileData?.freeTrial}
                >
                  Add / Remove Licenses
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
}

export default Manageteam;
