import React, { useState, useRef } from "react";
import "./Contact.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import { insertContact } from "../../store/contact/effect";
import { useDispatch, useSelector } from "react-redux";
import { GetContactState } from "../../types/Contact.type";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { RESET_CONTACT } from "../../store/contact/actions";
import Thankyoucontact  from "../../pages/thankyoucontact/Thankyoucontact";

function Contact() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const [form,setForm] = useState(false);
  const contactState = useSelector(
    (state: GetContactState) => state.contactState.createContact
  );

  React.useEffect(() => {
    if (contactState?.data?.success === true) {
      setForm(true)
     
      setContactInfo({
        ...contactInfo,
        ...{
          fullName: "",
          professionalTitle: "",
          email: "",
          contactNumber: "+1",
          practiceName: "",
          practiceUrl: "",
          notes: "",
        },
      });
    dispatch({type:RESET_CONTACT})
    }
    simpleValidator.current.hideMessageFor('fullName');
    simpleValidator.current.hideMessageFor('professionalTitle');
    simpleValidator.current.hideMessageFor('email');
    simpleValidator.current.hideMessageFor('contactNumber');
    simpleValidator.current.hideMessageFor('practiceName');
    simpleValidator.current.hideMessageFor('practiceUrl');
    simpleValidator.current.hideMessageFor('notes');
  
    if (contactState?.error) {
      toast.error(contactState?.error?.data?.message, {
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactState]);
  const [contactInfo, setContactInfo] = useState({
    fullName: "",
    professionalTitle: "",
    email: "",
    contactNumber: "",
    practiceName: "",
    practiceUrl: "",
    notes: "",
  });
  const setContactInfoValue = async (event: any) => {
    var { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setContactInfo({ ...contactInfo, [name]: value });
  };
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
      validators: {
        emailregex: {
          message: "Please Enter Valid Email",
          rule: (val: any, params: any): any => {
            const regex = new RegExp(
              "^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$"
            );
            return regex.test(val);
          },
        },
        urlregex: {
          message: "Please Enter Valid URL",
          rule: (val: any, params: any): any => {
            // eslint-disable-next-line no-useless-escape
            var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
            return regex.test(val);
          },
        },
      },
    })
  );
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setValidated(true);
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
    } else {
      dispatch(insertContact(contactInfo));
    }
  };
  return !form ?  (
    <>
      <div className="contact">
        <h1 className="preview-av pt-5 ">Let’s chat about enterprise</h1>
        <div className="subscription-sub-text-box">
          <span className="subscription-sub-text">
            Please fill out the form below and we’re reach out as soon as
            possible
          </span>
        </div>
        <br />
        <br />
        <Container className="small-container ">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Full Name*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single"
                    name="fullName"
                    onChange={(e) => {
                      setContactInfoValue(e);
                    }}
                    value={contactInfo.fullName}
                  />
                  {simpleValidator.current.message(
                    "fullName",
                    contactInfo.fullName,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">
                    Professional Title
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single"
                    name="professionalTitle"
                    onChange={(e) => {
                      setContactInfoValue(e);
                    }}
                    value={contactInfo.professionalTitle}
                  />
                  {simpleValidator.current.message(
                    "professionalTitle",
                    contactInfo.professionalTitle,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Contact Email*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single"
                    name="email"
                    onChange={(e) => {
                      setContactInfoValue(e);
                    }}
                    value={contactInfo.email}
                  />
                  {simpleValidator.current.message(
                    "email",
                    contactInfo.email,
                    "required|email|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">
                    Contact Phone No.*
                  </Form.Label>
                  <PhoneInput
                    country={"us"}
                    disableDropdown={true}
                    inputProps={{
                      id: "contactNumber",
                      name: "contactNumber",
                      className: "form-elem-single",
                    }}
                    value={contactInfo?.contactNumber}
                    onChange={(e) =>
                      setContactInfoValue({
                        target: {
                          name: "contactNumber",
                          value: e,
                        },
                      })
                    }
                    countryCodeEditable={false}
                  />
                  {simpleValidator.current.message(
                    "contactNumber",
                    contactInfo.contactNumber,
                    "required|max:15|min:11"
                  )}
                </Form.Group>

                <br />
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Practice Name*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single"
                    name="practiceName"
                    onChange={(e) => {
                      setContactInfoValue(e);
                    }}
                    value={contactInfo.practiceName}
                  />
                  {simpleValidator.current.message(
                    "practiceName",
                    contactInfo.practiceName,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Practice URL*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single"
                    name="practiceUrl"
                    onChange={(e) => {
                      setContactInfoValue(e);
                    }}
                    value={contactInfo.practiceUrl}
                  />
                  {simpleValidator.current.message(
                    "practiceUrl",
                    contactInfo.practiceUrl,
                    "required|urlregex|max:100"
                  )}
                </Form.Group>
              </Col>

              <Col md={12} className="mt-4">
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">
                    Tell us a little about your enterprise needs
                  </Form.Label>
                  <Form.Control
                    required
                    type="textarea"
                    style={{ height: "100px" }}
                    className="form-elem-single"
                    name="notes"
                    value={contactInfo.notes}
                    onChange={(e) => {
                      setContactInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "notes",
                    contactInfo.notes,
                    "max:200"
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="gx-5 pb-5">
              <Col md={12} className="text-center team-start-div">
                <Button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="team-start-btn"
                  disabled={contactState.loading}
                >
                  {contactState.loading && (
                    <Spinner className="loader" animation="border" />
                  )}
                   Send to our Sales Team 📬
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </>
  ) :
  <Thankyoucontact />
}

export default Contact;
