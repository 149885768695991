import * as actionTypes from "./actions";
import { Billingstate } from "../../types/Billing.type";
const initialState: Billingstate = {
  billigdetails: {
    loading: false,
    data: [],
    error: null,
  },
  editBilligDetails:{
    loading: false,
    data: [],
    error: null,
  }
};

const BillingReducer = (
  state: Billingstate = initialState,
  action: any
): Billingstate => {
  switch (action.type) {
    case actionTypes.GET_BILLING:
      return {
        ...state,
        billigdetails: {
          loading: true,
        },
      };

    case actionTypes.GET_BILLING_SUCCESS:
      return {
        ...state,
        billigdetails: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.GET_BILLING_FAILED:
      return {
        ...state,
        billigdetails: {
          error: action.error,
          loading: false,
        },
      };
      case actionTypes.EDIT_BILLING:
        return {
          ...state,
          editBilligDetails: {
            loading: true,
          },
        };
  
      case actionTypes.EDIT_BILLING_SUCCESS:
        return {
          ...state,
          editBilligDetails: {
            data: action.data,
            loading: false,
          },
        };
  
      case actionTypes.EDIT_BILLING_FAILED:
        return {
          ...state,
          editBilligDetails: {
            error: action.error,
            loading: false,
          },
        };
      
        case actionTypes.RESET_BILLINGEDIT:
          return {
            ...state,
            editBilligDetails: {
              loading: false,
              data: [],
              error: null,
            },
          };

  }
  return state;
};

export default BillingReducer;
