import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getBilling = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_BILLING,
    });
    apiService
      .getBilling()
      .then(({ data, ...rest }) => {
        const response = data
        dispatch({
          type: actionTypes.GET_BILLING_SUCCESS,
          data:response.data.billingDetails,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_BILLING_FAILED,
          error,
        });
      });
  };
};


export const editBilling = (payload:any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.EDIT_BILLING,
    });
    apiService
      .editBilling(payload)
      .then(({ data, ...rest }) => {
        dispatch({
          type: actionTypes.EDIT_BILLING_SUCCESS,
          data:data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.EDIT_BILLING_FAILED,
          error,
        });
      });
  };
};
