import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Toprighttoast() {
  return (
    <>
      <div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Toprighttoast;
