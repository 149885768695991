import * as actionTypes from "./actions";
import { practiceState } from "../../types/Practice.type";
const initialState: practiceState = {
  practice: {
    loading: false,
    data: [],
    error: null,
  },
  editPractice: {
    loading: false,
    data: [],
    error: null,
  },
};

const practiceReducer = (
  state: practiceState = initialState,
  action: any
): practiceState => {
  switch (action.type) {
    case actionTypes.GET_MEDICALPRACTICE:
      return {
        ...state,
        practice: {
          loading: true,
        },
      };

    case actionTypes.GET_MEDICALPRACTICE_SUCCESS:
      return {
        ...state,
        practice: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.GET_MEDICALPRACTICE_FAILED:
      return {
        ...state,
        practice: {
          error: action.error,
          loading: false,
        },
      };

      case actionTypes.EDIT_MEDICALPRACTICE:
      return {
        ...state,
        editPractice: {
          loading: true,
        },
      };

    case actionTypes.EDIT_MEDICALPRACTICE_SUCCESS:
      return {
        ...state,
        editPractice: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.EDIT_MEDICALPRACTICE_FAILED:
      return {
        ...state,
        editPractice: {
          error: action.error,
          loading: false,
        },
      };

      case actionTypes.RESET_PRACTICEEDIT:
      return {
        ...state,
        editPractice: {
          loading: false,
          data: [],
          error: null,
        },
      }; 
      
  }
  return state;
};

export default practiceReducer;
