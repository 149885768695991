/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSubscriptionState,
  GetDeleteSubscriptionState,
} from "../../types/Subscription.type";
import {
  getsubscription,
  deleteSubscription,
} from "../../store/subscription/effect";
import Grow from "../loader/Grow";
import Modal from "react-bootstrap/Modal";
import right from "../../assets/images/right.svg";
import cross from "../../assets/images/cross.svg";
import { RESET_DELETESUBSCRIPTION } from "../../store/subscription/actions";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Rightcheck from "../../assets/images/rightcheck.svg";

function Practicedetails(params: any) {
  const dispatch = useDispatch();
  const subscriptionState = useSelector(
    (state: GetSubscriptionState) => state.subscriptionState.subscription
  );

  const deleteSubscriptionState = useSelector(
    (state: GetDeleteSubscriptionState) =>
      state.subscriptionState.deletesubscription
  );

  const [show6, setShow6] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose6 = () => {
    setShow6(false);
  };
  const handleShow6 = () => {
    setSuccess(false);
    setShow6(true);
  };

  React.useEffect(() => {
    if (deleteSubscriptionState?.data?.success === true) {
      setSuccess(true);
      dispatch({ type: RESET_DELETESUBSCRIPTION });
      dispatch(getsubscription());
      handleClose6();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSubscriptionState]);

  const cancelSubscription = () => {
    dispatch(deleteSubscription());
  };

  React.useEffect(() => {
    dispatch(getsubscription());
  }, [dispatch]);

  React.useEffect(() => {}, [subscriptionState]);

  const formatDate = (date: any) => {
    var d = new Date(date),
      day = "" + d.getDate(),
      year = d.getFullYear();
    let month = d.toLocaleString("default", { month: "long" });
    if (day.length < 2) day = "0" + day;

    return month + ` ` + day + `th, ` + year;
  };

  const formateCancelDate = (date: any) => {
    var d = new Date(date),
      day = "" + d.getDate(),
      year = d.getFullYear();
    var month = d.getMonth()+1;
    if (day.length < 2) day = "0" + day;
    return month + `/` + day + `/` + year;
  };

  return (
    <>
      <Modal show={show6} onHide={handleClose6} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Cancel Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          {subscriptionState?.data?.subscription?.cancelAt === null ? (
            <>
              <Row className="gx-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Here’s what you should know before you cancel:
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center">
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <img src={cross} alt="cancel" />
                  </div>
                  <div className="mt-5">
                    <span className="cancel-text">
                      Your plan will expire at the end of your billing cycle on
                      DD/MM/YYYY. After that time, your learners will no longer
                      have access to their courses.
                    </span>
                  </div>
                </Col>
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <img src={right} alt="right" />
                  </div>
                  <div className="mt-5">
                    <span className="cancel-text">
                      Your team’s data and learning progress will be saved in
                      our system, so you can rejoin Alchemy Vision at any time
                      in the future.
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="gx-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Restore Subscription
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center"></Row>
            </>
          )}
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleClose6();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>

          <Col
            md={6}
            xs={12}
            className="footer-section-2 text-center"
            style={{ background: "#A34F52" }}
          >
            {subscriptionState?.data?.subscription?.cancelAt === null ? (
              <button
                disabled={deleteSubscriptionState.loading}
                className="assign-btn"
                onClick={() => {
                  cancelSubscription();
                }}
                style={{ background: "#A34F52" }}
              >
                {deleteSubscriptionState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Cancel Subscription
              </button>
            ) : (
              <button
                disabled={deleteSubscriptionState.loading}
                className="assign-btn"
                onClick={() => {
                  cancelSubscription();
                }}
                style={{ background: "#A34F52" }}
              >
                {deleteSubscriptionState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Restore Subscription
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Col md={12} className="mt-5" id="manage-subscription">
        <div className="common-box">
          <div className="partition partition-bg">
            <Row>
              <Col md={3}>
                <span className="common-text">Subscription Details</span>
                <br />
              </Col>
              <Col md={9} className="text-right update-class">
                {success ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    <span className="common-success-text">
                      &nbsp;&nbsp;Your updated details have been saved
                    </span>
                  </>
                ) : (
                  <></>
                )}
                <br />
              </Col>
            </Row>
          </div>
          {!subscriptionState.loading ? (
            <>
              <div className="partition">
                <Row className="gx-5 justify-content-center justify-content-center">
                  <Col md={5}>
                    <Title title="Subscription Status" />
                    <br />
                    {subscriptionState?.data?.subscription?.cancelAt ===
                    null ? (
                      <Subtitle
                        subtitle={subscriptionState?.data?.subscription?.status}
                      />
                    ) : (
                      <h4 style={{ color: "#A34F52" }}>
                        Active until{" "}
                        {formateCancelDate(
                          subscriptionState?.data?.subscription?.cancelAt
                        )}
                      </h4>
                    )}
                    <br />
                  </Col>
                  <Col md={4}>
                    <Title title="Member Since" />
                    <br />
                    <Subtitle
                      subtitle={formatDate(
                        subscriptionState?.data?.subscription?.memberSince
                      )}
                    />
                    <br />
                  </Col>
                </Row>
              </div>
              <div className="partition">
                <Row className="gx-5 justify-content-center justify-content-center">
                  <Col md={5}>
                    <Title title="Licenses" />
                    <br />
                    <div className="manage-width">
                      <span className="common-description">
                        {subscriptionState?.data?.subscription?.licenses.map(
                          (license: any, i: any) => {
                            // Return the element. Also pass key
                            return (
                              <div key={i}>
                                <span>
                                  {license.seats + ` ` + license.plan.name}
                                </span>
                                <br />
                              </div>
                            );
                          }
                        )}
                        {subscriptionState?.data?.subscription?.cancelAt ===
                          null && (
                          <>
                            <Link
                              to="/my-team#manage-team"
                              className="manage-team"
                            >
                              Add a New License
                            </Link>
                            <br />
                            <Link
                              to="/my-team#manage-team"
                              className="manage-team"
                            >
                              Manage Learners
                            </Link>
                          </>
                        )}
                      </span>
                    </div>
                  </Col>
                  <Col md={4}>
                    <Title title="Leader Accounts" />
                    <br />
                    <Subtitle
                      subtitle={
                        subscriptionState?.data?.subscription
                          ?.leaderAccountsCount
                      }
                    />
                    <br />
                    {subscriptionState?.data?.subscription?.cancelAt ===
                      null && (
                      <Link to="/my-team#manage-team" className="manage-team">
                        Manage Leaders
                      </Link>
                    )}
                    <br />
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="mt-5 mb-5">
              <Grow />
            </div>
          )}
          <div className="partition-last mt-5 text-center">
            <Row className="gx-5 update-btn-border">
              <Col md={12} className="text-center ">
                {subscriptionState?.data?.subscription?.cancelAt === null ? (
                  <button
                    onClick={() => {
                      handleShow6();
                    }}
                    className="update-practice-detail"
                  >
                    Cancel Subscription
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleShow6();
                    }}
                    className="update-practice-detail"
                  >
                    Restore Subscription
                  </button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
}

export default Practicedetails;
