import React from "react";

function NotFound() {
  return (
    <>
      <div style={{ padding: "100px", textAlign: "center" }}>
        <h1>Page Not Found</h1>
      </div>
    </>
  );
}

export default NotFound;
