import "./App.css";
import React from "react";
import Layout from "./layout/main/MainLayout";
import Layout2 from "./layout/main/MainLayout2";
import Adminlayout from "./layout/main/AdminMainLayout";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  Welcome,
  SignUp,
  PaidSignUp,
  Stripe,
  Adminlogin,
  Thankyou,
  Comingsoon,
  Dashboard,
  Managelicenses,
  Managepayments,
  Manageprofile,
  NotFound,
  NoInternetConnection,
  Auth,
  Mypractice,
  Myteam,
  Myaccount,
  Leaderlogin,
  Managelearner,
  Paginationresults,
  Register,
  Contact,
  Pay,
  AuthRedirect,
  Users, Emerge, Entry, Myresources, Welcome2,
  Junction,
  Pdf,
  BillingDetailsForm
} from "@pages";
import ScrollToTop from "./widgets/scrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {

  return (
    <NoInternetConnection>
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Welcome />} />
          </Route>
          <Route path="/pricing">
            <Route index element={<Welcome2 />} />
          </Route>
          <Route path="/pdf">
            <Route index element={<Pdf />} />
          </Route>
          <Route path="/signup" element={<Layout />}>
            <Route index element={<SignUp />} />
          </Route>
          <Route path="/signupwithoutheader" element={<Layout2 />} >
            <Route index element={<SignUp />} />
          </Route>
          <Route path="/plans" element={<Layout />}>
            <Route index element={<PaidSignUp />} />
          </Route>
          <Route path="/planswithoutheader" element={<Layout2 />}>
            <Route index element={<PaidSignUp />} />
          </Route>
          <Route path="/contact" element={<Layout />}>
            <Route index element={<Contact />} />
          </Route>
          <Route path="/payment" element={<Layout />}>
            <Route index element={<Stripe />} />
          </Route>
          <Route path="/paymentwithoutheader" element={<Layout2 />}>
            <Route index element={<Stripe />} />
          </Route>
          <Route path="/dashboard" element={<Adminlayout />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="/manage-licenses" element={<Adminlayout />}>
            <Route index element={<Managelicenses />} />
          </Route>
          <Route path="/manage-payments" element={<Adminlayout />}>
            <Route index element={<Managepayments />} />
          </Route>
          <Route path="/manage-profile" element={<Adminlayout />}>
            <Route index element={<Manageprofile />} />
          </Route>
          <Route path="/my-practice" element={<Adminlayout />}>
            <Route index element={<Mypractice />} />
          </Route>
          <Route path="/my-team" element={<Adminlayout />}>
            <Route index element={<Myteam />} />
          </Route>
          <Route path="/my-account" element={<Adminlayout />}>
            <Route index element={<Myaccount />} />
          </Route>
          <Route path="/adminlogin">
            <Route index element={<Adminlogin />} />
          </Route>
          <Route path="/authorization-code/callback">
            <Route index element={<Auth />} />
          </Route>
          <Route path="/thankyou" element={<Layout />}>
            <Route index element={<Thankyou />} />
          </Route>
          <Route path="/thankyouwithoutheader" >
            <Route index element={<Thankyou />} />
          </Route>
          <Route path="/comingsoon" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/link-1" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/link-2" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/link-3" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/link-4" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/about" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/help" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/sign-in">
            <Route index element={<Leaderlogin />} />
          </Route>
          <Route path="/sign-up" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/product" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/features" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/resources" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/about" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/blog" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/support" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/login">
            <Route index element={<AuthRedirect />} />
          </Route>
          <Route path="/my-team">
            <Route index element={<Myteam />} />
          </Route>
          <Route path="/manage-learner" element={<Adminlayout />}>
            <Route index element={<Managelearner />} />
          </Route>
          <Route path="/preview/login" element={<Layout />}>
            <Route index element={<Comingsoon />} />
          </Route>
          <Route path="/pagination" element={<Adminlayout />}>
            <Route index element={<Paginationresults />} />
          </Route>
          <Route path="/pdf" element={<Adminlayout />}>
            <Route index element={<Paginationresults />} />
          </Route>
          <Route path="/pay" element={<Layout />}>
            <Route index element={<Pay />} />
          </Route>
          <Route path="/register" element={<Register />} />
          <Route path="/admin/active/learners">
            <Route index element={<Users />} />
          </Route>
          <Route path="*" element={<Layout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path='/Essential'>
            <Route index element={<Emerge />} />
          </Route>
          <Route path='/Professional'>
            <Route index element={<Entry />} />
          </Route>
          <Route path='/my-resources' element={<Adminlayout />}>
            <Route index element={<Myresources />} />
          </Route>
          <Route path='/junction'>
            <Route index element={<Junction />} />
          </Route>
          <Route path='/billing'>
            <Route index element={<BillingDetailsForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </NoInternetConnection>
  );
}

export default App;
