import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { INCREMENT, DECREMENT } from "../../store/plans/actions";
import parse from "html-react-parser";

function Plan({ plan }: { plan: any }) {
  
  const dispatch = useDispatch();

  let incNum = (id: number) => {
    dispatch({
      type: INCREMENT,
      id: id,
    });
  };

  let decNum = (id: number) => {
    dispatch({
      type: DECREMENT,
      id: id,
    });
  };

  const style = {
    background:plan?.colorConfig?.homePage?.selectionCardBg ? plan?.colorConfig?.homePage?.selectionCardBg : '#C7C9C1',
  }
  const buttonStyle = {
    background:plan?.colorConfig?.listIconColor ? plan?.colorConfig?.listIconColor : '#787A6D',
     color: "#fff", border: "0px" 
  }
  const titleColor = {
    color:plan?.colorConfig?.homePage?.cardHeader,
    fontFamily: "Montserrat !important",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "34.13px",
    TextAlign:'left'
  }
  const titleColor2 = {
    color:plan?.colorConfig?.homePage?.titleColor,
    fontFamily: "Montserrat !important",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "21.13px",
    TextAlign:'left',
    marginTop:'10px'
  }
  const bodyColor = {
    color:plan?.colorConfig?.homePage?.titleColor,
    fontWeight:'500',
    fontFamily: "Montserrat !important",
    fontSize: "18px",
    lineHeight: '30px',
    TextAlign: 'left',
  }
  const cardBody = {
    background:plan?.colorConfig?.homePage?.cardBody ? plan?.colorConfig?.homePage?.cardBody : '#F4F5EE',
  }
  
  const backGroundColor = {
    border:'0px',
    background:plan?.colorConfig?.homePage?.dropdownBgColor ? plan?.colorConfig?.homePage?.dropdownBgColor : '#DDDED7'
  }
  return (
    <Col md={12}>
      <Card className="enter-prise-card">
        <Card.Body style={{ paddingTop: 0,paddingBottom:0,paddingRight:10,paddingLeft:10}}>
          <Row style={cardBody}>
            <Col md={3} className="enter-prise-colored2" style={style}>
                <div className='row'>
                  <div className="col-md-7">
                    <div style={titleColor2}> {plan?.name === 'Complimentary' ?  'Leaders' : plan?.name }</div>
                  </div>
                  <div className="col-md-5">
                    <div className="plan-icon-div2">
                      {plan?.primaryIcon ? (
                        <img alt="plan" src={plan?.primaryIcon} />
                      ) : (
                        <button className="plan-icon"></button>
                      )}
                    </div>
                  </div>
                
                <Card.Title className=" mt-2 ">
                  {plan?.name === 'Complimentary' ? <span style={titleColor}><p>Admins & Managers</p></span> : <span style={titleColor}> {parse(plan?.planSubTitle ? plan?.planSubTitle : '')}</span>}
                </Card.Title>
                </div>
            </Col>
            <Col md={6} className="contact-text2">
              <div style={{ marginLeft: "3rem", marginBottom: "2rem" }}>
                <Card.Title className="charges-paid mt-3">
                  {plan?.monthlyPrice !== 0
                    ? `$${plan?.monthlyPrice} per learner /month`
                    : `No additional cost`}
                </Card.Title>
                <Card.Text className="detail-text-2" style={bodyColor}>
                  {plan?.description}
                </Card.Text>
              </div>
            </Col>
            <Col md={3} className="contact-button">
              <button
                className="minus-btn"
                style={buttonStyle}
                onClick={() => {
                  decNum(plan?.id as number);
                }}
              >
                <i className="fa-solid fa-minus"></i>
              </button>
              <input
                type="text"
                className="number"
                name={`${plan?.id}`}
                style={backGroundColor}
                id={`${plan?.id}`}
                data-price={plan?.id}
                value={plan?.quantity}
                readOnly
              />
              <button
                onClick={() => {
                  incNum(plan?.id as number);
                }}
                style={buttonStyle}
                className="plus-btn"
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </Col>
            { (plan?.nextConfig - plan?.quantity) < 3 && (plan?.nextConfig - plan?.quantity) > 0 && (
              <div className="remaining-licence">
                <span>
                  Only {plan?.nextConfig - plan?.quantity} licenses away from
                  ${plan?.nextConfigPrice}/learner
                </span>
              </div>
            )}
          </Row>
        </Card.Body>
      </Card>
      {
        plan?.nextConfig > 0 && plan?.quantity > 0  && (
          <div className='mt-3' style={{textAlign:'right'}}>
            *{
              plan?.rules?.map((rule:any,index:number)=>{
                return (
                   <span key={index}>${rule.price} for {rule.rule} learners {plan?.rules?.length !== index+1 && ' – '}</span>
                )
              }) 
            }
           </div>
        )
      }
    </Col>
  );
}

export default Plan;
