import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getLeaders = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_LEADERS,
    });
    apiService
      .leaderlist()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_LEADERS_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_LEADERS_FAILED,
          error,
        });
      });
  };
};

export const insertLeader = (leaderData:any): any => {
  return (dispatch: any) => {
    dispatch({  
      type: actionTypes.ADD_LEADERS,
    });
    apiService
      .addLeader(leaderData)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.ADD_LEADERS_SUCCESS,
          data: response,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.ADD_LEADERS_FAILED,
          error,
        });

      });
  };
};

export const removeLeader = (subscriptionAllocationId:number): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.REMOVE_LEADER,
    });
    apiService
      .removeLeader(subscriptionAllocationId)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.REMOVE_LEADER_SUCCESS,
          data: response,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.REMOVE_LEADER_FAILED,
          error,
        });
        
      });
  };
};

