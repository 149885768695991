import { combineReducers } from "redux";
import AuthReducer from "./auth/reducer";
import PlanReducer from "./plans/reducer";
import FaqReducer from "./faqs/reducer";
import RegisterReducer from "./register/reducer";
import LicenceAllocationReducer from "./licenceallocation/reducer";
import CouponReducer from "./coupons/reducer";
import accountReducer from "./myaccount/reducer";
import practiceReducer from "./medicalpractice/reducer";
import subscriptionReducer from "./subscription/reducer";
import learnerReducer from "./learner/reducer";
import leaderReducer from "./leaders/reducer";
import BillingReducer from "./billingdetails/reducer";
import ContactReducer from "./contact/reducer";

export const rootReducer = combineReducers({
  authState: AuthReducer,
  planState: PlanReducer,
  faqState: FaqReducer,
  registerState: RegisterReducer,
  LicenceAllocationState: LicenceAllocationReducer,
  couponState: CouponReducer,
  accountState: accountReducer,
  practiceState: practiceReducer,
  subscriptionState: subscriptionReducer,
  learnerState: learnerReducer,
  leaderState: leaderReducer,
  billingState: BillingReducer,
  contactState:ContactReducer
});
