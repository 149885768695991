import React, { useEffect,useState } from 'react'
import {View,Text,Document, Page, PDFViewer, PDFDownloadLink, Image,Font } from '@react-pdf/renderer'
import Avicon from "../../assets/images/Logo-Training-History-svg.png"
import apiService from "../../services/api.service";
import moment from 'moment';
import regular from "../../fonts/SpaceGrotesk-Regular.ttf";
import bold from "../../fonts/SpaceGrotesk-Bold.ttf";
import light from "../../fonts/SpaceGrotesk-Light.ttf";
import semibold from "../../fonts/SpaceGrotesk-SemiBold.ttf";
import med from "../../fonts/SpaceGrotesk-Medium.ttf";

const Pdf = ({ profile }:any) => {

  const downloadPdf =  () =>{
    const click = document?.getElementById('clickable');
    if(click){
      click.click()
    }
  }
  Font.register({
    family: "SpaceGrotesk",
    src: regular
  })
  Font.register({
    family: "SpaceGrotesk-bold",
    src: bold
  })
  Font.register({
    family: "SpaceGrotesk-light",
    src: light
  })
  Font.register({
    family: "SpaceGrotesk-semibold",
    src: semibold
  })
  Font.register({
    family: "SpaceGrotesk-med",
    src: med
  })
  

  return (
    <div style={{ flexGrow: 1 }}>
        <PDFViewer
        showToolbar={false}
        style={{
          width: '100%',
          // height: '100%',
        }}
      >
        <Template profile={profile} />
      </PDFViewer>
      <PDFDownloadLink
        document={<Template profile={profile} />}
        fileName='somename.pdf'
        id='clickable'
      >
        {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>

      <button onClick={()=>downloadPdf()}>Download</button>
    </div>
  )
}

const styles:any = {
  
   row: {
     flexDirection: "row",
     paddingTop:"3",
     paddingLeft:"5",
     paddingRight:"5",
     paddingBottom:"3",
     width:'93%'
   },
   row2: {
      paddingTop:'4px',
      paddingLeft:'3px',
      paddingBottom:'4px',
      flexDirection: "row",
      alignItems: "center",
      // backgroundColor:'#f8f8f8'
    },
    row3: {
      flexDirection: "row",
      alignItems: "center",
    },
  
    rowhead: {
      marginRight:35,
      marginLeft:35,
      marginTop:'5px',
      paddingTop:'8px',
      paddingBottom:'8px',
      flexDirection: "row",
      alignItems: "center",
      // backgroundColor:'#e8e8e8'
    },

   courseName: {
     width: "40%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk-bold',
     paddingTop:'6',
     paddingBottom:'6',
     paddingLeft:'5',
     color:'#000',
      backgroundColor:'#f8f8f8'
    },
    courseNameth: {
      width: "40%",
      fontSize:"10px",
      fontFamily:'SpaceGrotesk-med',
      paddingTop:'10',
      paddingBottom:'10',
     },
   status: {
      width: "12%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk',
     paddingTop:'6',
     paddingBottom:'6',
      backgroundColor:'#f8f8f8'
    },
   enrolled:{
      width:"12%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk',
     paddingTop:'6',
     paddingBottom:'6',
      backgroundColor:'#f8f8f8' 
    },
   completed:{
      width:"12%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk',
       paddingTop:'6',
     paddingBottom:'6',
      backgroundColor:'#f8f8f8'
    },
   score:{
      width:"12%",
     fontSize:"10px",
     alignItem:'center',
     backgroundColor:'#cae2cd',
     fontFamily:'SpaceGrotesk-bold',
     color:'#40916c',
     paddingLeft:'6',
     paddingTop:'6',
     paddingBottom:'6'
   },
   passfail:{
      width:"11%",
     fontSize:"10px",
     alignItem:'center',
     fontFamily:'SpaceGrotesk-bold',
     paddingTop:'6',
     paddingBottom:'6',
     backgroundColor:'#cae2cd',
     color:'#40916c',
     paddingLeft:'5',
   },
   scorefail:{
    width:"12%",
   fontSize:"10px",
   paddingTop:'6',
   paddingBottom:'6',
   alignItem:'center',
   fontFamily:'SpaceGrotesk-bold',
   backgroundColor:'#f9c4be',
   paddingLeft:'5',
   color:'#a34f52'
 },
 passfailfail:{
    width:"11%",
   paddingTop:'6',
   paddingBottom:'6',
   alignItem:'center',
   fontSize:"10px",
   fontFamily:'SpaceGrotesk-bold',
   paddingLeft:'5',
   backgroundColor:'#f9c4be',
   color:'#a34f52'
 },
   courseLength:{
      width:"11%",
     fontSize:"10px",
     fontFamily:'SpaceGrotesk'
    },
   statusth: {
      width: "12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   enrolledth:{
      width:"12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   completedth:{
      width:"12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   scoreth:{
      width:"12%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   passfailth:{
      width:"11%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
   },
   courseLengthth:{
      width:"11%",
     fontSize:"8px",
     color:'#616161',
     fontFamily:'SpaceGrotesk-bold'
    },
   tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginLeft: 35,
      marginRight: 35,
      marginTop: 25,
      // backgroundColor:'#f2f6fc',
      // color:'#4f4f4f',
      fontWeight:'normal',
    },
    headerSection1:{
      width:'18%',  
    },
    headerSection2:{
      width:'30%',
      backgroundColor:"#eee",
      paddingTop:3,
      paddingLeft:6,
      paddingRight:5,
      marginRight:'10',
      borderRadius:3,
      fontFamily:'SpaceGrotesk'
    },
    headerSection3:{
        width:'20%',
      backgroundColor:"#eee",
      marginRight:'10',
      paddingTop:3,
      paddingLeft:6,
      paddingRight:5,
      borderRadius:3,
      fontFamily:'SpaceGrotesk'
    },
    headerSection4:{
        width:'20%',
      backgroundColor:"#eee",
      marginRight:'10',
        paddingTop:3,
      paddingLeft:6,
      paddingRight:5,
      borderRadius:3,
      fontFamily:'SpaceGrotesk',
    },
    headerSection5:{
        width:'20%',
      // backgroundColor:"#eee",
      borderRadius:3,
      fontFamily:'SpaceGrotesk',
      alignItems:'center',
      textAlign:'right',
      right:'0'
    },
    section: { color: 'white', textAlign: 'center', margin: 30 },
    image:{
      width:'100'
    },
    name:{
      fontFamily:'SpaceGrotesk-bold',
      color:'#000',
      fontSize:'11'
    },
    history:{
      fontSize:'18',
    },
    mainContent:{
      backgroundColor:'white',
      marginRight:30,
      marginLeft:30
    },
    emptyColumn:{
      width:'2%',
      backgroundColor:'#f2f6fc'
    },
    footerright: {
      position: 'absolute',
      fontFamily:'SpaceGrotesk-light',
      color:'#a5a5a5',
      bottom: 30,
      left: 0,
      right: 30,
      fontSize:8,
      textAlign: 'right',
    },
    footerleft: {
      position: 'absolute',
      fontFamily:'SpaceGrotesk-light',
      color:'#a5a5a5',
      bottom: 30,
      left: 30,
      right: 0,
      fontSize:8,
      textAlign: 'left',
    },
    
    mainContainer:{
      
    },
    generatedFor:{
      fontSize:'8',
      marginTop:'2',
      fontfamily:'SpaceGrotesk-med'
    },
    generatedForText:{
      fontSize:'10',
      fontFamily:'SpaceGrotesk-med',
      color:'#000',
      marginTop:'2'
    },
    empty:{
      backgroundColor:'#e9e9e9',
      width:"12%",
      fontSize:"9px",
      alignItem:'center',
      fontFamily:'SpaceGrotesk-med',
      paddingLeft:'5',
      paddingTop:'10',
      paddingBottom:'10'
    },
    logoIcon:{
      width:'100%',
      height:'25px',
      objectFit:'contain'
    },
    imageSection:{
      width:'7%',
      display: 'flex',
      marginTop:10,
      marginBottom:10,
    }
 };

// Create Document Component
const Template = ({ profile }:any) => {

 const [pdfData,setPdfData]:any =  useState();
 const [pagesCount,setPagesCount]:any =  useState();
 const [lastLogin,setLastLogin]:any =  useState();
 const [historyAsOf,setHistoryAsOf]:any =  useState();
  useEffect(()=>{
    apiService
    .generatePdfData(17936769)
    .then(async ({data}:any) => {
      console.log('data.data',data.data)
      setPdfData(data.data)
      setLastLogin(moment(data.data?.lastLogin).format('DD MMM YYYY'));
       setHistoryAsOf(moment(data.data?.lastUpdatedDateOfCourseEnrollments).format('DD MMM YYYY'));
    })
    .catch((error:any) => {
    });
  },[])

 
  useEffect(()=>{

    let pageArray = [];
    if(pdfData){
      for (let index = 0; index < Math.ceil(pdfData?.length/10); index++) {
        pageArray.push(index);
      }
      setPagesCount(pageArray)
    }
  },[pdfData])

  let date = new Date();
  let year = date.getFullYear();

  useEffect(()=>{
    console.log('pagesCount',pagesCount)
  },[pagesCount])

  
  return (
    <Document>
      <Page wrap  size='A4' orientation="landscape" style={styles}  >
      {/* <View break style={styles.body}> <Text>Page 1</Text> <View break>This will not break - Page 2</View> </View> */}

        <View style={styles.mainContainer}>
          <View style={styles.tableContainer} fixed>
              <View  style={styles.row} >
                      <View style={styles.headerSection1}><Text style={styles.name}>{`SINGLE LEARNER \nCOURSE HISTORY`}</Text></View>
                      <View style={styles.headerSection2}><Text style={styles.generatedFor}>{`Report Generated For`}</Text><Text style={styles.generatedForText}>Learner Name</Text></View>
                      <View style={styles.headerSection3}><Text style={styles.generatedFor}>{`History as of`}</Text><Text style={styles.generatedForText}>{lastLogin}</Text></View>
                      <View style={styles.headerSection4}><Text style={styles.generatedFor}>{`Report Generated For`}</Text><Text style={styles.generatedForText}>{historyAsOf}</Text></View>
                      <View style={styles.headerSection5}></View>
                      {/* <View style={styles.headerSection3}><Text style={styles.history}>COURSE HISTORY</Text></View> */}
              </View>
              <View style={styles.imageSection}>
              <Image src={Avicon} style={styles.logoIcon} />
              </View>
          </View>
              <View style={styles.rowhead} fixed>
                <Text style={styles.courseNameth}></Text>
                <Text style={styles.statusth}>{`Engagement\nstatus`}</Text>
                <Text style={styles.enrolledth}>{`Enrollment\ndate`}</Text>
                <Text style={styles.completedth}>{`Completion\ndate`}</Text>
                <Text style={styles.scoreth}>{`Course\nScore`}</Text>
                <Text style={styles.passfailth}>{`Completion\nstatus`}</Text>
              </View>
              {
              pdfData && pdfData?.enrollmentDetails?.map((dataArray:any,mainindex:any)=>{
                return(
                <>
                  <View style={styles.mainContent} break={mainindex > 0}  key={mainindex}>
                    {
                    dataArray && dataArray.map((data:any,index:number)=>{
                          return (
                            <>
                                <View style={styles.row2} key={index} >
                                  <Text style={styles.courseName}>{data?.luCourseName}</Text>
                                  <Text style={styles.status}>Completed</Text>
                                  <Text style={styles.enrolled}>{moment(data?.dateEnrolled).format('DD MMM YYYY')}</Text>
                                  <Text style={styles.completed}>{moment(data?.dateCompleted).format('DD MMM YYYY')}</Text>
                                  {
                                    data?.luCourseStatus ==='passed' &&
                                    <>
                                    <Text  style={styles.score}>{data?.luPercentage}%</Text>
                                    <Text style={styles.passfail}>Pass</Text>
                                    </>
                                    }
                                    {
                                    data?.luCourseStatus ==='failed' &&
                                    <>
                                    <Text style={styles.scorefail}>{data?.luPercentage}%</Text>
                                    <Text style={styles.passfailfail}>Fail</Text>
                                    </>
                                   }
                                      {
                                    data?.luCourseStatus ==='' &&
                                    <>
                                    <Text style={styles.empty}>—</Text>
                                    <Text style={styles.empty}>—</Text>
                                    </>
                                   }

                                </View>
                                <View style={styles.row3} key={index} >
                                </View>
                                
                            </>
                          )
                      })
                    }
                  </View>
                </>
                )
              })  
          
              }
        </View>
        <View fixed style={styles.footerleft}>
                <Text>{`Internal Practice Information – Be Mindful of Privacy`}</Text>
        </View>
        <View fixed style={styles.footerright}>
                <Text>{`Alchemy Vision – Copyright `+year}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default Pdf