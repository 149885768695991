import http from "./http-common";
import { AppConfig } from "../configs/app.config";
import { RegisterForm } from "../types/Register.type";
import { SingleLicenceAllocation } from "../types/licenceallocation.type";
import { Adminlogin } from "../types/User.type";

class ApiService {
  options = {
    headers: {
      Authorization: ''
    }
  }

  profile: any = null;
  thankyouemail:any = null;

  constructor() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');;
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    }

    if (localStorage.getItem('profile')) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "")
    }
  }

  login() {
    return http.get(`${AppConfig.apiBaseURL}/auth/login`);
  }

  logout() {
    return http.get(`${AppConfig.apiBaseURL}/auth/logout`);
  }

  acquireToken(code: string) {
    const request = {
      code,
    };
    return http.post(
      `${AppConfig.apiBaseURL}/auth/authorization-code/callback`,
      request
    );
  }

  getPlans(pageId: number) {
    return http.get(`${AppConfig.apiBaseURL}/plan/all?planPage=` + pageId);
  }

  getPlanSecret(
    selectedPlanObj: any,
    couponCode: String,
    medicalPracticeId: number
  ) {
    const request = {
      plans: selectedPlanObj,
      couponCode: couponCode,
      medicalPracticeId: medicalPracticeId,
    };
    return http.post(`${AppConfig.apiBaseURL}/payment/intent/create`, request);
  }

  getFaq() {
    return http.get(`${AppConfig.apiBaseURL}/faqs/all`);
  }

  register(registerData: RegisterForm) {
    return http.post(
      `${AppConfig.apiBaseURL}/medical-practice/register`,
      registerData  
    );
  }
  //done
  adminLogin(loginData: Adminlogin) {
    return http.post(`${AppConfig.apiBaseURL}/profile/login`, loginData);
  }

  getCoupons() {
    return http.get(`${AppConfig.apiBaseURL}/coupon/all`);
  }

  applyCoupons(code: String, selectedPlanObj: any) {
    const request = {
      plans: selectedPlanObj,
      resellerId: 1,
    };
    return http.post(`${AppConfig.apiBaseURL}/coupon/${code}/apply`, request);
  }

  createPayment(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/payment/create`, payload);
  }

  createPayment2(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/migration`, payload);
  }

  applyLicenceAllocation(applyLicense: SingleLicenceAllocation,isLeader:any) {
    applyLicense.inviteAsLeader = isLeader;
    return http.post(`${AppConfig.apiBaseURL}/license/assign/${this.profile.id}`, applyLicense, this.options);
  }

  getLicence() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/licenses/${this.profile.medicalPracticeId}`, this.options
    );
  }

  getAccount() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');;
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    };

    if (localStorage.getItem('profile')) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "")
    };
    
    return http.get(`${AppConfig.apiBaseURL}/profile/${this.profile?.id}`, this.options);
  }

  updateAccount(payload: any) {
    return http.put(
      `${AppConfig.apiBaseURL}/profile/${this.profile.id}`, payload, this.options
    );
  }

  getMedicalPractice() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/${this.profile.medicalPracticeId}`, this.options
    );
  }

  editMedicalPractice(practiceData: any) {
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.put(
      `${AppConfig.apiBaseURL}/medical-practice/${profile.medicalPracticeId}`,
      practiceData,this.options
    );
  }

  getsubscription() {
    return http.get(
      `${AppConfig.apiBaseURL}/subscription/${this.profile.subscriptionId}`, this.options
    );
  }

  deletesubscription() {
    return http.delete(
      `${AppConfig.apiBaseURL}/subscription/cancel/${this.profile.subscriptionId}/${this.profile.id}`, this.options
    );
  }

  subscriptionList() {
    return http.get(
      `${AppConfig.apiBaseURL}/subscription/invoices/${this.profile.id}`, this.options
    );
  }

  learnerList() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/${this.profile.medicalPracticeId}/learners`, this.options
    );
  }

  experienceLevel(planId:number) {
    return http.get(
      `${AppConfig.apiBaseURL}/experience-level/${planId}`
    );
  }
  
  deleteLicence(licenseId:number) {
    return http.delete(
      `${AppConfig.apiBaseURL}/license/remove/${licenseId}/${this.profile.id}`, this.options
    );
  }

  leaderlist() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/${this.profile.medicalPracticeId}/leaders/${this.profile.id}`, this.options
    );
  }

  addLeader(payload:any) {
    return http.post(
      `${AppConfig.apiBaseURL}/license/add/leader`, {
        ...payload,
        medicalPracticeId: this.profile.medicalPracticeId
      },
      this.options
    );
  }

  uppdateLicence(payload:any) {
    return http.post(
      `${AppConfig.apiBaseURL}/license/swipe/user/${this.profile.id}`,payload, this.options
    );
  }

  getBilling() {
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.get(`${AppConfig.apiBaseURL}/billing/${profile.medicalPracticeId}`, this.options);
  }

  editBilling(payload:any) {
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.post(`${AppConfig.apiBaseURL}/billing/${profile.medicalPracticeId}`, payload, this.options);
  }

  getPracticePlan(payload:any) {
    return http.get(`${AppConfig.apiBaseURL}/medical-practice/${this.profile.medicalPracticeId}/plans`, this.options);
  }

  editLiceseCount(payload:any) {
    return http.post(`${AppConfig.apiBaseURL}/license/manage/${this.profile.id}`, payload, this.options);
  }

  removeLeader(subscriptionAllocationId:number) {
    return http.delete(
      `${AppConfig.apiBaseURL}/licence/leader/${subscriptionAllocationId}`, this.options);
  }

  changeLicenceTire(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/license/change/tier/v2/`+this.profile.id, payload, this.options);
  }

  getExamState(){
    let payload = {
      medicalPracticeId: this.profile?.medicalPracticeId
    }
    return http.post(`${AppConfig.apiBaseURL}/learnupon/users/avg/exam/score`, payload, this.options);
  }
  insertContact(payload:any){
    return http.post(
      `${AppConfig.apiBaseURL}/enterprise/contact-us`,payload); 
  }

  registerProfile(payload: any){
    return http.post(`${AppConfig.apiBaseURL}/auth/register`, payload); 
  }

  getLoginState(){  
    let payload = {
      medicalPracticeId: this.profile?.medicalPracticeId
    };

    return http.post(`${AppConfig.apiBaseURL}/learnupon/users/activity`, payload, this.options);
  }

  editCreditCard(){  
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    let payload = {
      medicalPracticeId: profile?.medicalPracticeId
    }
    return http.get(
      `${AppConfig.apiBaseURL}/billing/setup/intent/`+payload.medicalPracticeId);
  }


  getStripeIntent(medicalPracticeId:number){
    let payload = {
      medicalPracticeId: medicalPracticeId
    }
    return http.get(
      `${AppConfig.apiBaseURL}/migration/intent/`+payload.medicalPracticeId);
  }
  sendEmail(payload:any){
    let setPayload = {
      licenseAllocationId: payload?.licenseAllocationId,
      leaderId: this.profile.id,
      roleId: payload.roleId
    }
    return http.post(
       `${AppConfig.apiBaseURL}/license/resend/invite`,setPayload,this.options);
  }

  getLearners(){  
    return http.get(`${AppConfig.apiBaseURL}/medical-practice/3/users`);
  }

  getActiveMedicalPractices(){  
    return http.get(`${AppConfig.apiBaseURL}/medical-practice/active/practices`);
  }

  changePassword(payload:any){
    return http.post(`${AppConfig.apiBaseURL}/auth/reset-password`,payload,this.options);
  }
  sendReport(){  
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.get(`${AppConfig.apiBaseURL}/learnupon/users/generate/report/`+profile?.medicalPracticeId, this.options);
  }

  getReport(){  
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.get(`${AppConfig.apiBaseURL}/learnupon/users/reporting/`+profile?.medicalPracticeId, this.options);
  }

  getFeatures(){  
    return http.get(`${AppConfig.apiBaseURL}/features`);
  }

  createFreeTrial(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/payment/create/free/trail`, payload);
  }

  getSubscriptionType() {
    return http.get(`${AppConfig.apiBaseURL}/subscription/${this.profile.subscriptionId}/type`, this.options);
  }

  learnerLogin() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');;
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    };

    return http.get(`${AppConfig.apiBaseURL}/auth/learner/login`, this.options);
  }

  generatePdfData(luid:number){
    return http.get(`${AppConfig.apiBaseURL}/learnupon/users/course/enrollment/details/`+luid, this.options);
  }
  
}

export default new ApiService();
