import * as actionTypes from "./actions";
import { subscriptionState } from "../../types/Subscription.type";

const initialState: subscriptionState = {
  subscription: {
    loading: false,
    data: [],
    error: null,
  },
  deletesubscription: {
    loading: false,
    data: [],
    error: null,
  },
  subscriptionlist: {
    loading: false,
    data: [],
    error: null,
  },
  subscriptionType: {
    loading: true,
    isTrial: true,
  }
};

const subscriptionReducer = (
  state: subscriptionState = initialState,
  action: any
): subscriptionState => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          loading: true,
        },
      };

    case actionTypes.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.GET_SUBSCRIPTION_FAILED:
      return {
        ...state,
        subscription: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.DELETE_SUBSCRIPTION:
      return {
        ...state,
        deletesubscription: {
          loading: true,
        },
      };

    case actionTypes.DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deletesubscription: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.DELETE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        deletesubscription: {
          error: action.error,
          loading: false,
        },
      };

    case actionTypes.RESET_DELETESUBSCRIPTION:
      return {
        ...state,
        deletesubscription: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case actionTypes.LIST_SUBSCRIPTION:
      return {
        ...state,
        subscriptionlist: {
          loading: true,
        },
      };

    case actionTypes.LIST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionlist: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.LIST_SUBSCRIPTION_FAILED:
      return {
        ...state,
        subscriptionlist: {
          error: action.error,
          loading: false,
        },
      };

    case actionTypes.SET_SUBSCRIPTION_TYPE:
      return {
        ...state,
        subscriptionType: {
          error: action.error,
          loading: false,
          isTrial: false
        },
      };

    case actionTypes.SET_SUBSCRIPTION_TYPE_SUCCESS:
      return {
        ...state,
        subscriptionType: {
          error: action.error,
          loading: false,
          isTrial: action?.data?.subscription?.freeTrial
        },
      };

    case actionTypes.SET_SUBSCRIPTION_TYPE_FAILED:
      return {
        ...state,
        subscriptionType: {
          error: action.error,
          loading: false,
          isTrial: false
        },
      };
  }
  return state;
};

export default subscriptionReducer;
