/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Col from "react-bootstrap/Col";
import parse from "html-react-parser";

function Chooseplan({
  plan,
  currentLicence = false,
  reset,
  active,
  learnerId,
}: any) {
  const styleForTitle = {color:plan?.colorConfig?.changeTier?.titleColor}
  return (
    <>
      <Col
        md={4}
        xs={12}
        className="mt-4"
        onClick={() => {
          reset(plan, learnerId);
        }}
      >
        <div
          className="licence-box onboarding"
          style={{
            background: currentLicence
              ? "#DDDFD2"
              : active
              ? plan.colorConfig.changeTier.activeColor
              : plan.colorConfig.changeTier.inactiveColor,
            color: currentLicence ? "#787A6D" : "#fff",
            minHeight: '460px'
          }}
        >
           {currentLicence === true ? 
          <img src={plan?.secondaryIcon} alt="peoplecircle" />
          :
          <img src={plan?.smallIcon} alt="peoplecircle" />
           }
          <br />
          {currentLicence === false ? (
          <div className="mt-1">
            <span className="title" style={styleForTitle}>{plan?.name.toUpperCase()} </span>
          </div>
          ):(
            <div className="mt-1">
            <span className="title" style={{color:'#787A6D'}}>{plan?.name.toUpperCase()} </span>
            </div>
          )}
          <div className="mt-1">
            {plan?.availableSeats > 0 ? (
              <span className="price">
                {plan?.availableSeats} seats available{" "}
              </span>
            ) : (
              <span className="price">${plan?.monthlyPrice}/mo </span>
            )}
          </div>
          {currentLicence === true ? (
            <div className="mt-1">
              <button className="licence-btn">Current License</button>
            </div>
          ) : (
            <div className="mt-5"></div>
          )}
          {plan?.activeSelect === true && !currentLicence && (
            <>
              <div className="minus-marge-mt5">
                <button className="update-btn-licence">Updated License</button>
              </div>
            </>
          )}
          <div className="mt-3 mb-4">
           {currentLicence === false ? 
            <span className="txt" style={{color:'#F4F5EE'}}>{parse(plan?.subtext)}</span>
            :
            <span className="txt">{parse(plan?.subtext)}</span>
           }
            </div>
          <div className="mb-5">
           {currentLicence === false ? 
            <span className="txt" style={{color:'#F4F5EE'}}>{parse(plan?.subtitle)}</span>
            :
            <span className="txt">{parse(plan?.subtitle)}</span>
           }
          </div>
        </div>
      </Col>
    </>
  );
}

export default Chooseplan;
