/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAccountState,
  GetEditAccountState,
  GetEditPasswordState
} from "../../types/Myaccount.type";
import { getAccount, editAccount , resetPassword } from "../../store/myaccount/effect";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { RESET_ACCOUNTEDIT,RESET_PASSWORD_RESET } from "../../store/myaccount/actions";
import Grow from "../loader/Grow";
import Spinner from "react-bootstrap/Spinner";
import Rightcheck from "../../assets/images/rightcheck.svg";
import PhoneInput from "react-phone-input-2";
import Profileicon from "../../../src/assets/images/icon.png";
import Modal from "react-bootstrap/Modal";

function Myaccountbox() {
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [show6, setShow6] = useState(false);
  const [editData, setEditData]: any = useState({
    name: "",
    surname: "",
    email: "",
    contactNumber: "",
    certifications: "",
    jobTitle: "",
  });
  const [editPasswordData, setEditPasswordData]: any = useState({
    newPassword: "",
    confirmPassword: ""
  });
  
  const getFileExtension = (filename: any) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
  };
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
      },
      validators: {
        certificate: {
          message: "Please Enter Valid Value",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^[A-Za-z,]+$");
            return regex.test(val);
          },
        },
        image: {
          message: "Please Select Valid Image",
          rule: (val: any, params: any): any => {
            const availableExtention = ["png", "jpeg", "svg", "jpg"];
            if (availableExtention.indexOf(getFileExtension(val.name)) !== -1) {
              return true;
            } else {
              return false;
            }
          },
        },
      },
    })
  );
  const passwordValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
        in:'Oops! Your new passwords don’t match. Let’s try that again.'
      },
      
      validators: {
        regexpassword: {
          message: "The password rules above were not met. Let’s try that again!",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^(?=.*[0-9])(?=.*[A-Z])(?=.*[@$!%*#?&])[a-zA-Z0-9!@#$%^&*]{8,16}$");
            return regex.test(val);
          },
        },
        image: {
          message: "Please Select Valid Image",
          rule: (val: any, params: any): any => {
            const availableExtention = ["png", "jpeg", "svg", "jpg"];
            if (availableExtention.indexOf(getFileExtension(val.name)) !== -1) {
              return true;
            } else {
              return false;
            }
          },
        },
      },
    })
  );

  function changePassword(event:any) {
      setValidated(true);
      event.preventDefault();
      passwordValidator.current.showMessages();
      const formValid = passwordValidator.current.allValid();
      if (!formValid) {
        passwordValidator.current.showMessages();
        forceUpdate();
      } else {
        dispatch(resetPassword(editPasswordData));
      }
  }
                                     
  const handleShow6 = (e:any) => {
    e.preventDefault();
    setSuccess(false);
    setShow6(true);
  };

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    else{
      return phoneNumberString;
    }
  }

  function useForceUpdate() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  }
  const forceUpdate = useForceUpdate();
  const cancelSave = () => {
    setEdit(false);
    setImage(displayImage);
    simpleValidator.current.hideMessages();
    simpleValidator.current.hideMessageFor("profilePicture");
    simpleValidator.current.hideMessageFor("name");
    simpleValidator.current.hideMessageFor("surname");
    simpleValidator.current.hideMessageFor("email");
    simpleValidator.current.hideMessageFor("contactNumber");
    simpleValidator.current.hideMessageFor("certifications");
    simpleValidator.current.hideMessageFor("jobTitle");
    setEditData({
      ...editData,
      ...{
        name: accountState?.data?.profile?.name,
        surname: accountState?.data?.profile?.surname,
        email: accountState?.data?.profile?.email,
        contactNumber: accountState?.data?.profile?.contactNumber,
        certifications: accountState?.data?.profile?.certifications,
        jobTitle: accountState?.data?.profile?.jobTitle,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const [passwordSuccess,setPasswordSuccess] = useState(false)

  const updateDetails = () => {
    if (edit === false) {
      setSuccess(false);
      simpleValidator.current.hideMessages();
      setEdit(true);
    } else {
      setEdit(false);
    }
  };

  const accountState = useSelector(
    (state: GetAccountState) => state.accountState.myaccount
  );
  const editAccountState = useSelector(
    (state: GetEditAccountState) => state.accountState.editAccount
  );
  const editPasswordState = useSelector(
    (state: GetEditPasswordState) => state.accountState.changePassword
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);
  const [image, setImage] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  React.useEffect(() => {
    setEditData({
      ...editData,
      ...{
        name: accountState?.data?.profile?.name,
        surname: accountState?.data?.profile?.surname,
        email: accountState?.data?.profile?.email,
        contactNumber: accountState?.data?.profile?.contactNumber,
        certifications: accountState?.data?.profile?.certifications,
        jobTitle: accountState?.data?.profile?.jobTitle,
      },
    });
    setImage(accountState?.data?.profile?.profilePicture);
    setDisplayImage(accountState?.data?.profile?.profilePicture);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountState.data]);
  React.useEffect(() => {
    if (editAccountState?.data?.success === true) {
      setSuccess(true);
      dispatch({ type: RESET_ACCOUNTEDIT });
      dispatch(getAccount());
      setEdit(false);
    }
    if (editAccountState?.error?.statusText) {
      toast.error(editAccountState?.error?.statusText, {
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAccountState]);

  React.useEffect(() => {
    if (editAccountState?.data?.success === true) {
      setSuccess(true);
      dispatch({ type: RESET_ACCOUNTEDIT });
      dispatch(getAccount());
      setEdit(false);
    }
    if (editAccountState?.error?.statusText) {
      toast.error(editAccountState?.error?.statusText, {
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAccountState]);

    React.useEffect(() => {
    if (editPasswordState?.data?.success === true) {
      setPasswordSuccess(true);  
      dispatch({ type: RESET_PASSWORD_RESET });
      // setEdit(false);
      handleClose6()
    }
    if (editPasswordState?.error?.statusText) {
      toast.error(editPasswordState?.error?.statusText, {
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPasswordState]);

  const updateValue = (event: any) => {
    const { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setEditData({ ...editData, [name]: value });
  };

  const updateValuePassword = (event: any) => {
  
    const { name, value } = event.target;
    setEditPasswordData({ ...editPasswordData, [name]: value });
  };

  const updateAccount = (event: any) => {
    setValidated(true);
    event.preventDefault();
    simpleValidator.current.showMessages();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(editAccount(editData));
    }
  };

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    simpleValidator.current.showMessageFor("profilePicture");
    setEditData({
      ...editData,
      ...{
        profilePicture: event.target.files[0],
      },
    });
  };

  const handleClose6 = () => {
    setShow6(false);
    setEditPasswordData({
      ...editPasswordData,
      ...{
        newPassword: "",
        confirmPassword: ""
      },
    });
    passwordValidator.current.hideMessages();
    passwordValidator.current.hideMessageFor("newPassword");
    passwordValidator.current.hideMessageFor("confirmPassword");
  };

  let surname = accountState?.data?.profile?.surname
    ? accountState?.data?.profile?.surname
    : "";

  return (
    <>
        <Modal show={show6} onHide={handleClose6} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row>
            <Col md={9}>
              <b style={{fontSize:'21px'}}>To keep your account safe, you’ll need to use a minimum of 8 characters, with at least one capital letter, one special character, and one number.</b>
            </Col>
          </Row>
          {/* <Row className="gx-5 pt-5  ">
            <Col md={6}>
              <label htmlFor="current-password" className="learner-label">
                Current Password*
              </label>
              <br />
              <input
                type="password"
                id="current-password"
                name="currentPassword"
                placeholder='Enter Current Password'
                value={editPasswordData?.password}
                onChange={(e) => {
                  updateValuePassword(e);
                }}
                className="form-control mt-1 no-radious"
              />
              {passwordValidator.current.message(
                "currentPassword",
                editPasswordData.currentPassword,
                "required"
              )}
            </Col>
          </Row> */}
          <br/>
          <Row className="gx-5 justify-content-center pb-5 justify-content-center">
            <Col md={6}>
              <label htmlFor="newPassword" className="learner-label">
               New Password* 
              </label>
              <br />
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder="Enter New Password"
                value={editPasswordData?.newPassword}
                className="form-control mt-1 no-radious"
                onChange={(e) => {
                  updateValuePassword(e);
                }}
              />
              <br/>
              {passwordValidator.current.message(
                "newPassword",
                editPasswordData.newPassword,
                "required|regexpassword"
              )}
            </Col>
            <Col md={6}>
              <label htmlFor="confirmPassword" className="learner-label">
               Confirm New Password*
              </label>
              <br />
              <input
                type="password"
                className="form-control mt-1 no-radious"
                placeholder="Enter Confirm Password"
                id='confirmPassword'
                name="confirmPassword"
                value={editPasswordData?.confirmPassword}
                onChange={(e) => {
                  updateValuePassword(e);
                }}
              />
              <br/>
              {passwordValidator.current.message(
                "confirmPassword",
                editPasswordData.confirmPassword,
                `required|in:${editPasswordData.newPassword}`
              )}
            </Col>
          </Row>
                
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleClose6();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              className="assign-btn"
              onClick={(e)=>{
                changePassword(e)
              }}
              disabled={editPasswordState.loading}
            >
               {editPasswordState.loading && (
                    <Spinner className="loader" animation="border" />
                  )}
              Update My Password
            </button>
          </Col>
        </Row>
      </Modal>
      <Row>
        <Col md={12} style={{ padding: "25px 10px" }}>
          <div className="common-box">
            <form
              noValidate
              onSubmit={(e) => {
                updateAccount(e);
              }}
            >
              <div className="partition practice-color">
                <Row>
                  <Col md={3}>
                    <span className="common-text">My Account</span>
                    <br />
                  </Col>
                  <Col md={9} className="text-right">
                    {success ? (
                      <>
                        <img src={Rightcheck} alt="right check" />
                        <span className="common-success-text">
                          &nbsp;&nbsp;Your updated details have been saved
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {passwordSuccess ? (
                      <>
                        <img src={Rightcheck} alt="right check" />
                        <span className="common-success-text">
                          &nbsp;&nbsp;Your new password has been saved
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    
                    
                    <br />
                  </Col>
                </Row>
              </div>
              {!accountState.loading ? (
                <>
                  <div className="partition-first mt-5">
                    <Row className="gx-5">
                      <Col md={3} className="text-center">
                        <div className="intro-inside">
                          {edit === true ? (
                            <>
                              <div className="image-upload">
                                <label htmlFor="file-input">
                                  {
                                    image !== null ? (
                                      <img
                                      src={image}
                                      alt="profile"
                                      className="profile-image1"
                                      height="150px"
                                      width="150px"
                                      id="output"
                                    />
                                    ):
                                    (
                                      <img
                                      src={Profileicon}
                                      alt="profile"
                                      className="profile-image1"
                                      height="150px"
                                      width="150px"
                                      id="output"
                                    />
                                    )
                                  }
                                </label>
                                <input
                                  id="file-input"
                                  type="file"
                                  name="profilePicture"
                                  onChange={(e) => onImageChange(e)}
                                />
                              </div>
                              {simpleValidator.current.message(
                                "profilePicture",
                                editData.profilePicture,
                                "image"
                              )}
                            </>
                          ) : (
                            <>
                              {
                                displayImage !== null ? (
                                  <img
                                  src={displayImage}
                                  alt="profile"
                                  className="profile-image1"
                                  height="150px"
                                  width="150px"
                                />
                                ) : (
                                  <img
                                  src={Profileicon}
                                  alt="profile"
                                  className="profile-image1"
                                  height="150px"
                                  width="150px"
                                />
                                )
                              }
                            
                            </>
                          )}
                        </div>
                      </Col>
                      <Col md={5} className="mobile-margin-top">
                        {edit === true ? (
                          <>
                            <label htmlFor="name" className="label">
                              Name
                            </label>
                            <br />
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={editData?.name}
                              onChange={(e) => {
                                updateValue(e);
                              }}
                              className="form-control no-radious "
                            />
                            {simpleValidator.current.message(
                              "name",
                              editData.name,
                              "required|max:100"
                            )}
                            <br />
                          </>
                        ) : (
                          <>
                            <Title title="My Name" />
                            <br />
                            <Subtitle
                              subtitle={
                                accountState?.data?.profile?.name +
                                ` ` +
                                surname
                              }
                            />
                            <br />
                          </>
                        )}
                      </Col>
                      <Col md={4} className="mobile-margin-top">
                        {edit === true ? (
                          <>
                            <label htmlFor="surname" className="label">
                              Surname
                            </label>
                            <br />
                            <input
                              type="text"
                              id="surname"
                              name="surname"
                              value={editData?.surname}
                              onChange={(e) => {
                                updateValue(e);
                              }}
                              className="form-control no-radious-last"
                            />
                            {simpleValidator.current.message(
                              "surname",
                              editData.surname,
                              "required|max:100"
                            )}
                            <br />
                          </>
                        ) : (
                          <>
                            <Title title="My Email" />
                            <br />
                            <Subtitle
                              subtitle={accountState?.data?.profile?.email}
                            />
                            <br />
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="partition-second">
                    <Row className="gx-5">
                      <Col md={3} className="mobile-margin-top"></Col>
                      <Col md={5} className="mt-xs-5 mobile-margin-top">
                        {edit === true ? (
                          <>
                            <label htmlFor="email" className="label">
                              Email{" "}
                              <span className="disable">
                                (this cannot be changed)
                              </span>
                            </label>
                            <br />
                            <input
                              type="text"
                              disabled
                              id="email"
                              name="email"
                              value={editData?.email}
                              onChange={(e) => {
                                updateValue(e);
                              }}
                              className="form-control no-radious disable"
                            />
                            {simpleValidator.current.message(
                              "email",
                              editData.email,
                              "required|email|max:100"
                            )}
                            <br />
                          </>
                        ) : (
                          <>
                            <Title title="My Contact Number" />
                            <br />
                            <div style={{ width: "60%" }}>
                              {
                                accountState?.data?.profile?.contactNumber  ?
                                (
                                  <Subtitle
                                    subtitle={formatPhoneNumber(
                                      `+` +
                                        accountState?.data?.profile?.contactNumber
                                    )}
                                  />
                                ) :
                                <Subtitle
                                    subtitle='No number added'
                                  />
                              }
                            </div>
                          </>
                        )}
                      </Col>
                      <Col md={4} className="mobile-margin-top">
                        {edit === true ? (
                          <>
                            <label htmlFor="contactNumber" className="label">
                              Contact Number
                            </label>

                            <PhoneInput
                              country={"us"}
                              disableDropdown={true}
                              inputProps={{
                                id: "contactNumber",
                                name: "contactNumber",
                                className: "form-control mt-1 no-radious",
                              }}
                              value={editData?.contactNumber}
                              onChange={(e) =>
                                updateValue({
                                  target: {
                                    name: "contactNumber",
                                    value: e,
                                  },
                                })
                              }
                              countryCodeEditable={false}
                            />
                            {simpleValidator.current.message(
                              "contactNumber",
                              editData.contactNumber,
                              "required|max:15|min:11"
                            )}
                            <br />
                          </>
                        ) : (
                          <>
                            <Title title="Job Title" />
                            <br />
                            <Subtitle
                              subtitle={accountState?.data?.profile?.jobTitle}
                            />
                            <br />
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="partition mb-5">
                    <Row className="gx-5">
                      <Col md={3} className="no-display-mobile"></Col>
                      <Col md={5}>
                        {edit === true ? (
                          <>
                            <label htmlFor="contactNumber" className="label">
                              Job Title
                            </label>
                            <br />
                            <input
                              type="text"
                              id="jobTitle"
                              name="jobTitle"
                              value={editData?.jobTitle}
                              onChange={(e) => {
                                updateValue(e);
                              }}
                              className="form-control no-radious"
                            />
                            {simpleValidator.current.message(
                              "jobTitle",
                              editData.jobTitle,
                              "required|max:100"
                            )}
                            <br />
                          </>
                        ) : (
                          <>
                            <Title title="Certification" />
                            <br />
                            <Subtitle
                              subtitle={
                                accountState?.data?.profile?.certifications
                              }
                            />
                            <br />
                          </>
                        )}
                      </Col>
                      {edit === false && (
                        <>
                        <Col md={4}>
                            <label htmlFor="certifications" className="label">
                              Password
                            </label><br/>
                            <a href='#' onClick={(e)=>handleShow6(e)} className='change-password'>Change Password</a>
                        </Col>
                        </>
                      )
                      }
                      <Col md={4} className="mobile-margin-top">
                        {edit === true ? (
                          <>
                            <label htmlFor="certifications" className="label">
                              Certifications
                            </label>
                            <br />
                            <input
                              type="text"
                              id="certifications"
                              name="certifications"
                              value={editData?.certifications}
                              onChange={(e) => {
                                updateValue(e);
                              }}
                              className="form-control no-radious-last"
                            />
                            {simpleValidator.current.message(
                              "certifications",
                              editData.certifications,
                              "required|max:100"
                            )}
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div className="mt-5 mb-5">
                  <Grow />
                </div>
              )}
            </form>
            <div className="partition-last">
              {edit === false ? (
                <Row className="gx-5 update-btn-border">
                  <Col md={12} className="text-center main-div-last">
                    <button
                      type="button"
                      onClick={() => {
                        updateDetails();
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        marginTop: "10px",
                        color: "#000",
                        fontWeight: 500,
                      }}
                    >
                      Update My Details
                    </button>
                  </Col>
                </Row>
              ) : (
                <Row className="gx-5 update-btn-border">
                  <Col md={6} className="text-center main-div-last">
                    <button
                      type="button"
                      onClick={() => {
                        cancelSave();
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        marginTop: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                  <Col
                    md={6}
                    className="text-center main-div-last save-my-details-div"
                    style={{ background: "#97998d" }}
                  >
                    <button
                      type="button"
                      onClick={(e) => {
                        updateAccount(e);
                      }}
                      className="save-my-details"
                      style={{
                        border: "none",
                        background: "none",
                        marginTop: "10px",
                        fontWeight: 500,
                      }}
                      disabled={editAccountState.loading}
                    >
                      {editAccountState.loading && (
                        <Spinner className="loader" animation="border" />
                      )}
                      Save My Details
                    </button>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Myaccountbox;
