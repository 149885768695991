import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./Managelearner.scss";
import Profileicon from "../../../src/assets/images/icon.png";
import Addicon from "../../../src/assets/images/addicon.svg";
import Modal from "react-bootstrap/Modal";
import Learner from "../../assets/images/learner.png";
import Exclamation from "../../../src/assets/images/exclamation.svg";
import Bigprofile from "../../../src/assets/images/bigprofile.svg";
import Peoplecircle from "../../../src/assets/images/peoplecircle.svg";
import Rocket from "../../../src/assets/images/rocket.svg";
import Training from "../../../src/assets/images/training.svg";
import Leader from "../../../src/assets/images/Leader.svg";
import Laura from "../../../src/assets/images/laura.svg";
import Manageicon from "../../../src/assets/images/ManageIcon.png";
import Minusicon from "../../../src/assets/images/minusicon.svg";
import Thankyou from "../../../src/assets/images/thankyou.svg";

function Managelearner() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  //model 1
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //model 2
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //model 3
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //model 4
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //model 5
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //model 6
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  //model 7
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  return (
    <>
      <div className="manage-learner">
        <Button variant="primary" onClick={handleShow}>
          Add learner
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;
        <Button variant="primary" onClick={handleShow2}>
          Add learner 2
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button variant="primary" onClick={handleShow3}>
          Assign Learner
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button variant="primary" onClick={handleShow4}>
          Change License Tier
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button variant="primary" onClick={handleShow5}>
          Add Leader
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button variant="primary" onClick={handleShow6}>
          Manage Leader
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button variant="primary" onClick={handleShow7}>
          Thank you&nbsp;&nbsp;&nbsp;
        </Button>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Manage Learners</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <img src={Learner} alt="learner" />{" "}
            <span className="new-learner">ADD A NEW LEARNER</span>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value="Heman"
                />
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Surname</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value="Sayls"
                />
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <input
                  type="email"
                  className="form-control mt-1 no-radious"
                  value="hsayls@practice.org"
                />
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">License Tier</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">Team Training</span>
                  &nbsp;&nbsp;
                  <img src={Exclamation} alt="Exclamation" className="mb-2" />
                </div>
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">
                  Learner Experience Level
                </label>
                <br />
                <input type="checkbox" name="Beginner" className="mt-3" />
                &nbsp;&nbsp;
                <span className="checkbox-class">
                  {" "}
                  Beginner (0-1 years experience){" "}
                </span>
              </Col>
              <Col md={6} xs={12}>
                <br />
                <input type="checkbox" name="Beginner" className="mt-3" />
                &nbsp;&nbsp;
                <span className="checkbox-class">
                  {" "}
                  Semi Experienced (1+ years experience){" "}
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <p className="add-learner-extra">
                This learner will be emailed so that they can continue setting
                up their account.
                <br />
                The experience level you choose above will determine the
                learning path on which they’ll start.
              </p>
            </Row>
          </Modal.Body>
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  handleClose();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            <Col md={6} xs={12} className="footer-section-2 text-center">
              <button className="assign-btn">
                Assign Seat to this Learner
              </button>
            </Col>
          </Row>
        </Modal>
        <Modal show={show2} onHide={handleClose2} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Manage Learner</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <Row className="mt-4 gx-5">
              <Col md={3} xs={12}>
                <img src={Bigprofile} alt="profile-img" />
              </Col>
              <Col md={4} xs={12}>
                <label className="learner-label">Name</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">Akura Sato</span>
                  &nbsp;&nbsp;
                </div>
              </Col>
              <Col md={4} xs={12}>
                <label className="learner-label">Email</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">
                    asato@practice.org
                  </span>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>
            <Row className="gx-5">
              <Col md={3} xs={12}></Col>
              <Col md={4} xs={12}>
                <label className="learner-label">License Tier</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">Onboarding</span>
                  &nbsp;&nbsp;
                  <img src={Exclamation} alt="Exclamation" className="mb-2" />
                  <br />
                  <button className="change-license-tier mt-2">
                    Change License Tier
                  </button>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <label className="learner-label">Job Title</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">Senior Technician</span>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>

            <Row className="mt-5">
              <p className="add-learner-extra">
                By clicking “Change License Tier” above, you can upgrade or
                downgrade this learner’s license (your subscription price will
                be automagically updated). If you’d rather keep the license tier
                as it is, but assign it to another team member, click “Assign
                Seat to New Learner” below.
              </p>
            </Row>
          </Modal.Body>
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  handleClose2();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            <Col md={6} xs={12} className="footer-section-2 text-center">
              <button className="assign-btn">
                Assign Seat to this Learner
              </button>
            </Col>
          </Row>
        </Modal>
        <Modal show={show3} onHide={handleClose3} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Assign New Learner</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12} className="border-right-custom">
                <br />
                <span className="new-learner">CURRENT LEARNER INFORMATION</span>
                <Row>
                  <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                    <label className="learner-label">Name</label>
                    <br />
                    <div className="mt-2">
                      <span className="team-training-text ">Akura Sato</span>
                      &nbsp;&nbsp;
                    </div>
                  </Col>
                  <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                    <label className="learner-label">Email</label>
                    <br />
                    <div className="mt-2">
                      <span className="team-training-text ">
                        asato@practice.org
                      </span>
                      &nbsp;&nbsp;
                    </div>
                  </Col>
                  <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                    <label className="learner-label">License Tier</label>
                    <br />
                    <div className="mt-2">
                      <span className="team-training-text ">Onboarding</span>
                      &nbsp;&nbsp;
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6} xs={12}>
                <br />
                <span className="new-learner">NEW LEARNER INFORMATION</span>
                <Row>
                  <Col
                    md={12}
                    className="mt-4"
                    xs={12}
                    style={{ marginLeft: "20px" }}
                  >
                    <label className="learner-label">Name</label>
                    <input
                      type="text"
                      className="form-control mt-1 no-radious"
                      placeholder="Marcus"
                    />
                  </Col>
                  <Col
                    md={12}
                    className="mt-4"
                    xs={12}
                    style={{ marginLeft: "20px" }}
                  >
                    <label className="learner-label">Surname</label>
                    <input
                      type="text"
                      className="form-control mt-1 no-radious"
                      placeholder="Aurelius"
                    />
                  </Col>
                  <Col
                    md={12}
                    className="mt-4"
                    xs={12}
                    style={{ marginLeft: "20px" }}
                  >
                    <label className="learner-label">Email</label>
                    <input
                      type="text"
                      className="form-control mt-1 no-radious"
                      placeholder="lorem@ipsum.io"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-5">
              <p className="add-learner-extra">
                The current learner will be notified of their account
                suspension, the new learner will be notified
                <br /> about setting up their account, and there will be no
                changes to your subscription licenses or price.
              </p>
            </Row>
          </Modal.Body>
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  handleClose3();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            <Col md={6} xs={12} className="footer-section-2 text-center">
              <button className="assign-btn">
                Assign Seat to this Learner
              </button>
            </Col>
          </Row>
        </Modal>
        <Modal show={show4} onHide={handleClose4} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Assign New Learner</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <Row className="mt-4 gx-5">
              <br />
              <span className="new-learner">
                CHOOSE A LICENSE TIER FOR AKURA SATO...
              </span>
              <Row>
                <Col md={4} xs={12} className="mt-4">
                  <div className="onboarding licence-box">
                    <img src={Peoplecircle} alt="peoplecircle" />
                    <br />
                    <div className="mt-1">
                      <span className="title"> ONBOARDING </span>
                    </div>
                    <div className="mt-1">
                      <span className="price"> $39/mo </span>
                    </div>
                    <div className="mt-1">
                      <button className="licence-btn">Current License</button>
                    </div>
                    <div className="mt-3 mb-5">
                      <span className="txt">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={4} xs={12} className="mt-4">
                  <div className="team-training licence-box">
                    <img src={Training} alt="peoplecircle" />
                    <br />
                    <div className="mt-1">
                      <span className="title"> TEAM TRAINING </span>
                    </div>
                    <div className="mt-1">
                      <span className="price"> $59/mo </span>
                    </div>
                    <div className="mt-3">
                      {/* <button className='licence-btn'>Current License</button> */}
                    </div>
                    <div className="mt-5 mb-5">
                      <span className="txt">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={4} xs={12} className="mt-4">
                  <div className="revenue-growth licence-box">
                    <img src={Rocket} alt="peoplecircle" />
                    <br />
                    <div className="mt-1">
                      <span className="title"> REVENUE GROWTH </span>
                    </div>
                    <div className="mt-1">
                      <span className="price"> $99/mo </span>
                    </div>
                    <div className="mt-1"></div>
                    <div className="mt-5 mb-5">
                      <span className="txt">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="mt-5">
              <p className="add-learner-extra">
                Your subscription price will adjusted and pro-rated for your
                next billing cycle.
              </p>
            </Row>
          </Modal.Body>
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  handleClose4();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            <Col md={6} xs={12} className="footer-section-2 text-center">
              <button className="assign-btn">
                Assign Seat to this Learner
              </button>
            </Col>
          </Row>
        </Modal>
        <Modal show={show5} onHide={handleClose5} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Manage Leaders</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <img src={Leader} alt="learner" />{" "}
            <span className="new-learner">ADD A NEW LEADER</span>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value="Heman"
                />
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Surname</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value="Sayls"
                />
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <input
                  type="email"
                  className="form-control mt-1 no-radious"
                  value="hsayls@practice.org"
                />
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Job Title</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value="Shift Manager"
                />
              </Col>
            </Row>
            <br />
            <br />
            <Row className="mt-4">
              <p className="add-learner-extra">
                This leader will be emailed of their account creation, so they
                can continue setting up their account. They will have the same
                administrative privileges as you, including the ability to:
                <br />
                <b>
                  manage learners, your Alchemy Vision subscription, your
                  practice information, and payment type
                </b>
              </p>
            </Row>
          </Modal.Body>
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  handleClose5();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            <Col md={6} xs={12} className="footer-section-2 text-center">
              <button className="assign-btn">
                Assign Seat to this Learner
              </button>
            </Col>
          </Row>
        </Modal>
        <Modal show={show6} onHide={handleClose6} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Manage Leaders</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <Row className="mt-4 gx-5">
              <Col md={12} xs={12}>
                <table className="table table-borderless responsive">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Job Title</th>
                      <th>Last Login</th>
                    </tr>
                  </thead>
                  <tbody className="border-top-custom ">
                    <br />
                    <tr>
                      <td>
                        <img src={Laura} alt="profileicon" />
                      </td>
                      <td>
                        <span className="table-name">Laura Williams</span>
                      </td>
                      <td className="licence-text">Practice Administrator</td>
                      <td className="last-login-date">17 Apr 2023</td>
                      <td>
                        <img src={Manageicon} alt="profileicon" />
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>
                        <img src={Profileicon} alt="profileicon" />
                      </td>
                      <td>
                        <span className="table-name">Mary Stanza</span>
                      </td>
                      <td className="licence-text">Practice Administrator</td>
                      <td className="last-login-date">16 Apr 2023</td>
                      <td>
                        <img src={Minusicon} alt="profileicon" />
                      </td>
                    </tr>
                    <br />
                  </tbody>
                </table>
              </Col>
            </Row>
          </Modal.Body>
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  handleClose6();
                }}
                className="cancel-btn"
              >
                Back to Manage Team
              </button>
            </Col>
            <Col md={6} xs={12} className="footer-section-2 text-center">
              <button className="assign-btn">Add a New Leader</button>
            </Col>
          </Row>
        </Modal>
        <Modal show={show7} onHide={handleClose7} size="xl">
          <Modal.Header className="model-heading-custom" closeButton>
            <Modal.Title>Team Progress</Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-body-custom">
            <Row className="mt-4 gx-5">
              <Col md={6} xs={6} className="text-center mb-5">
                <img src={Thankyou} alt="thankyou" />
              </Col>
              <Col md={6} xs={6} style={{ verticalAlign: "middle" }}>
                <span>Check Your Email In a Few Minutes</span>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <section className="section-practice">
          <Container>
            <Col md={12} id="manage-team">
              <div className="common-box">
                <div className="partition practice-color ">
                  <Row>
                    <Col md={3}>
                      <span className="common-text">Manage Learners</span>
                      <br />
                    </Col>
                  </Row>
                </div>
                <div className="partition">
                  <div className="table-responsive-lg">
                    <table className="table table-borderless responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th></th>
                          <th>License Tier</th>
                          <th>Last Login</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="border-top-table">
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Onboarding</td>
                          <td className="last-login-date">29 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Onboarding</td>
                          <td className="last-login-date">18 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Onboarding</td>
                          <td className="last-login-date">21 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Team Training</td>
                          <td className="last-login-date">29 Feb 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Team Training</td>
                          <td className="last-login-date">12 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Team Training</td>
                          <td className="last-login-date">27 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Team Training</td>
                          <td className="last-login-date">22 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Team Training</td>
                          <td className="last-login-date">5 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Team Training</td>
                          <td className="last-login-date">09 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <img src={Profileicon} alt="profileicon" />
                          </td>
                          <td>
                            <span className="table-name">
                              Unassigned Learner
                            </span>
                          </td>
                          <td className="licence-text">Revenue Growth</td>
                          <td className="last-login-date">29 Mar 2023</td>
                          <td>
                            <img src={Addicon} alt="profileicon" />
                          </td>
                        </tr>
                        <br />
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="partition-last">
                  <Row className="gx-5 update-btn-border">
                    <Col md={6} className="text-center main-div-last">
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          marginTop: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Manage Leaders
                      </button>
                    </Col>
                    <Col
                      md={6}
                      className="text-center main-div-last save-my-details-div"
                      style={{ background: "#787A6D" }}
                    >
                      <button
                        className="save-my-details"
                        style={{
                          border: "none",
                          background: "none",
                          marginTop: "10px",
                          fontWeight: 500,
                          color: "white",
                        }}
                      >
                        Add a New User
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Managelearner;
