/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Myresources.scss";
import { Link } from "react-router-dom";
import video1 from "../../assets/images/video1.png";
// import video2 from "../../assets/images/video2.png";
import ss1 from "../../assets/images/ss-1.png";
import ss2 from "../../assets/images/ss-2.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import syllabusentry from '../../assets/images/Syllabus – Entry.png';
import syllabusemerge from '../../assets/images/Syllabus – Emerge.png';
import EssentialDailyTrainingGuideImage from '../../assets/images/EssentialDailyTrainingGuide.svg';
import Eprogress from "../../assets/images/essential-progress.jpeg";
import Pprogress from "../../assets/images/professional-progress.jpeg";

function Myresources() {
  
  React.useEffect(() => {
    const str = window.location.href;
    const after_ = str.slice(str.indexOf("#") + 1);
    var elem = document.getElementById(after_);
    elem && elem.scrollIntoView();
  }, []);
   
  function openEmerge() {
    window.open('/Essential.pdf', '_blank', 'fullscreen=yes'); 
  }

  function openEntry() {
    window.open('/Professional.pdf', '_blank', 'fullscreen=yes'); 
  }
  function openEmerges() {
    window.open('/Essential-Syllabus.pdf', '_blank', 'fullscreen=yes'); 
  }

  function openEntrys() {
    window.open('/Professional-Syllabus.pdf', '_blank', 'fullscreen=yes'); 
  }

  function openEssentialDailyTrainingGuide() {
    window.open('https://alchemyvision.notion.site/Essential-Daily-Training-Guide-275e389d229745ff876e7d39476209af', '_blank', 'fullscreen=yes'); 
  }

  function progressChecklistE() {
    window.open('/Progress-Checklist-Essential.pdf', '_blank', 'fullscreen=yes'); 
  }
  function progressChecklistP() {
    window.open('/Progress-Checklist-Professional.pdf', '_blank', 'fullscreen=yes'); 
  }


  return (
    <>
      <div className="my-resources">
        <section className="section-practice">
          <Container>
            <div className="back-to-dashboard black">
              &#60;<Link to="/dashboard"> Back to My Dashboard</Link>
            </div>
            <Col md={12} id='alchemy-vision-101'>
                <div className="common-box mt-5">
                <div className="partition practice-color">
                    <Row>
                    <Col md={6}>
                        <span className="common-text">Alchemy Vision 101</span>
                        <br />
                    </Col>
                    <Col md={6} className="text-right" style={{display:'flex',alignItems: 'center',justifyContent:'flex-end'}}>
                        
                        <br />
                    </Col>
                    </Row>
                </div>
                <div className="partition">
                     {/* <Row className='mt-5'>
                        <Col md={3} sm={3}  className='d-flex justify-content-center'>
                            <img src={video2} alt='video-2' style={{ width: '100%',objectFit:'contain'}} />
                        </Col>
                        <Col md={9}>
                            <div className='video-2-content'>
                                <span className='video-title'>Using Your Leader Dashboard (Coming Soon)</span><br/><br/>
                                <span className='video-description'>This video is part of the learner Orientation course, to show them how to navigate and use our platform. It’s provided to you here so you can also be familiar with what they will see and how they’ll use Alchemy Vision.</span>
                            </div>
                        </Col>
                    </Row> */}
                    <Row className='mt-5 mb-5'>
                        <Col md={3} sm={3} className='d-flex justify-content-center'>
                        <Popup  trigger={<a href='javascript:void(0)' style={{display:'flex'}} ><img src={video1} alt="video-1" style={{ width: '100%',objectFit:'contain'}} /></a> } modal>
                            <div>
                                <video width="100%" height="100%" controls>
                                <source src="https://alchemyprod001.blob.core.windows.net/signup-portal/GettingAroundthePlatform.m4v" type="video/mp4" />
                                <source src="https://alchemyprod001.blob.core.windows.net/signup-portal/GettingAroundthePlatform.m4v" type="video/ogg" />
                                Your browser does not support the video tag.
                                </video>
                            </div>
                        </Popup>
                        </Col>
                        <Col md={9}>
                        <Popup trigger={
                              <a href='javascript:void(0)' className='no-hover'>
                                <div className='video-1-content'>
                                    <span className='video-title'>Getting Around the Platform (05:23)</span><br/><br/>
                                    <span className='video-description'>This video is part of the learner Orientation course, to show them how to navigate and use our platform. It’s provided to you here so you can also be familiar with what they will see and how they’ll use Alchemy Vision.</span>
                                </div>
                            </a>
                        } modal>
                            <div>
                            <video width="100%" height="100%" controls>
                            <source src="https://alchemyprod001.blob.core.windows.net/signup-portal/GettingAroundthePlatform.m4v" type="video/mp4" />
                            <source src="https://alchemyprod001.blob.core.windows.net/signup-portal/GettingAroundthePlatform.m4v" type="video/ogg" />
                            Your browser does not support the video tag.
                            </video>
                            </div>
                        </Popup>
                        </Col>
                    </Row>
                </div>
                    </div>
            </Col>
            <Col md={12} id='leader-tools'>
                <div className="common-box mt-5">
                <div className="partition practice-color">
                    <Row>
                    <Col md={6}>
                        <span className="common-text">Leader Tools</span>
                        <br />
                    </Col>
                    <Col md={6} className="text-right" style={{display:'flex',alignItems: 'center',justifyContent:'flex-end'}}>
                        
                        <br />
                    </Col>
                    </Row>
                </div>
                <div className="partition">
                    <Row className='mt-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                                <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{openEmerge()}}> <img style={{ width: '100%',objectFit:'contain'}} src={ss2} alt='video-1' /> </a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-1-content'>
                                <a href='javascript:void(0)' className='video-title underline no-hover' onClick={()=>{openEmerge()}}>Skills Check-in – Essential (PDF)</a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{openEmerge()}}>This PDF can help you assess the skills of a new technician. It was designed to be administered before and after they get trained by Alchemy Vision, as a direct comparison of skills acquired and learned.</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                                <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{openEmerges()}}> <img style={{ width: '100%',objectFit:'contain'}} src={syllabusemerge} alt='video-1' /> </a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-1-content' >
                                <a href='javascript:void(0)' className='video-title underline no-hover' onClick={()=>{openEmerges()}}>Syllabus – Essential (PDF)</a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{openEmerges()}}>In this PDF, you can see all the modules found within the learning path in the Essential subscription tier.</a>
                            </div>
                        </Col>
                    </Row>
                    {/* /*new */}
                    <Row className='mt-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                                <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{progressChecklistE()}}> <img style={{ width: '210px',height:'168px',objectFit:'contain'}} src={Eprogress} alt='video-1' /> </a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-1-content' >
                                <a href='javascript:void(0)' className='video-title underline no-hover' onClick={()=>{progressChecklistE()}}>Progress Checklist – Essential (PDF)</a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{progressChecklistE()}}>This progress checklist is a tool for your training staff to easily track their progress in the Essential learning path for eye care new hires. Instruct your staff to mark completed modules and note scores on assignments.  Progress tracking made simple and printable, for ongoing training.</a>
                            </div>
                        </Col>
                    </Row>
                    {/* /*new */}

                    <Row className='mt-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                            <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{openEssentialDailyTrainingGuide()}}> <img style={{ width: '100%',objectFit:'contain'}} src={EssentialDailyTrainingGuideImage} alt='video-1' /> </a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-1-content' >
                                <a href='javascript:void(0)' className='video-title underline no-hover' onClick={()=>{openEssentialDailyTrainingGuide()}}>Essential Daily Training Guide (Webpage) </a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{openEssentialDailyTrainingGuide()}}>
                                    This dynamic webpage makes it as easy as possible for you to implement the Essential learning path with your new hires. It gives you day-by-day lesson guidance, checkpoint answer keys, pro tips, and more.
                                </a>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-5 mb-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                               <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{openEntry()}}><img style={{ width: '100%',objectFit:'contain'}} src={ss1} alt='video-2' /></a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-2-content'>
                                <a href='javascript:void(0)' className='video-title underline no-hover'  onClick={()=>{openEntry()}}>Skills Check-in – Professional (PDF)</a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{openEntry()}}>This PDF can help you assess the skills of a new technician. It was designed to be administered before and after they get trained by Alchemy Vision, as a direct comparison of skills acquired and learned.</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5 mb-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                               <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{openEntrys()}}><img style={{ width: '100%',objectFit:'contain'}} src={syllabusentry} alt='video-2' /></a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-2-content'>
                                <a href='javascript:void(0)' className='video-title underline no-hover'  onClick={()=>{openEntrys()}}>Syllabus – Professional (PDF)</a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{openEntrys()}}>In this PDF, you can see all the modules found within the learning path in the Professional subscription tier.</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5 mb-5'>
                        <Col md={3} sm={12} className='d-flex justify-content-center' style={{margin:'0 auto'}}>
                               <a href='javascript:void(0)'  style={{display:'flex'}}  className='video-title no-hover' onClick={()=>{progressChecklistP()}}><img style={{ width: '210px',height:'168px',objectFit:'contain'}} src={Pprogress} alt='video-2' /></a>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className='video-2-content'>
                                <a href='javascript:void(0)' className='video-title underline no-hover'  onClick={()=>{progressChecklistP()}}>Progress Checklist – Professional (PDF)</a><br/><br/>
                                <a href='javascript:void(0)' className='video-description no-hover' onClick={()=>{progressChecklistP()}}>This progress checklist is a tool for your training staff to easily track their progress in the Professional learning path for technicians and scribes. Instruct your staff to mark completed modules and note scores on assignments.  Progress tracking made simple and printable, for ongoing training.</a>
                            </div>
                        </Col>
                    </Row>
                </div>
                    </div>
            </Col>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Myresources;
