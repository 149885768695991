import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
// import { applyLicenceAllocation } from "../../store/licenceallocation/effect"

function Managelicenses() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState([]);

  return (
    <div className="dashboard-content">
      <div className="dashboard-app">
        <header className="dashboard-toolbar">
          <a href="#!" className="menu-toggle">
            <i className="fas fa-bars"></i>
          </a>
        </header>
        <div className="container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Premium (Available Seats 2)
                <div className="float-end add-more-licence">
                  <input type="number" min="1" max="100" /> &nbsp;&nbsp;Add more
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      value={value[1]}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-3 send-invite">
                    <button className="btn btn-success">Send Invite</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-3 send-invite">
                    <button className="btn btn-success">Send Invite</button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Professional (Available Seats 2)
                <div className="float-end add-more-licence">
                  <input type="number" min="1" max="100" /> &nbsp;&nbsp;Add more
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-3 send-invite">
                    <button className="btn btn-success">Send Invite</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-3 send-invite">
                    <button className="btn btn-success">Send Invite</button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Administrative (Available Seats 2)
                <div className="float-end add-more-licence">
                  <input type="number" min="1" max="100" /> &nbsp;&nbsp;Add more
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-3 send-invite">
                    <button className="btn btn-success">Send Invite</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-3 send-invite">
                    <button className="btn btn-success">Send Invite</button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="container" style={{ marginTop: "50px" }}>
          <h4>Manage Users</h4>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>
                  <button className="btn btn-danger">DELETE</button>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>
                  <button className="btn btn-danger">DELETE</button>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry the Bird</td>
                <td>Larry the Bird</td>
                <td>
                  <button className="btn btn-danger">DELETE</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container" style={{ marginTop: "50px" }}>
          <h4>Manage Admins</h4>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>
                  <button className="btn btn-danger">DELETE</button>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>
                  <button className="btn btn-danger">DELETE</button>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry the Bird</td>
                <td>Larry the Bird</td>
                <td>
                  <button className="btn btn-danger">DELETE</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Managelicenses;
