// login actions
export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILED = "GET_PLANS_FAILED";

export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";

export const INCREMENT_PRACTICE = "INCREMENT_PRACTICE"
export const DECREMENT_PRACTICE = "DECREMENT_PRACTICE"

export const GET_PRACTICEPLANS = "GET_PRACTICEPLANS";
export const GET_PRACTICEPLANS_SUCCESS = "GET_PRACTICEPLANS_SUCCESS";
export const GET_PRACTICEPLANS_FAILED = "GET_PRACTICEPLANS_FAILED";

export const GET_PLAN_FEATURE = "GET_PLAN_FEATURE";
export const GET_PLAN_FEATURE_SUCCESS = "GET_PLAN_FEATURE_SUCCESS";
export const GET_PLAN_FEATURE_FAILED = "GET_PLAN_FEATURE_FAILED";

export const EDIT_LICENSE = "EDIT_LICENSE";
export const EDIT_LICENSE_SUCCESS = "EDIT_LICENSE_SUCCESS";
export const EDIT_LICENSE_FAILED = "EDIT_LICENSE_FAILED";
export const RESET_EDIT_LICENSE = "RESET_EDIT_LICENSE";

